import React, { FC, useCallback } from 'react';
import styled from '@emotion/styled';
import TextAndGraphicItem from './TextAndGraphicItem';
import { Col, GridContainer, maxMediaQuery, Row } from '../grid';
import { ConditionalWrapper } from '../wrapper/ConditionalWrapper';
import { TextAndGraphicProps } from './props';

const TextAndGraphicRow: FC<TextAndGraphicProps> = ({
  items,
  id,
  withContainer = true,
  withSpaceTop = true,
  withSpaceBottom = true,
}) => {
  const handleWithContainer = useCallback((children: React.ReactNode) => <GridContainer>{children}</GridContainer>, [
    withContainer,
    id,
  ]);

  return (
    <Container id={id} withSpaceTop={withSpaceTop} withSpaceBottom={withSpaceBottom}>
      <ConditionalWrapper condition={withContainer === true} wrapperPositive={handleWithContainer}>
        <Row>
          {items &&
            items.map((item, index) => (
              <Col key={index} sm={12} lg={4}>
                <TextAndGraphicItem
                  id={id != null ? `${id}-${index}` : undefined}
                  image={item.image}
                  imageDescription={item.imageDescription}
                  heading={item.heading}
                  copy={item.copy}
                />
              </Col>
            ))}
        </Row>
      </ConditionalWrapper>
    </Container>
  );
};

const Container = styled.div<{ withSpaceTop: boolean; withSpaceBottom: boolean }>`
  margin-top: ${({ theme, withSpaceTop }) => (withSpaceTop === true ? `${theme.spacing.unit * 10}px` : 0)};
  margin-bottom: ${({ theme, withSpaceBottom }) => (withSpaceBottom === true ? `${theme.spacing.unit * 10}px` : 0)};

  ${maxMediaQuery.md} {
    margin-top: ${({ theme, withSpaceTop }) => (withSpaceTop === true ? `${theme.spacing.unit * 5}px` : 0)};
    margin-bottom: ${({ theme, withSpaceBottom }) => (withSpaceBottom === true ? `${theme.spacing.unit * 5}px` : 0)};
  }

  ${maxMediaQuery.sm} {
    margin-top: ${({ theme, withSpaceTop }) => (withSpaceTop === true ? `${theme.spacing.unit * 3}px` : 0)};
    margin-bottom: ${({ theme, withSpaceBottom }) => (withSpaceBottom === true ? `${theme.spacing.unit * 3}px` : 0)};
  }
`;

export default TextAndGraphicRow;
