import { IGatsbyImageData } from 'gatsby-plugin-image';
import { ContentfulRichTextGatsbyReference, RenderRichTextData } from 'gatsby-source-contentful/rich-text';
import { ComponentMediaBlockFragment } from 'graphql-types';
import { MediaBlockProps } from '../../components/media-block/props';
import { OverlayAlignmentVariant, OverlayVariant } from '../../components/overlay/props';
import { mapContentButtonToProps } from './button';

export function mapContentMediaBlockToProps(mediaBlockContent: ComponentMediaBlockFragment): MediaBlockProps {
  const heading = mediaBlockContent.heading as RenderRichTextData<ContentfulRichTextGatsbyReference>;
  const excerpt = mediaBlockContent.excerpt as RenderRichTextData<ContentfulRichTextGatsbyReference>;

  return {
    id: mediaBlockContent?.contentfulid ?? undefined,
    overlayVariant: (mediaBlockContent?.overlay as OverlayVariant) ?? undefined,
    heading,
    fluidImage: mediaBlockContent.fluidImage?.gatsbyImageData as IGatsbyImageData,
    constrainedImage: mediaBlockContent.constrainedImage?.gatsbyImageData as IGatsbyImageData,
    imageAlt: mediaBlockContent.fluidImage?.description ?? undefined,
    excerpt,
    alignment: (mediaBlockContent.alignment as OverlayAlignmentVariant) ?? undefined,
    button: mediaBlockContent.button != null ? mapContentButtonToProps(mediaBlockContent.button) : undefined,
    buttonText: mediaBlockContent.button?.label ?? undefined,
    videoUrl: mediaBlockContent.videoUrl ?? undefined,
  };
}
