import React, { Suspense } from 'react';
import styled from '@emotion/styled';
import { LocationProvider } from '@reach/router';
import Header from './Header';
import Footer from '../footer/Footer';
import { GridContainer, maxMediaQuery } from '../grid';
import { isBrowser } from '../../utils/system';
import { DESKTOP_NAVIGATION_HEIGHT, MOBILE_NAVIGATION_HEIGHT } from '../../constants/navigation';

const CookieConsentManager = React.lazy(
  () =>
    new Promise((resolve) => {
      setTimeout(() => resolve(import('../cookie-consent-manager/CookieConsentManager')), 1000);
    })
);

const Layout: React.FC = ({ children }) => {
  const browser = isBrowser();

  return (
    <LocationProvider>
      <GridContainer>
        {browser === true && (
          <Suspense fallback={null}>
            <CookieConsentManager />
          </Suspense>
        )}
      </GridContainer>
      <Container>
        <HeaderContainer>
          <Header />
        </HeaderContainer>
        <Main id="main">{children}</Main>
        <Footer />
      </Container>
    </LocationProvider>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const HeaderContainer = styled.header`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
`;

const Main = styled.main`
  flex-grow: 1;
  padding-top: ${DESKTOP_NAVIGATION_HEIGHT}px;

  ${maxMediaQuery.sm} {
    padding-top: ${MOBILE_NAVIGATION_HEIGHT}px;
  }
`;

export default Layout;
