import React from 'react';
import { Global, css } from '@emotion/react';
import { normalize } from 'polished';
import { maxMediaQuery } from '../grid';

const GlobalStyles: React.FC = () => {
  return (
    <Global
      styles={(theme) => css`
        ${normalize()}

        html,
        body {
          font-family: ${theme.typography.body.fontFamily};
          font-size: ${theme.typography.body.fontSize};
          font-weight: ${theme.typography.body.fontWeight};
          line-height: ${theme.typography.body.lineHeight};
          color: ${theme.colors.primary.copy};
          box-sizing: border-box;
        }

        *,
        *:before,
        *:after {
          box-sizing: border-box;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          margin-top: 0;
          margin-bottom: ${theme.spacing.unit * 3}px;
          text-transform: uppercase;
          font-weight: 700;
          font-family: ${theme.fonts.headers};
        }

        h1 {
          font-family: ${theme.typography.h1.fontFamily};
          font-size: ${theme.typography.h1.fontSize};
          font-weight: ${theme.typography.h1.fontWeight};
          line-height: ${theme.typography.h1.lineHeight};
        }

        h2 {
          font-family: ${theme.typography.h2.fontFamily};
          font-size: ${theme.typography.h2.fontSize};
          font-weight: ${theme.typography.h2.fontWeight};
          line-height: ${theme.typography.h2.lineHeight};
        }

        h3 {
          font-family: ${theme.typography.h3.fontFamily};
          font-size: ${theme.typography.h3.fontSize};
          font-weight: ${theme.typography.h3.fontWeight};
          line-height: ${theme.typography.h3.lineHeight};
        }

        h4 {
          font-family: ${theme.typography.h4.fontFamily};
          font-size: ${theme.typography.h4.fontSize};
          font-weight: ${theme.typography.h4.fontWeight};
          line-height: ${theme.typography.h4.lineHeight};
        }

        h5 {
          font-family: ${theme.typography.h5.fontFamily};
          font-size: ${theme.typography.h5.fontSize};
          font-weight: ${theme.typography.h5.fontWeight};
          line-height: ${theme.typography.h5.lineHeight};
        }

        h6 {
          font-family: ${theme.typography.h6.fontFamily};
          font-size: ${theme.typography.h6.fontSize};
          font-weight: ${theme.typography.h6.fontWeight};
          line-height: ${theme.typography.h6.lineHeight};
        }

        a {
          color: ${theme.colors.primary.link};
          text-decoration: none;
        }

        ul,
        ol {
          padding-left: 18px;
        }

        hr {
          margin: ${theme.spacing.unit * 4}px 0;
        }

        .sr-only {
          position: absolute;
          width: 1px;
          height: 1px;
          margin: -1px;
          padding: 0;
          border: 0;
          clip: rect(0 0 0 0);
          overflow: hidden;
        }

        .btn-restart {
          appearance: none;
          margin: 0;
          padding: 0;
          background: transparent;
          border: none;
          box-shadow: none;
          cursor: pointer;
        }

        .underlined {
          text-decoration: underline;
        }

        ${maxMediaQuery.lg} {
          h1 {
            font-family: ${theme.tabletTypography.h1.fontFamily};
            font-size: ${theme.tabletTypography.h1.fontSize};
            font-weight: ${theme.tabletTypography.h1.fontWeight};
            line-height: ${theme.tabletTypography.h1.lineHeight};
          }

          h2 {
            font-family: ${theme.tabletTypography.h2.fontFamily};
            font-size: ${theme.tabletTypography.h2.fontSize};
            font-weight: ${theme.tabletTypography.h2.fontWeight};
            line-height: ${theme.tabletTypography.h2.lineHeight};
          }

          h3 {
            font-family: ${theme.tabletTypography.h3.fontFamily};
            font-size: ${theme.tabletTypography.h3.fontSize};
            font-weight: ${theme.tabletTypography.h3.fontWeight};
            line-height: ${theme.tabletTypography.h3.lineHeight};
          }

          h4 {
            font-family: ${theme.tabletTypography.h4.fontFamily};
            font-size: ${theme.tabletTypography.h4.fontSize};
            font-weight: ${theme.tabletTypography.h4.fontWeight};
            line-height: ${theme.tabletTypography.h4.lineHeight};
          }

          h5 {
            font-family: ${theme.tabletTypography.h5.fontFamily};
            font-size: ${theme.tabletTypography.h5.fontSize};
            font-weight: ${theme.tabletTypography.h5.fontWeight};
            line-height: ${theme.tabletTypography.h5.lineHeight};
          }

          h6 {
            font-family: ${theme.tabletTypography.h6.fontFamily};
            font-size: ${theme.tabletTypography.h6.fontSize};
            font-weight: ${theme.tabletTypography.h6.fontWeight};
            line-height: ${theme.tabletTypography.h6.lineHeight};
          }
        }

        ${maxMediaQuery.sm} {
          h1 {
            font-family: ${theme.mobileTypography.h1.fontFamily};
            font-size: ${theme.mobileTypography.h1.fontSize};
            font-weight: ${theme.mobileTypography.h1.fontWeight};
            line-height: ${theme.mobileTypography.h1.lineHeight};
          }

          h2 {
            font-family: ${theme.mobileTypography.h2.fontFamily};
            font-size: ${theme.mobileTypography.h2.fontSize};
            font-weight: ${theme.mobileTypography.h2.fontWeight};
            line-height: ${theme.mobileTypography.h2.lineHeight};
          }

          h3 {
            font-family: ${theme.mobileTypography.h3.fontFamily};
            font-size: ${theme.mobileTypography.h3.fontSize};
            font-weight: ${theme.mobileTypography.h3.fontWeight};
            line-height: ${theme.mobileTypography.h3.lineHeight};
          }

          h4 {
            font-family: ${theme.mobileTypography.h4.fontFamily};
            font-size: ${theme.mobileTypography.h4.fontSize};
            font-weight: ${theme.mobileTypography.h4.fontWeight};
            line-height: ${theme.mobileTypography.h4.lineHeight};
          }

          h5 {
            font-family: ${theme.mobileTypography.h5.fontFamily};
            font-size: ${theme.mobileTypography.h5.fontSize};
            font-weight: ${theme.mobileTypography.h5.fontWeight};
            line-height: ${theme.mobileTypography.h5.lineHeight};
          }

          h6 {
            font-family: ${theme.mobileTypography.h6.fontFamily};
            font-size: ${theme.mobileTypography.h6.fontSize};
            font-weight: ${theme.mobileTypography.h6.fontWeight};
            line-height: ${theme.mobileTypography.h6.lineHeight};
          }
        }
      `}
    />
  );
};

export default GlobalStyles;
