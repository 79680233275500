import React from 'react';
import { CardProps } from '../card/props';
import CardProjectMap from '../card/CardProjectMap';
import FullpageInfo from './FullpageInfo';

type CurrentMapItemIndicatorProps = CardProps & {
  top: number;
  left: number;
  displayMode: string;
  mobileView: boolean;
  closeMethod: () => void;
};

const CurrentMapItemIndicator = React.forwardRef<HTMLDivElement, CurrentMapItemIndicatorProps>(
  ({ id, top, left, displayMode, closeMethod, mobileView, ...cardBodyProps }, ref) => {
    return (
      <>
        {mobileView ? (
          <FullpageInfo
            id={id}
            top={top}
            left={left}
            displayMode={displayMode}
            {...cardBodyProps}
            closeMethod={closeMethod}
          />
        ) : displayMode !== 'none' ? (
          <CardProjectMap id={id} top={top} left={left} displayMode={displayMode} {...cardBodyProps} ref={ref} />
        ) : null}
      </>
    );
  }
);

export default CurrentMapItemIndicator;
