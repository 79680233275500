module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"mode":"async","enableListener":false,"preconnect":["https://fonts.gstatic.com"],"web":[{"name":["Montserrat","Anton"],"file":"https://fonts.googleapis.com/css?family=Montserrat:400,700|Anton:400,700&display=swap"}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
