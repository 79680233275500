export function isApplicationUrl(url: string): boolean {
  return (
    url.slice(0, 4) !== 'http' && url.slice(0, 2) !== '//' && url.slice(0, 6) !== 'mailto' && url.slice(0, 3) !== 'tel'
  );
}

export function isApplicationTargetId(url: string): boolean {
  return url.charAt(0) === '#';
}

export function isApplicationTargetEntry(url: string): boolean {
  return isApplicationUrl(url) === true && isApplicationTargetId(url) === false;
}

export function getUrlWithLeadingSlash(url: string): string {
  return url.charAt(0) === '/' ? url : `/${url}`;
}

export function removeFollowingSlash(url: string): string {
  return url.charAt(url.length - 1) === '/' ? url.slice(0, url.length - 1) : url;
}
