import React, { FC } from 'react';
import styled from '@emotion/styled';
import { ContentfulRichTextGatsbyReference, RenderRichTextData } from 'gatsby-source-contentful/rich-text';
import { maxMediaQuery } from '../grid';

interface Props {
  title: string;
  topText?: string;
  topTextColor?: string;
  bottomText?: RenderRichTextData<ContentfulRichTextGatsbyReference>;
  tags?: string[];
}

const PaheHeaderMainPart: FC<Props> = ({ title, topText, topTextColor, ...props }) => {
  return (
    <Container {...props}>
      <TopText textColor={topTextColor}>{topText}</TopText>
      <Title>{title}</Title>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
`;

const TopText = styled.h5<{ textColor: string | undefined }>`
  color: ${(props) => props.textColor || props.theme.colors.primary.copy};
`;

const Title = styled.h1`
  color: ${({ theme }) => theme.colors.primary.copy};

  ${maxMediaQuery.sm} {
    font-size: ${({ theme }) => theme.typography.h2.fontSize};
    line-height: ${({ theme }) => theme.typography.h2.lineHeight};
  }
`;

export default PaheHeaderMainPart;
