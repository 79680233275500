import styled from '@emotion/styled';
import { maxMediaQuery } from './mediaquery';

const Row = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: ${(props) => `calc(100% + ${props.theme.grid.gutter}px)`};
  margin-left: ${(props) => `-${props.theme.grid.gutter / 2}px`};
  margin-right: ${(props) => `-${props.theme.grid.gutter / 2}px`};

  ${maxMediaQuery.sm} {
    width: calc(100% + 40px);
    margin-left: -20px;
    margin-right: -20px;
  }
`;

export default Row;
