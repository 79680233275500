import styled from '@emotion/styled';
import ButtonBase from './ButtonBase';

const ButtonSolid = styled(ButtonBase)`
  color: ${(props) => props.theme.colors.primary.white};
  background-color: ${(props) => props.theme.colors.primary.comicReliefRed};
  border: 2px solid ${(props) => props.theme.colors.primary.comicReliefRed};

  &:hover {
    background-color: ${(props) => props.theme.colors.secondary.coral.dark};
    border-color: ${(props) => props.theme.colors.secondary.coral.dark};
  }
`;

export default ButtonSolid;
