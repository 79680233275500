import styled from '@emotion/styled';
import { AnimationProps, motion, useAnimation } from 'framer-motion';
import { GatsbyImage } from 'gatsby-plugin-image';
import React, { FC, useEffect } from 'react';
import { CardProps } from './props';

type Props = Pick<CardProps, 'image' | 'imageAlt'> & {
  isHovering: boolean;
};

const containerAnimationVariants: AnimationProps['variants'] = {
  hoverOut: {
    scale: 1,
  },
  hoverIn: {
    scale: 1.05,
  },
};

const overlayAnimationVariants: AnimationProps['variants'] = {
  hoverOut: {
    opacity: 0,
    transitionEnd: {
      visibility: 'hidden',
    },
  },
  hoverIn: {
    opacity: 1,
    visibility: 'visible',
  },
};

const animationTransition: AnimationProps['transition'] = {
  scale: { duration: 0.4 },
  opacity: { duration: 0.4 },
};

const CardImage: FC<Props> = ({ image, imageAlt, isHovering }) => {
  const containerControls = useAnimation();
  const overlayControls = useAnimation();

  useEffect(() => {
    if (isHovering === true) {
      containerControls.start('hoverIn');
      overlayControls.start('hoverIn');
    } else {
      containerControls.start('hoverOut');
      overlayControls.start('hoverOut');
    }
  }, [isHovering, containerControls, overlayControls]);

  return (
    <Container
      animate={containerControls}
      variants={containerAnimationVariants}
      initial="hoverOut"
      transition={animationTransition}
    >
      {image != null && <ImageElement image={image} alt={imageAlt ?? ''} />}
      <Overlay
        animate={overlayControls}
        variants={overlayAnimationVariants}
        initial="hoverOut"
        transition={animationTransition}
      />
    </Container>
  );
};

const Container = styled(motion.div)`
  position: relative;
  width: 100%;
  height: 100%;
`;

const ImageElement = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
`;

const Overlay = styled(motion.div)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.2);
`;

export default CardImage;
