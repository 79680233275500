// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-article-template-tsx": () => import("./../../../src/templates/ArticleTemplate.tsx" /* webpackChunkName: "component---src-templates-article-template-tsx" */),
  "component---src-templates-page-template-tsx": () => import("./../../../src/templates/PageTemplate.tsx" /* webpackChunkName: "component---src-templates-page-template-tsx" */),
  "component---src-templates-project-template-tsx": () => import("./../../../src/templates/ProjectTemplate.tsx" /* webpackChunkName: "component---src-templates-project-template-tsx" */),
  "component---src-templates-publication-template-tsx": () => import("./../../../src/templates/PublicationTemplate.tsx" /* webpackChunkName: "component---src-templates-publication-template-tsx" */),
  "component---src-templates-story-template-tsx": () => import("./../../../src/templates/StoryTemplate.tsx" /* webpackChunkName: "component---src-templates-story-template-tsx" */)
}

