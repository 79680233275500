import styled from '@emotion/styled';
import React, { FC, useCallback, useState } from 'react';
import { AnimationProps, motion } from 'framer-motion';
import { ContentfulRichTextGatsbyReference, RenderRichTextData } from 'gatsby-source-contentful/rich-text';
import { maxMediaQuery } from '../grid';
import Icon from '../icon/Icon';
import { AccordionItem } from './AccordionItem';
import { mapTextBlockRichText } from '../../utils/rich-text/text-block';

type Props = {
  item: AccordionItem;
  id?: string;
};

const contentAnimationVariants: AnimationProps['variants'] = {
  closed: {
    height: 0,
    opacity: 0,
    transitionEnd: {
      visibility: 'hidden',
    },
  },
  opened: {
    height: 'auto',
    opacity: 1,
    visibility: 'visible',
  },
};

const contentAnimationTransition: AnimationProps['transition'] = {
  height: { type: 'spring', stiffness: 250, damping: 30, duration: 0.3 },
  opacity: { duration: 0.3 },
};

const Accordion: FC<Props> = ({ item, id, ...props }) => {
  const [contentVisible, setContentVisible] = useState(false);

  const iconRotateVariants = {
    rotate: { rotate: [0, -180], transition: { duration: 0.25 } },
    stop: { rotate: [-180, 0], transition: { duration: 0.25 } },
  };

  const changeContentVisibility = useCallback(() => {
    setContentVisible(!contentVisible);
  }, [contentVisible]);

  return (
    <Container {...props} id={id} contentVisible={contentVisible}>
      <HeadingContainer className="btn-restart" onClick={changeContentVisibility} aria-expanded={contentVisible}>
        <Heading>{item.heading}</Heading>
        <IconContainer
          contentVisible={contentVisible}
          variants={iconRotateVariants}
          animate={contentVisible ? 'rotate' : 'stop'}
          initial="stop"
        >
          <StyledIcon iconType="accordion-expander" />
        </IconContainer>
      </HeadingContainer>
      <ContentContainer
        animate={contentVisible === true ? 'opened' : 'closed'}
        variants={contentAnimationVariants}
        transition={contentAnimationTransition}
        initial="closed"
      >
        <div>
          {mapTextBlockRichText((item?.content as unknown) as RenderRichTextData<ContentfulRichTextGatsbyReference>)}
        </div>
      </ContentContainer>
    </Container>
  );
};

const HeadingContainer = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: ${({ theme }) => theme.spacing.unit * 3}px;
  cursor: pointer;

  ${maxMediaQuery.sm} {
    padding: ${(props) => props.theme.spacing.unit * 2}px;
  }
`;

const Container = styled.div<{ contentVisible: boolean }>`
  position: relative;
  width: 100%;
  border-radius: 10px;
  text-align: left;
  overflow: hidden;
  transform-origin: top;
  background-color: ${({ theme, contentVisible }) =>
    contentVisible === true ? theme.colors.primary.white : theme.colors.secondary.coral.normal};
  box-shadow: ${({ contentVisible }) => (contentVisible ? '0px 4px 12px rgba(0, 0, 0, 0.2)' : 'none')};
  transition: all 0.3s ease;

  ${HeadingContainer} {
    color: ${({ theme }) => theme.colors.primary.copy};
    transition: color 0.3s ease;
  }

  &:hover {
    background-color: ${({ theme, contentVisible }) =>
      contentVisible === true ? theme.colors.primary.white : theme.colors.primary.comicReliefRed};
    color: ${({ theme, contentVisible }) => (contentVisible ? theme.colors.primary.copy : theme.colors.primary.white)};

    svg > circle {
      fill: ${(props) =>
        props.contentVisible ? props.theme.colors.primary.white : props.theme.colors.primary.comicReliefRed};
      transition: all 0.3s ease;
    }

    svg > path {
      fill: ${(props) =>
        props.contentVisible ? props.theme.colors.primary.comicReliefRed : props.theme.colors.primary.white};
      transition: all 0.3s ease;
    }

    svg {
      stroke: ${(props) =>
        props.contentVisible ? props.theme.colors.primary.comicReliefRed : props.theme.colors.primary.white};
      transition: all 0.3s ease;
    }

    ${HeadingContainer} {
      color: ${({ theme, contentVisible }) =>
        contentVisible === true ? theme.colors.primary.copy : theme.colors.primary.white};
    }
  }

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
  }
`;

const Heading = styled.div`
  flex: 1;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  text-align: left;

  ${maxMediaQuery.sm} {
    font-size: 16px;
    line-height: 20px;
  }
`;

const IconContainer = styled(motion.div)<{ contentVisible: boolean }>`
  transform: ${(props) => `rotate(${props.contentVisible ? 180 : 0}deg)`};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  margin-left: ${(props) => props.theme.spacing.unit * 2}px;

  svg > circle {
    fill: ${(props) =>
      props.contentVisible ? props.theme.colors.primary.comicReliefRed : props.theme.colors.primary.white};
    transition: all 0.3s ease;
  }

  svg > path {
    fill: ${(props) =>
      props.contentVisible ? props.theme.colors.primary.white : props.theme.colors.secondary.coral.normal};
    transition: all 0.3s ease;
  }
`;

const StyledIcon = styled(Icon)`
  margin: 2px;
  overflow: visible;
`;

const ContentContainer = styled(motion.div)`
  > div {
    padding: ${({ theme }) => theme.spacing.unit * 3}px;
    padding-top: 1px;

    ${maxMediaQuery.sm} {
      padding: ${(props) => props.theme.spacing.unit * 2}px;
    }
  }

  a:not(.btn) {
    font-weight: 700;
    border-bottom: 1px solid transparent;
    transition: all 0.2s ease;

    &:hover {
      border-bottom-color: ${({ theme }) => theme.colors.primary.copy};
    }
  }
`;

export default Accordion;
