import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { transparentize } from 'polished';
import React, { FC, useState, useEffect, useCallback } from 'react';
import { maxMediaQuery } from '../grid';
import Icon from '../icon/Icon';

type Props = {
  currentPage: number;
  totalPages: number;
  onPageChange(pageNumber: number): void;
};

interface Page {
  hidden: boolean;
  pageNumber: number;
}

const MAX_PAGES = 9;

const Pagination: FC<Props> = ({ currentPage, totalPages, onPageChange }) => {
  const [pages, setPages] = useState<Page[]>([]);
  const previousPage = useCallback(() => {
    onPageChange(currentPage - 1);
  }, [currentPage, onPageChange]);
  const nextPage = useCallback(() => {
    onPageChange(currentPage + 1);
  }, [currentPage, onPageChange]);

  useEffect(() => {
    const pagesIndexes = Array.from(Array(totalPages).keys());
    const pagesToDisplay = [];
    let leftHidden = false;
    let rightHidden = false;

    if (currentPage >= 6 && totalPages > MAX_PAGES) {
      leftHidden = true;
    }

    if (currentPage <= totalPages - 5 && totalPages > MAX_PAGES) {
      rightHidden = true;
    }

    pagesToDisplay.push(...pagesIndexes.slice(0, 3));

    if (totalPages > 3) {
      if (totalPages - 6 <= 3 || currentPage <= 4) {
        pagesToDisplay.push(...pagesIndexes.slice(3, 6));
      } else if (currentPage >= totalPages - 3) {
        pagesToDisplay.push(...pagesIndexes.slice(totalPages - 6, totalPages - 3));
      } else {
        pagesToDisplay.push(...pagesIndexes.slice(currentPage - 2, currentPage + 1));
      }
    }

    if (totalPages > 6 && totalPages <= 9) {
      pagesToDisplay.push(...pagesIndexes.slice(6));
    } else if (totalPages > 6) {
      pagesToDisplay.push(...pagesIndexes.slice(-3));
    }

    setPages(
      pagesToDisplay.map((pageIndex, index) => {
        const page = {
          hidden: false,
          pageNumber: pageIndex + 1,
        };

        if (index === 2 && leftHidden === true) {
          page.hidden = true;
        }

        if (index === 6 && rightHidden === true) {
          page.hidden = true;
        }

        return page;
      })
    );
  }, [currentPage, totalPages, setPages]);

  return (
    <Container aria-label="pagination">
      <LeftDirection
        whileHover={currentPage !== 1 ? { x: -5 } : undefined}
        whileFocus={currentPage !== 1 ? { x: -5 } : undefined}
        className="btn-restart"
        disabled={currentPage === 1}
        onClick={previousPage}
      >
        <Icon iconType="chevron" aria-hidden />
        <span className="sr-only">
          Previous
          <span>page</span>
        </span>
      </LeftDirection>
      <PagesContainer>
        <Label aria-hidden>Page</Label>
        {pages.map((page) => (
          <li key={page.pageNumber}>
            {page.hidden === true ? (
              <span aria-hidden>...</span>
            ) : (
              <Item
                className={currentPage === page.pageNumber ? 'btn-restart selected' : 'btn-restart'}
                aria-current={currentPage === page.pageNumber ? 'page' : undefined}
                onClick={() => {
                  if (currentPage !== page.pageNumber) {
                    onPageChange(page.pageNumber);
                  }
                }}
              >
                <span className="sr-only">page </span>
                <span>{page.pageNumber}</span>
              </Item>
            )}
          </li>
        ))}
      </PagesContainer>
      <RightDirection
        whileHover={currentPage !== totalPages ? { x: 5 } : undefined}
        whileFocus={currentPage !== totalPages ? { x: 5 } : undefined}
        className="btn-restart"
        disabled={currentPage === totalPages}
        onClick={nextPage}
      >
        <Icon iconType="chevron" aria-hidden />
        <span className="sr-only">
          Next
          <span>page</span>
        </span>
      </RightDirection>
    </Container>
  );
};

const Container = styled.nav`
  display: flex;
  align-items: center;
`;

const DirectionButton = styled(motion.button)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 68px;
  color: ${({ theme }) => theme.colors.primary.copy};
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.primary.white};
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2);

  > svg {
    display: block;
    width: 20px;
    height: 10px;
  }

  &:disabled {
    color: ${({ theme }) => theme.colors.secondary.grey.normal};
    cursor: not-allowed;
  }

  ${maxMediaQuery.sm} {
    height: 50px;
    width: 50px;
  }
`;

const LeftDirection = styled(DirectionButton)`
  > svg {
    transform: rotate(90deg);
  }
`;

const RightDirection = styled(DirectionButton)`
  > svg {
    transform: rotate(-90deg);
  }
`;

const PagesContainer = styled.ul`
  display: flex;
  height: 68px;
  margin: ${({ theme }) => `0 ${theme.spacing.unit * 1.5}px`};
  padding: 0 20px;
  list-style: none;
  color: ${({ theme }) => theme.colors.primary.copy};
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.primary.white};
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2);

  li {
    + li {
      margin-left: ${({ theme }) => theme.spacing.unit * 0.5}px;
    }

    > span {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      padding: 0 4px;
      margin-top: -4px;
    }

    ${maxMediaQuery.sm} {
      &:nth-of-type(2) {
        margin-left: 0;
      }
    }
  }

  ${maxMediaQuery.sm} {
    height: 50px;
  }
`;

const Label = styled.li`
  display: flex;
  align-items: center;
  height: 100%;
  margin-right: ${({ theme }) => theme.spacing.unit * 2}px;
  font-size: ${({ theme }) => theme.typography.titleBody.fontSize};
  font-weight: 700;
  line-height: ${({ theme }) => theme.typography.titleBody.lineHeight};

  ${maxMediaQuery.sm} {
    display: none;
  }
`;

const Item = styled.button`
  display: block;
  height: 100%;
  font-size: ${({ theme }) => theme.typography.titleBody.fontSize};
  font-weight: 700;
  line-height: ${({ theme }) => theme.typography.titleBody.lineHeight};
  transition: all 0.3s ease;

  > span:not(.sr-only) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    color: ${({ theme }) => theme.colors.primary.copy};
    background-color: transparent;
    border-radius: 50%;
    transition: all 0.3s ease;
  }

  &:hover {
    > span:not(.sr-only) {
      background-color: ${({ theme }) => transparentize(0.75, theme.colors.primary.comicReliefRed)};
    }
  }

  &:active,
  &.selected {
    > span:not(.sr-only) {
      color: ${({ theme }) => theme.colors.primary.white};
      background-color: ${({ theme }) => theme.colors.primary.comicReliefRed};
    }
  }
`;

export default Pagination;
