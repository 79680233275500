import styled from '@emotion/styled';
import React, { FC, useCallback } from 'react';
import Button from '../button/Button';
import { ButtonVariants } from '../button/buttonProps';
import { maxMediaQuery } from '../grid';
import { CategoriesFilterItem } from './props';

type Props = {
  items: CategoriesFilterItem[];
  value?: CategoriesFilterItem['value'][];
  label?: React.ReactNode;
  onItemSelect(value: string | undefined): void;
};

const CategoriesFilter: FC<Props> = ({ items, value, label = 'Show', onItemSelect }) => {
  const clearSelection = useCallback(() => {
    onItemSelect(undefined);
  }, [onItemSelect]);

  return (
    <Container>
      <Label>{label}</Label>
      <Button variant={ButtonVariants.OutlinedVioletAlt} active={value === undefined} onClick={clearSelection}>
        View all
      </Button>
      {items.map((item) => (
        <Button
          key={item.key}
          variant={ButtonVariants.OutlinedVioletAlt}
          active={value?.includes(item.value) === true}
          onClick={() => onItemSelect(item.value)}
        >
          {item.label}
        </Button>
      ))}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  button {
    margin-left: ${({ theme }) => theme.spacing.unit * 3}px;
  }

  ${maxMediaQuery.sm} {
    flex-direction: column;
    width: 100%;

    button {
      width: 100%;
      margin-left: 0;
      margin-top: ${({ theme }) => theme.spacing.unit * 2}px;
    }
  }
`;

const Label = styled.div`
  font-weight: 700;
  color: ${({ theme }) => theme.colors.primary.comicReliefDeepViolet};
`;

export default CategoriesFilter;
