import React, { FC } from 'react';
import { css, SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import { minMediaQuery, BreakpointsTypes, maxMediaQuery } from './mediaquery';
import { Theme } from '../../models/theme';

type SpanType = boolean | 'auto' | number;
type ObjectColProps = { span: SpanType; offset?: number; order?: number };
type BreakpointColProps = SpanType | ObjectColProps;

export type ColProps = {
  size?: number;
  sm?: BreakpointColProps;
  md?: BreakpointColProps;
  lg?: BreakpointColProps;
  xl?: BreakpointColProps;
  offsetAuto?: boolean;
  theme?: Theme;
} & React.PropsWithRef<JSX.IntrinsicElements['div']>;

const getSpan = (props: BreakpointColProps): SpanType => (typeof props === 'object' ? props.span : props);

const getColWidth = (props: BreakpointColProps, columnWidthPercent: number): number => {
  const span: SpanType = getSpan(props);

  if (typeof span === 'number') {
    return columnWidthPercent * span;
  }

  return 100;
};

const colAutoOffset = css({
  marginLeft: 'auto',
});

const flexibleCol = css({
  flexBasis: 0,
  flexGrow: 1,
  maxWidth: '100%',
});

const fixedCol = (props: ColProps, type: Exclude<BreakpointsTypes, 'xs'>) => {
  const width = getColWidth(props[type] || 'auto', props.theme?.grid.columnWidthPercent || 100);

  return css({
    flexBasis: 'auto',
    flexShrink: 0,
    flexGrow: 1,
    width: '100%',
    maxWidth: `${width}%`,
  });
};

const colOffset = (offset: number, columnWidthPercent: number) =>
  css({
    marginLeft: `${columnWidthPercent * offset}%`,
  });

const colOrder = (order: number) =>
  css({
    order,
  });

const mapStyles = (props: ColProps, type: Exclude<BreakpointsTypes, 'xs'>): SerializedStyles => {
  const span = getSpan(props[type] || 'auto');
  const styles: SerializedStyles[] = [];

  if (typeof span === 'string' || typeof span === 'boolean') {
    styles.push(flexibleCol);
  } else if (typeof span === 'number') {
    styles.push(fixedCol(props, type));
  }

  if (props[type] != null && typeof props[type] === 'object') {
    const colProps = props[type] as ObjectColProps;

    if (colProps.offset != null) {
      styles.push(colOffset(colProps.offset, props.theme?.grid.columnWidthPercent || 100));
    }

    if (colProps.order != null) {
      styles.push(colOrder(colProps.order));
    }
  }

  return css(...styles);
};

const smCol = (props: ColProps) =>
  css({
    [minMediaQuery.sm]: {
      ...mapStyles(props, 'sm'),
    },
  });

const mdCol = (props: ColProps) =>
  css({
    [minMediaQuery.md]: {
      ...mapStyles(props, 'md'),
    },
  });

const lgCol = (props: ColProps) =>
  css({
    [minMediaQuery.lg]: {
      ...mapStyles(props, 'lg'),
    },
  });

const xlCol = (props: ColProps) =>
  css({
    [minMediaQuery.xl]: {
      ...mapStyles(props, 'xl'),
    },
  });

const StyledCol = styled.div<ColProps>`
  display: block;
  width: ${(props) => (props.size ? (100 / props.theme.grid.columns) * props.size : 100)}%;
  padding-left: ${(props) => props.theme.grid.gutter / 2}px;
  padding-right: ${(props) => props.theme.grid.gutter / 2}px;

  ${maxMediaQuery.sm} {
    padding-left: 20px;
    padding-right: 20px;
  }

  ${(props) => (!props.size && !props.sm && !props.md && !props.lg && !props.xl ? flexibleCol : null)}
  ${(props) => (props.sm ? smCol : null)}
  ${(props) => (props.md ? mdCol : null)}
  ${(props) => (props.lg ? lgCol : null)}
  ${(props) => (props.xl ? xlCol : null)}
  ${(props) => (props.offsetAuto ? colAutoOffset : null)}
`;

const Col: FC<ColProps> = ({ children, ...props }) => <StyledCol {...props}>{children}</StyledCol>;

export default Col;
