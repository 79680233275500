import { IGatsbyImageData } from 'gatsby-plugin-image';
import { ContentfulRichTextGatsbyReference, RenderRichTextData } from 'gatsby-source-contentful/rich-text';
import { ComponentHeroFragment } from 'graphql-types';
import { OverlayAlignmentVariant, OverlayBackgroundVariant, OverlayVariant } from '../../components/overlay/props';
import { HeroProps } from '../../components/hero/props';
import { mapContentButtonToProps } from './button';

export function mapContentHeroToProps(heroContent: ComponentHeroFragment): HeroProps {
  const heading = heroContent.heading as RenderRichTextData<ContentfulRichTextGatsbyReference>;
  const excerpt = heroContent.excerpt as RenderRichTextData<ContentfulRichTextGatsbyReference>;

  return {
    id: heroContent?.contentfulid ?? undefined,
    overlayVariant: (heroContent?.overlay as OverlayVariant) ?? undefined,
    heading,
    image: heroContent.image?.gatsbyImageData as IGatsbyImageData,
    imageAlt: heroContent.image?.description ?? undefined,
    excerpt,
    alignment: (heroContent.alignment as OverlayAlignmentVariant) ?? undefined,
    button: heroContent.button != null ? mapContentButtonToProps(heroContent.button) : undefined,
    buttonText: heroContent.button?.label ?? undefined,
    background: (heroContent.background as OverlayBackgroundVariant) ?? undefined,
  };
}
