import React from 'react';
import {
  renderRichText,
  ContentfulRichTextGatsbyReference,
  RenderRichTextData,
} from 'gatsby-source-contentful/rich-text';
import { BLOCKS } from '@contentful/rich-text-types';
import { RichTextOptions } from './reusable';

function childrenElementNotEmpty(value: React.ReactNode): boolean {
  return (
    Array.isArray(value) === true &&
    typeof ((value as unknown) as string[])[0] === 'string' &&
    ((value as unknown) as string[])[0].trim() !== ''
  );
}

const options: RichTextOptions = {
  renderNode: {
    [BLOCKS.HEADING_2]: (_node: React.ReactNode, children: React.ReactNode) =>
      childrenElementNotEmpty(children) === true ? <h2>{children}</h2> : null,
    [BLOCKS.HEADING_3]: (_node: React.ReactNode, children: React.ReactNode) =>
      childrenElementNotEmpty(children) === true ? <h3>{children}</h3> : null,
    [BLOCKS.HEADING_4]: (_node: React.ReactNode, children: React.ReactNode) =>
      childrenElementNotEmpty(children) === true ? <h4>{children}</h4> : null,
    [BLOCKS.PARAGRAPH]: (_node: React.ReactNode, children: React.ReactNode) =>
      childrenElementNotEmpty(children) === true ? <h2>{children}</h2> : null,
  },
};

export function mapMediaBlockHeadingRichText(
  richText: RenderRichTextData<ContentfulRichTextGatsbyReference>
): React.ReactNode {
  if (richText?.raw == null) {
    return '';
  }

  return renderRichText(richText, options);
}
