import { IGatsbyImageData } from 'gatsby-plugin-image';
import { ComponentCardFeaturedFragment } from 'graphql-types';
import { CardProps } from '../../components/card/props';
import { mapCardsContent } from './card';

export function mapContentFeaturedCardToProps(content: ComponentCardFeaturedFragment): CardProps {
  const entryProps = content?.entry != null ? mapCardsContent(content?.entry) : undefined;
  const image = entryProps?.image ?? (content?.imageOverwrite?.gatsbyImageData as IGatsbyImageData) ?? undefined;
  const imageAlt = entryProps?.imageAlt ?? content?.imageOverwrite?.description ?? undefined;

  return {
    ...entryProps,
    key: content?.id,
    id: content?.contentfulid ?? undefined,
    title: entryProps?.title ?? '',
    image,
    imageAlt,
    imagePosition: content?.imageAlignment === 'Right' ? 'right' : 'left',
  };
}
