import React, { FC, useMemo } from 'react';
import Hero from '../hero/Hero';
import { HeroProps, HeroVariants } from '../hero/props';
import { componentQuery } from './query';
import Slider from '../slider/Slider';

type Props = {
  id?: string;
  variant?: HeroVariants;
  slides: HeroProps[];
};

const HeroSlider: FC<Props> = ({ id, variant = HeroVariants.FULL, slides }) => {
  const childrenSlides = useMemo(() => {
    return slides.map((slide, index) => <Hero key={index} variant={variant} {...slide} fadeInEnabled={false} />);
  }, [slides, variant]);

  return <Slider id={id} slides={childrenSlides} />;
};

export const query = componentQuery;

export default HeroSlider;
