import React, { FC } from 'react';
import { ButtonVariants } from '../button/buttonProps';
import PagesListChosen from '../pages-list-chosen/PagesListChosen';
import { RelatedCardsContext, RelatedCardsTypes } from './props';

type Props = {
  context: RelatedCardsContext;
} & RelatedCardsTypes;

const cardsTitles: Record<keyof RelatedCardsTypes, string> = {
  projects: 'Related projects',
  stories: 'Related stories',
  publications: 'Related publications',
  articles: 'Related articles',
  press: 'Related press releases',
};

const cardsPaths: Record<keyof RelatedCardsTypes, string> = {
  projects: '/projects',
  stories: '/stories',
  publications: '/publications',
  articles: '/media',
  press: '/press',
};

const RelatedCards: FC<Props> = ({ context, ...cards }) => {
  return (
    <>
      {cards[context].length > 0 && (
        <PagesListChosen
          id={`related-cards-${context}`}
          title={cardsTitles[context]}
          pages={cards[context]}
          cta={{
            variant: ButtonVariants.Solid,
            id: `related-cards-cta-${context}`,
            href: cardsPaths[context],
          }}
          ctaLabel="View all"
        />
      )}
      {Object.keys(cards)
        .filter((cardKey) => cardKey !== context)
        .map((cardKey) => {
          const cardContext = cardKey as RelatedCardsContext;

          return cards[cardContext].length > 0 ? (
            <PagesListChosen
              key={cardKey}
              id={`related-cards-${cardContext}`}
              title={cardsTitles[cardContext]}
              pages={cards[cardContext]}
              cta={{
                variant: ButtonVariants.Solid,
                id: `related-cards-cta-${cardContext}`,
                href: cardsPaths[cardContext],
              }}
              ctaLabel="View all"
            />
          ) : null;
        })}
    </>
  );
};

export default RelatedCards;
