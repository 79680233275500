import React, { FC } from 'react';
import styled from '@emotion/styled';
import { GatsbyImage } from 'gatsby-plugin-image';
import { motion } from 'framer-motion';
import { MediaBlockProps } from './props';
import { useViewportFadeIn } from '../../hooks/viewport-fade-in';
import { maxMediaQuery } from '../grid';
import MediaBlockVideo from './MediaBlockVideo';

const MediaBlockFullWidth: FC<MediaBlockProps> = ({
  id,
  fluidImage,
  imageAlt,
  children,
  withTransition,
  videoOpen = false,
  videoUrl,
}) => {
  const [containerRef, containerControls] = useViewportFadeIn(withTransition);

  return (
    <Container id={id} ref={containerRef} animate={containerControls}>
      {fluidImage != null && (
        <BackgroundImage
          image={fluidImage}
          alt={imageAlt != null && imageAlt !== '' ? imageAlt : 'Media block background'}
        />
      )}
      {children}
      {videoOpen === true && videoUrl != null && (
        <VideoContainer>
          <MediaBlockVideo videoUrl={videoUrl} videoOpen={videoOpen} />
        </VideoContainer>
      )}
    </Container>
  );
};

const Container = styled(motion.div)`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 600px;
  width: 100%;

  > div {
    height: 100%;
  }

  ${maxMediaQuery.sm} {
    min-height: 300px;
  }
`;

const BackgroundImage = styled(GatsbyImage)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;

  ${maxMediaQuery.sm} {
    position: relative;
    height: 300px !important;
    width: 100%;
  }
`;

const VideoContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
`;

export default MediaBlockFullWidth;
