import styled from '@emotion/styled';
import { maxMediaQuery } from '../grid';
import OverlayBase from './OverlayBase';
import { OverlayProps } from './props';

const OverlayNone = styled(OverlayBase)<OverlayProps>`
  padding: ${({ theme }) => `${theme.spacing.unit * 15}px 0`};
  color: ${({ theme }) => theme.colors.primary.copy};

  ${maxMediaQuery.sm} {
    padding: ${({ theme }) => `${theme.spacing.unit * 4}px 0`};
  }
`;

export default OverlayNone;
