import React, { FC, useCallback } from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { Col, GridContainer, maxMediaQuery, Row } from '../grid';
import { useViewportSlideIn } from '../../hooks/viewport-slide-in';
import { ConditionalWrapper } from '../wrapper/ConditionalWrapper';
import './query';

type Props = {
  body: string;
  author: string;
  id?: string;
  withContainer?: boolean;
  withSpaceTop?: boolean;
  withSpaceBottom?: boolean;
};

const Quote: FC<Props> = ({
  body,
  author,
  id,
  withContainer = true,
  withSpaceTop = true,
  withSpaceBottom = true,
  ...props
}) => {
  const [containerRef, containerControls] = useViewportSlideIn();
  const handleWithContainer = useCallback(
    (children: React.ReactNode) => <GridContainer id={id}>{children}</GridContainer>,
    [withContainer, id]
  );

  return (
    <ConditionalWrapper condition={withContainer === true} wrapperPositive={handleWithContainer}>
      <StyledRow
        id={withContainer === false ? id : undefined}
        withSpaceTop={withSpaceTop}
        withSpaceBottom={withSpaceBottom}
      >
        <Col>
          <Container id={id} {...props} ref={containerRef} animate={containerControls}>
            <QuoteSign>“</QuoteSign>
            <Content>
              <Copy>{body}</Copy>
              <Author>{author}</Author>
            </Content>
          </Container>
        </Col>
      </StyledRow>
    </ConditionalWrapper>
  );
};

const StyledRow = styled(Row)<{ withSpaceTop: boolean; withSpaceBottom: boolean }>`
  margin-top: ${({ theme, withSpaceTop }) => (withSpaceTop === true ? `${theme.spacing.unit * 5}px` : 0)};
  margin-bottom: ${({ theme, withSpaceBottom }) => (withSpaceBottom === true ? `${theme.spacing.unit * 5}px` : 0)};

  ${maxMediaQuery.sm} {
    margin-top: ${({ theme, withSpaceTop }) => (withSpaceTop === true ? `${theme.spacing.unit * 3}px` : 0)};
    margin-bottom: ${({ theme, withSpaceBottom }) => (withSpaceBottom === true ? `${theme.spacing.unit * 3}px` : 0)};
  }
`;

const Container = styled(motion.div)`
  position: relative;
  display: flex;
  width: 100%;
  height: auto;
  background-color: ${(props) => props.theme.colors.secondary.coral.normal};
  border-radius: 24px;
  color: ${(props) => props.theme.colors.primary.comicReliefDeepViolet};
`;

const QuoteSign = styled.div`
  max-width: 40%;
  font-family: ${(props) => props.theme.fonts.headers};
  font-style: normal;
  font-weight: 400;
  font-size: 200px;
  line-height: 200px;
  text-align: center;
  text-transform: uppercase;
  margin: ${(props) => props.theme.spacing.unit * 5}px 0px 0px ${(props) => props.theme.spacing.unit * 5}px;

  ${maxMediaQuery.md} {
    font-size: 140px;
    margin: 10px 0px 0px 30px;
  }

  ${maxMediaQuery.sm} {
    font-size: 50px;
    line-height: 50px;
    margin: 20px 0px 0px 20px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  margin: 40px !important;
  position: relative;
  width: 100%;

  ${maxMediaQuery.sm} {
    margin: 20px !important;
  }
`;

const Copy = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 36px;
  min-height: 115px;
  font-family: ${(props) => props.theme.fonts.headers};
  text-transform: uppercase;

  ${maxMediaQuery.md} {
    font-size: 24px;
    line-height: 30px;
  }

  ${maxMediaQuery.sm} {
    font-size: 20px;
    line-height: 28px;
  }
`;

const Author = styled.div`
  flex: 1;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: right;
  text-transform: uppercase;
  margin-top: 24px;

  ${maxMediaQuery.sm} {
    font-size: 14px;
    line-height: 18px;
  }
`;

export default Quote;
