import React, { FC } from 'react';
import styled from '@emotion/styled';

const PageVariantLanding: FC = ({ children }) => {
  return <Container>{children}</Container>;
};

const Container = styled.div`
  position: relative;
`;

export default PageVariantLanding;
