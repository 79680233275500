import React, { FC } from 'react';
import './query';
import CardArticle from './CardArticle';
import CardFeatured from './CardFeatured';
import CardProject from './CardProject';
import CardPublication from './CardPublication';
import CardStory from './CardStory';
import { CardProps, CardVariant } from './props';

type Props = {
  variant?: CardVariant;
} & CardProps;

const variants: Record<CardVariant, React.FC<CardProps>> = {
  [CardVariant.PROJECT]: CardProject,
  [CardVariant.STORY]: CardStory,
  [CardVariant.FEATURED]: CardFeatured,
  [CardVariant.PUBLICATION]: CardPublication,
  [CardVariant.ARTICLE]: CardArticle,
  [CardVariant.PRESS]: CardArticle,
};

const Card: FC<Props> = ({ variant = CardVariant.ARTICLE, ...componentProps }) => {
  const Component = variants[variant];
  return <Component {...componentProps} />;
};

export default Card;
