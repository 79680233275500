import styled from '@emotion/styled';
import React, { FC } from 'react';
import Hero from '../components/hero/Hero';
import { HeroVariants } from '../components/hero/props';
import Button from '../components/button/Button';
import { OverlayAlignmentVariant, OverlayBackgroundVariant, OverlayVariant } from '../components/overlay/props';
import { GridContainer, maxMediaQuery } from '../components/grid';

const NotFoundErorPage: FC = () => {
  return (
    <>
      <Hero
        variant={HeroVariants.FIT_TO_CONTENT}
        heading="Oops!"
        background={OverlayBackgroundVariant.CORAL}
        overlayVariant={OverlayVariant.NONE}
        alignment={OverlayAlignmentVariant.CENTER}
      />

      <StyledGridContainer>
        <CopyContainer>
          <p>We can&apos;t seem to find the page you&apos;re looking for.</p>
          <p>
            This might be because you&apos;ve accidentally typed the web address incorrectly or perhaps the page
            you&apos;re looking for has been removed, updated or deleted.
          </p>
        </CopyContainer>
        <Button href="/" title="Back to homepage" icon="arrow-long-right">
          Back to homepage
        </Button>
      </StyledGridContainer>
    </>
  );
};

const CopyContainer = styled.div`
  width: 100%;
  max-width: 736px;
  text-align: center;
`;

const StyledGridContainer = styled(GridContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 80px;
  padding-bottom: 80px;

  ${maxMediaQuery.lg} {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  ${maxMediaQuery.sm} {
    padding-top: 20px;
    padding-bottom: 20px;
  }
`;

export default NotFoundErorPage;
