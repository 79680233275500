import {
  ComponentCardArticleFragment,
  ComponentPagesListFragment,
  ComponentCardPressFragment,
  ComponentCardProjectFragment,
  ComponentCardStoryFragment,
  ComponentCardPublicationFragment,
} from 'graphql-types';
import { DropdownItem } from '../../components/dropdown/props';
import { CardVariant } from '../../components/card/props';
import { PageListFilterItem, PagesListItem, PagesListProps, PagesListVariant } from '../../components/pages-list/props';
import {
  mapContentCardArticleToProps,
  mapContentCardPressToProps,
  mapContentCardProjectToProps,
  mapContentCardPublicationToProps,
  mapContentCardStoryToProps,
} from './card';

export function mapContentPagesListToProps(content: ComponentPagesListFragment): PagesListProps {
  return {
    id: content?.contentfulid ?? undefined,
    title: content?.title ?? '',
    showFiltering: content?.showFiltering ?? false,
    variant: (content?.pageType as PagesListVariant) ?? PagesListVariant.ARTICLE,
  };
}

// Article
export function mapContentPageListArticleToListItems(content: ComponentCardArticleFragment[]): PagesListItem[] {
  if (content != null) {
    return content.map((item) => ({
      ...mapContentCardArticleToProps(item),
      variant: CardVariant.ARTICLE,
    }));
  }

  return [];
}

export function mapContentPageListArticleToListFilters(content: ComponentCardArticleFragment[]): PageListFilterItem[] {
  if (content == null) {
    return [];
  }

  const filterItems: DropdownItem[] = [];

  content.forEach((item) => {
    if (item.tag != null) {
      const existingItem = filterItems.find((filterItem) => filterItem.key === item.tag?.id);

      if (existingItem == null) {
        filterItems.push({ key: item.tag.id, value: item.tag.id, label: item.tag.name ?? '' });
      }
    }
  });

  return [
    {
      key: 'type',
      label: 'Filter by type',
      options: filterItems,
    },
  ];
}

// Press
export function mapContentPageListPressToListItems(content: ComponentCardPressFragment[]): PagesListItem[] {
  if (content != null) {
    return content.map((item) => ({
      ...mapContentCardPressToProps(item),
      variant: CardVariant.PRESS,
    }));
  }

  return [];
}

export function mapContentPageListPressToListFilters(content: ComponentCardPressFragment[]): PageListFilterItem[] {
  if (content == null) {
    return [];
  }

  const filterItems: DropdownItem[] = [];

  content.forEach((item) => {
    if (item.tag != null) {
      const existingItem = filterItems.find((filterItem) => filterItem.key === item.tag?.id);

      if (existingItem == null) {
        filterItems.push({ key: item.tag.id, value: item.tag.id, label: item.tag.name ?? '' });
      }
    }
  });

  return [
    {
      key: 'type',
      label: 'Filter by type',
      options: filterItems,
    },
  ];
}

// Press
export function mapContentPageListProjectToListItems(content: ComponentCardProjectFragment[]): PagesListItem[] {
  if (content != null) {
    return content.map((item) => ({
      ...mapContentCardProjectToProps(item),
      variant: CardVariant.PROJECT,
    }));
  }

  return [];
}

export function mapContentPageListProjectToListFilters(content: ComponentCardProjectFragment[]): PageListFilterItem[] {
  if (content == null) {
    return [];
  }

  const typeFilterItems: DropdownItem[] = [];
  const locationFilterItems: DropdownItem[] = [];

  content.forEach((item) => {
    if (item.types != null && item.types.length > 0) {
      (item.types as { id: string; name: string }[]).forEach((type) => {
        const existingItem = typeFilterItems.find((filterItem) => filterItem.key === type.id);

        if (existingItem == null) {
          typeFilterItems.push({ key: type.id, value: type.id, label: type.name ?? '' });
        }
      });
    }

    if (item.locationTag != null) {
      const existingItem = locationFilterItems.find((filterItem) => filterItem.key === item.locationTag?.id);

      if (existingItem == null) {
        locationFilterItems.push({
          key: item.locationTag.id,
          value: item.locationTag.id,
          label: item.locationTag.name ?? '',
        });
      }
    }
  });

  return [
    {
      key: 'location',
      label: 'Filter by location',
      options: locationFilterItems,
    },
    {
      key: 'type',
      label: 'Filter by type',
      options: typeFilterItems,
    },
  ];
}

// Story
export function mapContentPageListStoryToListItems(content: ComponentCardStoryFragment[]): PagesListItem[] {
  if (content != null) {
    return content.map((item) => ({
      ...mapContentCardStoryToProps(item),
      variant: CardVariant.STORY,
    }));
  }

  return [];
}

export function mapContentPageListStoryToListFilters(content: ComponentCardStoryFragment[]): PageListFilterItem[] {
  if (content == null) {
    return [];
  }

  const tagFilterItems: DropdownItem[] = [];

  content.forEach((item) => {
    if (item.tag != null) {
      const existingItem = tagFilterItems.find((filterItem) => filterItem.key === item.tag?.id);

      if (existingItem == null) {
        tagFilterItems.push({ key: item.tag.id, value: item.tag.id, label: item.tag.name ?? '' });
      }
    }
  });

  return [
    {
      key: 'type',
      label: 'Show',
      options: tagFilterItems,
    },
  ];
}

// Publication
export function mapContentPageListPublicationToListItems(content: ComponentCardPublicationFragment[]): PagesListItem[] {
  if (content != null) {
    return content.map((item) => ({
      ...mapContentCardPublicationToProps(item),
      variant: CardVariant.PUBLICATION,
    }));
  }

  return [];
}

export function mapContentPageListPublicationToListFilters(
  content: ComponentCardPublicationFragment[]
): PageListFilterItem[] {
  if (content == null) {
    return [];
  }

  const tagFilterItems: DropdownItem[] = [];

  content.forEach((item) => {
    if (item.type != null) {
      const existingItem = tagFilterItems.find((filterItem) => filterItem.key === item.type?.id);

      if (existingItem == null) {
        tagFilterItems.push({ key: item.type.id, value: item.type.id, label: item.type.name ?? '' });
      }
    }
  });

  return [
    {
      key: 'type',
      label: 'Filter by type',
      options: tagFilterItems,
    },
  ];
}
