import { graphql, useStaticQuery } from 'gatsby';
import React, { FC, useMemo } from 'react';
import { PagesListStoryQuery } from 'graphql-types';
import { PageListFilterItem, PagesListItem, PagesListSharedProps } from './props';
import {
  mapContentPageListStoryToListFilters,
  mapContentPageListStoryToListItems,
} from '../../utils/content/pages-list';
import PagesListBody from './PagesListBody';

const PagesListStory: FC<PagesListSharedProps> = ({ showFiltering }) => {
  const data = useStaticQuery<PagesListStoryQuery>(graphql`
    query PagesListStory {
      allContentfulStory(
        sort: { order: DESC, fields: createdAt }
        filter: { slug: { regex: "/^(?!dont\\-remove).+/" } }
      ) {
        nodes {
          ... on Node {
            ...ComponentCardStory
          }
        }
      }
    }
  `);
  const listItems = useMemo<PagesListItem[]>(() => {
    const items = data?.allContentfulStory?.nodes;

    return mapContentPageListStoryToListItems(items);
  }, [data]);
  const listFilters = useMemo<PageListFilterItem[]>(() => {
    const items = data?.allContentfulStory?.nodes;

    return mapContentPageListStoryToListFilters(items);
  }, [data]);

  return (
    <PagesListBody items={listItems} filters={listFilters} showFiltering={showFiltering ?? false} multipleFiltering />
  );
};

export default PagesListStory;
