import React, { FC, useCallback, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { useKey } from 'react-use';
import { maxMediaQuery } from '../grid';
import Icon from '../icon/Icon';
import { useScrollLock } from '../../hooks/useScrollLock';

type ModalProps = {
  visible: boolean;
  closeFunction?: () => void;
};

const Modal: FC<ModalProps> = ({ visible, closeFunction, children }) => {
  const bodyRef = useRef<HTMLDivElement | null>(null);
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { ref, enableScroll, disableScroll } = useScrollLock<HTMLDivElement>();

  const handleEscapeKey = useCallback(() => {
    if (visible === true && closeFunction) {
      closeFunction();
    }
  }, [visible, closeFunction]);

  const handleBackdropClick = useCallback(
    (event: React.MouseEvent) => {
      if (
        bodyRef == null ||
        (event.target instanceof Element && bodyRef.current?.contains(event.target as Node) === false)
      ) {
        closeFunction?.();
      }
    },
    [visible, closeFunction]
  );

  useEffect(() => {
    if (visible === true) {
      disableScroll();
    } else {
      enableScroll();
    }
  }, [visible, enableScroll, disableScroll]);

  useKey('Escape', handleEscapeKey);

  return (
    <>
      {visible && (
        <ModalWrapper>
          <ModalInnerContainer ref={ref}>
            <ModalBackdrop onClick={handleBackdropClick}>
              <ModalBody ref={bodyRef}>
                {children}
                {closeFunction && (
                  <CloseModalButton type="button" onClick={closeFunction}>
                    <Icon iconType="plus" />
                  </CloseModalButton>
                )}
              </ModalBody>
            </ModalBackdrop>
          </ModalInnerContainer>
        </ModalWrapper>
      )}
    </>
  );
};

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 1500;
`;

const ModalInnerContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
`;

const ModalBackdrop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100vw;
  min-height: 100vh;

  ${maxMediaQuery.sm} {
    padding: 20px 20px 90px;
  }
`;

const ModalBody = styled.div`
  position: relative;
  display: block;
  max-width: 650px;
  padding: ${(props) => props.theme.spacing.unit * 4}px;
  background: ${(props) => props.theme.colors.primary.white};
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2);
  border-radius: 24px;

  ${maxMediaQuery.md} {
    width: 100%;
    padding: 40px 20px;
  }
`;

const CloseModalButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  outline: none;
  z-index: 100;
  cursor: pointer;
  width: 32px;
  height: 32px;

  svg {
    height: 100%;
    transform: rotate(45deg);
  }

  ${maxMediaQuery.md} {
    width: 28px;
    height: 28px;
  }

  ${maxMediaQuery.sm} {
    width: 24px;
    height: 24px;
    top: 10px;
    right: 20px;
  }
`;

export default Modal;
