import styled from '@emotion/styled';

type Props = {
  backgroundColor?: string;
  borderColor?: string;
};

const GridPlaceholder = styled.div<Props>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 70px;
  background-color: ${(props) => props.backgroundColor || '#fff'};
  border: 1px solid ${(props) => props.borderColor || '#ddd'};
`;

export default GridPlaceholder;
