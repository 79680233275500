import React, { FC, SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { ComposableMap, Geographies, Geography, Marker, ZoomableGroup, Point } from 'react-simple-maps';
import { useCounter, useDebounce, useEffectOnce, useEvent, useKey, useToggle } from 'react-use';
import { motion } from 'framer-motion';
import { useTheme } from '@emotion/react';
import Icon from '../icon/Icon';
import { maxMediaQuery } from '../grid';
import { getApplicationTargetEntry } from '../../utils/content/url';
import { useBreakpoint } from '../../hooks/breakpoint';
import CurrentMapItemIndicator from './CurrentItemIndicator';
import { MarkerData } from './props';
import { isBrowser } from '../../utils/system';

type Props = {
  markers: MarkerData[];
  inModal?: boolean;
  onOpenMobileMap?(): void;
};

const CARD_WIDTH = 370;
const CARD_HEIGHT = 322;
const MAP_HEIGHT = 350;

const DESKTOP_ZOOM = 2;
const CURRENT_PROJECT_CARD_ID = 'current-project-map-card';
const GEO_URL = `${process.env.GATSBY_SITE_URL ?? ''}/world-map.json`;

const ProjectsMapElement: FC<Props> = ({ markers, inModal = false, onOpenMobileMap }) => {
  const theme = useTheme();
  const containerRef = useRef<HTMLDivElement>(null);
  const refCard = useRef<HTMLDivElement>(null);
  const markerOpenRef = useRef<boolean>(false);
  const markersRef = useRef<SVGPathElement[]>([]);
  const currentMarkerIndexRef = useRef<number | undefined>();
  const [mapHeight, setMapHeight] = useState(MAP_HEIGHT);
  const [mapWidth, setMapWidth] = useState(0);
  const [position, setPosition] = useState<{ coordinates: Point; zoom: number }>({
    coordinates: [0, 0],
    zoom: DESKTOP_ZOOM,
  });
  const [showMapOverlay, toggleShowMapOverlay] = useToggle(false);
  const [resizeCount, { inc: increaseResizeCount }] = useCounter(0);

  const { md: breakpointMd, xl: breakpointXl } = useBreakpoint();

  const [cardContent, setCardContent] = useState<MarkerData>({
    name: undefined,
    location: undefined,
    description: undefined,
    tag: undefined,
    path: '',
    displayMode: 'none',
    currentTarget: undefined,
  });

  const [cardPosition, setCardPosition] = useState<MarkerData>({
    left: 0,
    top: 0,
  });

  const showMarker = useCallback(
    (event: SyntheticEvent, item: MarkerData, markerIndex: number) => {
      const { name, location, description, tag } = item;
      const path = getApplicationTargetEntry(item.path || '#', 'ContentfulProject');

      currentMarkerIndexRef.current = markerIndex;
      markerOpenRef.current = true;

      setCardContent({
        name,
        location,
        description,
        tag,
        displayMode: 'flex',
        path,
        currentTarget: event?.target as HTMLElement,
      });
    },
    [setCardContent]
  );

  const hideMarker = useCallback(() => {
    currentMarkerIndexRef.current = undefined;
    markerOpenRef.current = false;

    setCardContent((prevState) => ({
      ...prevState,
      displayMode: 'none',
    }));
  }, [setCardContent]);

  const handleEscape = useCallback(() => {
    if (cardContent.displayMode !== 'none') {
      hideMarker();
    }
  }, [cardContent, hideMarker]);

  const handleTab = useCallback(
    (event: KeyboardEvent) => {
      if (markerOpenRef.current !== true) {
        return;
      }

      const { current: currentMarkerIndex } = currentMarkerIndexRef;

      if (currentMarkerIndex !== markers.length - 1) {
        event.preventDefault();

        if (currentMarkerIndex != null && markersRef.current[currentMarkerIndex + 1] != null) {
          const nextMarkerElement = markersRef.current[currentMarkerIndex + 1];

          setTimeout(() => {
            (nextMarkerElement?.firstElementChild as SVGPathElement)?.focus?.();
          }, 0);
        }
      } else {
        hideMarker();
      }
    },
    [markers, hideMarker]
  );

  const handleZoomIn = useCallback(() => {
    setPosition((previousPosition) => {
      const newZoom = previousPosition.zoom * 1.2;

      if (previousPosition.zoom >= DESKTOP_ZOOM * 2) {
        return previousPosition;
      }

      return {
        ...previousPosition,
        zoom: newZoom,
      };
    });
  }, [setPosition]);

  const handleZoomOut = useCallback(() => {
    setPosition((previousPosition) => {
      const newZoom = previousPosition.zoom / 1.2;

      if (previousPosition.zoom <= DESKTOP_ZOOM) {
        return previousPosition;
      }

      return {
        ...previousPosition,
        zoom: newZoom,
      };
    });
  }, [setPosition]);

  const handleMoveEnd = useCallback(
    (eventPosition) => {
      setPosition(eventPosition);
    },
    [setPosition]
  );

  const filterMapEvent = useCallback(
    (event: MouseEvent | TouchEvent | WheelEvent) => {
      if (event instanceof WheelEvent) {
        return false;
      }

      if (window?.TouchEvent != null && event instanceof TouchEvent && inModal === false && breakpointXl === false) {
        toggleShowMapOverlay(true);
        return false;
      }

      return true;
    },
    [inModal, breakpointXl, toggleShowMapOverlay]
  );

  const handleResize = useCallback(() => {
    increaseResizeCount();
  }, [increaseResizeCount]);

  const handleMapClick = useCallback(() => {
    if (inModal === false && showMapOverlay === true) {
      onOpenMobileMap?.();
    }
  }, [inModal, showMapOverlay, onOpenMobileMap]);

  useKey('Escape', handleEscape);
  useKey('Tab', handleTab);

  useEvent('resize', handleResize);
  useEffectOnce(handleResize);

  useDebounce(
    () => {
      const { current: containerElement } = containerRef;

      if (isBrowser() === false || containerElement == null) {
        return;
      }

      const itemRects = containerElement.getBoundingClientRect();

      setMapHeight(itemRects.height);
      setMapWidth(itemRects.width);
      setPosition((previousPosition) => {
        if (previousPosition.coordinates[0] === 0 && previousPosition.coordinates[1] === 0) {
          return {
            ...previousPosition,
            coordinates: [15, 5],
          };
        }

        return previousPosition;
      });
    },
    50,
    [resizeCount, setPosition, setMapHeight, setMapWidth]
  );

  useEffect(() => {
    markersRef.current = markersRef.current.slice(0, markers.length);
  }, [markers]);

  useEffect(() => {
    const focusItemBoundingRect = cardContent.currentTarget ? cardContent.currentTarget.getBoundingClientRect() : null;
    const scrollLeft = window?.pageXOffset || document.documentElement.scrollLeft;
    const scrollTop = window?.pageYOffset || document.documentElement.scrollTop;
    const cardHeight = refCard?.current?.clientHeight || CARD_HEIGHT;
    const cardWidth = refCard?.current?.clientWidth || CARD_WIDTH;
    let left: number | undefined;
    let top: number | undefined;

    if (inModal === true) {
      left = focusItemBoundingRect
        ? focusItemBoundingRect.left - cardWidth / 2 + focusItemBoundingRect?.width / 2
        : cardPosition.left;
      top = focusItemBoundingRect ? focusItemBoundingRect.top - cardHeight : cardPosition.top;
    } else {
      left = focusItemBoundingRect
        ? focusItemBoundingRect.left + scrollLeft - cardWidth / 2 + focusItemBoundingRect?.width / 2
        : cardPosition.left;
      top = focusItemBoundingRect ? focusItemBoundingRect.top + scrollTop - (cardHeight + 130) : cardPosition.top;
    }

    setCardPosition({
      left,
      top,
    });
  }, [cardContent, inModal]);

  useEffect(() => {
    if (showMapOverlay === true) {
      setTimeout(() => {
        toggleShowMapOverlay(false);
      }, 5000);
    }
  }, [showMapOverlay]);

  return (
    <>
      <MapContainer ref={containerRef} inModal={inModal} onClick={handleMapClick}>
        <StyledComposableMap height={mapHeight} width={mapWidth}>
          <ZoomableGroup
            zoom={position.zoom}
            center={position.coordinates}
            onMoveEnd={handleMoveEnd}
            onMoveStart={hideMarker}
            filterZoomEvent={filterMapEvent}
            // translateExtent={TRANSLATE_EXTEND}
          >
            <Geographies geography={GEO_URL}>
              {({ geographies }) =>
                geographies.map((geo) => (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    style={{
                      default: {
                        fill: theme.colors.primary.comicReliefDeepViolet,
                        outline: 'none',
                      },
                      hover: {
                        fill: theme.colors.primary.comicReliefDeepViolet,
                        outline: theme.colors.primary.white,
                      },
                    }}
                    tabIndex={-1}
                  />
                ))
              }
            </Geographies>
            {markers.map(({ name, coordinates, description, location, tag, path }, index) => {
              return (
                <g
                  ref={(element) => {
                    markersRef.current[index] = element as SVGPathElement;
                  }}
                  key={path ?? name}
                >
                  <StyledMarker
                    coordinates={coordinates}
                    tabIndex={index === 0 ? 0 : -1}
                    onFocus={(e) => showMarker(e, { name, description, location, tag, path }, index)}
                  >
                    <circle r={!breakpointMd ? 8 : 5} fill={theme.colors.primary.comicReliefRed} />
                  </StyledMarker>
                </g>
              );
            })}
          </ZoomableGroup>
        </StyledComposableMap>
        {(breakpointMd === true || (breakpointMd === false && inModal === true)) && (
          <ControlContainer>
            <button onClick={handleZoomIn} type="button" aria-label="Map zoom-in">
              <Icon iconType="zoom-in" withStroke />
            </button>
            <button onClick={handleZoomOut} type="button" aria-label="Map zoom-out">
              <Icon iconType="zoom-out" withStroke />
            </button>
          </ControlContainer>
        )}
        {inModal === false && showMapOverlay === true && (
          <Overlay>
            <span>Tap to interact</span>
          </Overlay>
        )}
      </MapContainer>
      <CurrentMapItemIndicator
        key={CURRENT_PROJECT_CARD_ID}
        left={cardPosition.left ?? 0}
        top={cardPosition.top ?? 0}
        title={cardContent.name ?? ''}
        displayMode={cardContent.displayMode ?? 'none'}
        ref={refCard}
        id={CURRENT_PROJECT_CARD_ID}
        location={cardContent.location}
        excerpt={cardContent.description}
        path={cardContent.path}
        bottomType={cardContent.tag}
        closeMethod={hideMarker}
        mobileView={!breakpointMd}
      />
    </>
  );
};

const MapContainer = styled.div<{ inModal: boolean }>`
  position: relative;
  max-height: ${({ inModal }) => (inModal === true ? 'none' : '85vh')};
  height: ${({ inModal }) => (inModal === true ? '100vh' : '70vh')};
  background-color: ${({ theme }) => theme.colors.secondary.blue.light};
  overflow: hidden;

  ${maxMediaQuery.lg} {
    height: ${({ inModal }) => (inModal === true ? '100%' : '70vh')};
  }
`;

const StyledComposableMap = styled(ComposableMap)`
  fill: ${({ theme }) => theme.colors.primary.comicReliefDeepViolet};
  height: 100%;
  width: 100%;
  margin: 0px;
`;

const StyledMarker = styled(Marker)`
  > circle {
    stroke: ${(props) => props.theme.colors.primary.white};
    stroke-width: 1;
  }

  &:hover,
  &:focus {
    > circle {
      transform: scale(1.3);
      transition: all 0.2s ease-in-out;
      fill: ${(props) => props.theme.colors.secondary.coral.normal};
    }
    outline: 0px;
    cursor: pointer;
  }
`;

const ControlContainer = styled.div`
  display: flex;
  position: absolute;
  flex-direction: column;
  bottom: 40px;
  right: 5%;

  & > button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 50px;
    background: ${({ theme }) => theme.colors.primary.comicReliefRed};
    color: ${({ theme }) => theme.colors.primary.white};
    border-radius: 30%;
    border: 0;
    font-size: 80px;
    line-height: 20px;
    font-family: ${({ theme }) => theme.fonts.headers};
    cursor: pointer;
    padding: 0px 6px;

    ${maxMediaQuery.md} {
      height: 45px;
      width: 45px;
    }
  }

  > button + button {
    margin-top: ${({ theme }) => theme.spacing.unit * 1}px;
  }

  ${maxMediaQuery.lg} {
    bottom: 30px;
  }

  ${maxMediaQuery.md} {
    bottom: 40px;
  }

  ${maxMediaQuery.sm} {
    bottom: 20px;
    right: 20px;
  }
`;

const Overlay = styled(motion.div)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.primary.white};
  font-size: ${({ theme }) => theme.typography.titleBody.fontSize};
  font-weight: 700;
  line-height: ${({ theme }) => theme.typography.titleBody.lineHeight};
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
`;

export default ProjectsMapElement;
