import { MutableRefObject, useCallback, useEffect, useRef } from 'react';
import { useUnmount } from 'react-use';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { isBrowser } from '../utils/system';

type HookReturnType<T> = {
  ref: MutableRefObject<T | null>;
  enableScroll(): void;
  disableScroll(): void;
};

export function useScrollLock<T = HTMLElement>(initialDisabled = false): HookReturnType<T> {
  const targetRef = useRef<T | null>(null);

  const enableScroll = useCallback(() => {
    if (isBrowser() === true && targetRef.current != null) {
      enableBodyScroll((targetRef.current as unknown) as HTMLElement);
    } else if (isBrowser()) {
      clearAllBodyScrollLocks();
    }
  }, []);

  const disableScroll = useCallback(() => {
    if (isBrowser() === true && targetRef.current != null) {
      disableBodyScroll((targetRef.current as unknown) as HTMLElement);
    }
  }, []);

  useEffect(() => {
    if (isBrowser() === true && initialDisabled === true) {
      disableScroll();
    }
  }, [disableScroll, initialDisabled]);

  useUnmount(() => {
    if (isBrowser() === true) {
      clearAllBodyScrollLocks();
    }
  });

  return {
    ref: targetRef,
    enableScroll,
    disableScroll,
  };
}
