import React, { FC, useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { PageListFilterItem, PageListFilterValue, PagesListItem } from './props';
import PagesListGrid from './PagesListGrid';
import PagesListFilter from './PagesListFilter';
import { sliceItemsByPage } from '../../utils/array';
import { CardVariant } from '../card/props';
import { getCardVariantByInternalType } from '../../utils/content/card';
import { Row } from '../grid';

const LOCATION_KEY = 'location';
const TYPE_KEY = 'type';
export const LIST_LIMIT = 12;

type Props = {
  items: PagesListItem[];
  filters: PageListFilterItem[];
  showFiltering: boolean;
  multipleFiltering?: boolean;
  showPagination?: boolean;
  filterByType?: boolean;
};

const PagesListBody: FC<Props> = ({
  items,
  filters,
  showFiltering,
  multipleFiltering = false,
  showPagination = true,
  filterByType = false,
}) => {
  const [filteredItems, setFilteredItems] = useState<PagesListItem[]>([]);
  const [paginatedItems, setPaginatedItems] = useState<PagesListItem[]>([]);
  const [listPage, setListPage] = useState<number | undefined>();
  const [listPageToUpdate, setListPageToUpdate] = useState<number | undefined>();
  const [listLimit, setListLimit] = useState<number | undefined>();
  const [filtersValues, setFiltersValues] = useState<PageListFilterValue[]>([]);

  const handleListChange = useCallback(
    (currentPage: number, limit: number) => {
      setListPage(currentPage);
      setListLimit(limit);
      setListPageToUpdate(undefined);
    },
    [setListPage, setListLimit, setListPageToUpdate]
  );

  const handleFilterChange = useCallback(
    (filterValue: PageListFilterValue) => {
      setFiltersValues((currentFiltersValues) => {
        if (multipleFiltering === true && filterValue.value === undefined) {
          return currentFiltersValues.filter((currentFilterValue) => currentFilterValue.key !== filterValue.key);
        }

        if (multipleFiltering === true) {
          const existingFilter = currentFiltersValues.find(
            (currentFilterValue) =>
              currentFilterValue.key === filterValue.key && currentFilterValue.value === filterValue.value
          );

          if (existingFilter != null) {
            return currentFiltersValues.filter(
              (currentFilterValue) =>
                currentFilterValue.key === filterValue.key && currentFilterValue.value !== filterValue.value
            );
          }

          return [...currentFiltersValues, filterValue];
        }

        const newFiltersValues = currentFiltersValues.filter(
          (currentFilterValue) => currentFilterValue.key !== filterValue.key
        );

        return [...newFiltersValues, filterValue];
      });

      setListPageToUpdate(undefined);
    },
    [multipleFiltering, setFiltersValues, setListPageToUpdate]
  );

  const handleFilterByType = useCallback(() => {
    const typeValue = filtersValues.find((filterValue) => filterValue.key === TYPE_KEY);

    if (typeValue?.value != null) {
      setFilteredItems(
        items.filter((listItem) => {
          const variant = getCardVariantByInternalType(typeValue.value ?? '');

          return variant === listItem.variant;
        })
      );
    } else {
      setFilteredItems(items);
    }
  }, [items, filtersValues, setFilteredItems]);

  const handleFilterByOtherValues = useCallback(() => {
    const tagValue = filtersValues.find((filterValue) => filterValue.key === TYPE_KEY);
    const locationValue = filtersValues.find((filterValue) => filterValue.key === LOCATION_KEY);
    const itemsType = items[0]?.variant;

    switch (itemsType) {
      case CardVariant.ARTICLE:
      case CardVariant.PRESS:
        if (tagValue == null || tagValue.value == null) {
          setFilteredItems(items);
        } else {
          setFilteredItems(
            items.filter((listItem) => tagValue.value != null && listItem.bottomTypesIds?.includes(tagValue.value))
          );
        }
        break;

      case CardVariant.PROJECT:
        if ((tagValue == null || tagValue.value == null) && (locationValue == null || locationValue.value == null)) {
          setFilteredItems(items);
        } else {
          setFilteredItems(
            items.filter((listItem) => {
              if (tagValue?.value != null && locationValue?.value != null) {
                return (
                  listItem.bottomTypesIds?.includes(tagValue.value) &&
                  listItem.locationsIds?.includes(locationValue.value)
                );
              }

              return (
                (listItem.bottomTypesIds != null &&
                  tagValue?.value != null &&
                  listItem.bottomTypesIds.includes(tagValue.value)) ||
                (listItem.locationsIds != null &&
                  locationValue?.value != null &&
                  listItem.locationsIds.includes(locationValue.value))
              );
            })
          );
        }
        break;

      case CardVariant.STORY:
        {
          const allTagFilters = filtersValues
            .filter((filterValue) => filterValue.key === TYPE_KEY)
            .map(({ value }) => value);

          if (allTagFilters.length === 0) {
            setFilteredItems(items);
          } else {
            setFilteredItems(
              items.filter(
                (listItem) =>
                  listItem.bottomTypesIds != null &&
                  listItem.bottomTypesIds.some((bottonTypeId) => allTagFilters.includes(bottonTypeId))
              )
            );
          }
        }
        break;

      case CardVariant.PUBLICATION:
        if (tagValue == null || tagValue.value == null) {
          setFilteredItems(items);
        } else {
          setFilteredItems(
            items.filter((listItem) => tagValue.value != null && listItem.topTypesIds?.includes(tagValue.value))
          );
        }
        break;

      default:
        break;
    }
  }, [items, filtersValues, setFilteredItems]);

  useEffect(() => {
    if (filterByType === true) {
      handleFilterByType();
    } else {
      handleFilterByOtherValues();
    }

    setListPageToUpdate(1);
  }, [items, filtersValues, filterByType, handleFilterByType, handleFilterByOtherValues, setListPage]);

  useEffect(() => {
    if (listPage == null || listLimit == null) {
      return;
    }

    setPaginatedItems(sliceItemsByPage(filteredItems, listPage, listLimit));
  }, [filteredItems, listPage, listLimit]);

  return (
    <Container>
      <Row>
        {showFiltering === true && (
          <PagesListFilter
            filters={filters}
            values={filtersValues}
            multipleFiltering={multipleFiltering}
            onChange={handleFilterChange}
          />
        )}
      </Row>
      <PagesListGrid
        items={paginatedItems}
        itemsLength={filteredItems.length}
        limit={LIST_LIMIT}
        initialPage={listPageToUpdate}
        showPagination={showPagination}
        onChange={handleListChange}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export default PagesListBody;
