import styled from '@emotion/styled';
import React, { FC, useCallback, useRef, useState } from 'react';
import { AnimatePresence, motion, AnimationProps, DraggableProps, PanInfo } from 'framer-motion';
import { wrap } from 'popmotion';
import Icon from '../icon/Icon';
import { getSwipePower } from '../../utils/animation';
import { useViewportFadeIn } from '../../hooks/viewport-fade-in';
import { useViewportSlideIn } from '../../hooks/viewport-slide-in';
import { maxMediaQuery } from '../grid';

type Props = {
  id?: string;
  slides: React.ReactNode[];
};

const swipeConfidenceThreshold = 10000;

const slideVariants: AnimationProps['variants'] = {
  enter: (direction: number) => {
    return {
      x: direction > 0 ? 1000 : -1000,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction: number) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
};

const slideTransition: AnimationProps['transition'] = {
  x: { type: 'spring', stiffness: 300, damping: 30, duration: 0.5 },
  opacity: { duration: 0.2 },
};

const slideDragContaints: DraggableProps['dragConstraints'] = { left: 0, right: 0 };

const Slider: FC<Props> = ({ id, slides }) => {
  const currentSlideRef = useRef<HTMLLIElement>(null);
  const [[currentSlide, direction], setCurrentSlide] = useState<Array<number>>([0, 0]);
  const [containerRef, containerControls] = useViewportFadeIn();
  const [navigationRef, navigationControls] = useViewportSlideIn();
  const slideIndex = wrap(0, slides.length, currentSlide);

  const paginate = useCallback((newDirection: number) => {
    setCurrentSlide(([previousSlideValue]) => {
      return [previousSlideValue + newDirection, newDirection];
    });
  }, []);

  const previousSlide = useCallback(() => {
    paginate(-1);
  }, [paginate]);

  const nextSlide = useCallback(() => {
    paginate(1);
  }, [paginate]);

  const handleSlideDragEng = useCallback<(event: TouchEvent, info: PanInfo) => void>(
    (_event, { offset, velocity }) => {
      const swipePower = getSwipePower(offset.x, velocity.x);

      if (swipePower < -swipeConfidenceThreshold) {
        paginate(1);
      } else if (swipePower > swipeConfidenceThreshold) {
        paginate(-1);
      }
    },
    [paginate]
  );

  const handleSlideFocus = useCallback(() => {
    currentSlideRef.current?.focus();
  }, []);

  return (
    <Container id={id}>
      <AnimatePresence initial={false} custom={direction}>
        <SlidesContainer ref={containerRef} animate={containerControls}>
          {slides.map((slide, index) =>
            slideIndex === index ? (
              <Slide
                ref={currentSlideRef}
                key={index}
                tabIndex={-1}
                custom={direction}
                variants={slideVariants}
                initial="enter"
                animate="center"
                exit="exit"
                transition={slideTransition}
                drag="x"
                dragConstraints={slideDragContaints}
                dragElastic={0.5}
                onAnimationComplete={handleSlideFocus}
                onDragEnd={handleSlideDragEng}
              >
                {slide}
              </Slide>
            ) : null
          )}
        </SlidesContainer>
      </AnimatePresence>
      <NavigationContainer>
        <Navigation ref={navigationRef} animate={navigationControls}>
          <ArrowButton
            type="button"
            className="btn-restart"
            whileHover={{ x: -5 }}
            whileFocus={{ x: -5 }}
            onClick={previousSlide}
          >
            <Icon iconType="arrow-long-left" aria-hidden="true" />
            <span className="sr-only">Previous slide</span>
          </ArrowButton>
          <span className="sr-only">
            Slide {slideIndex + 1} out of {slides.length}
          </span>
          <NumberOfSlidesCopy aria-hidden="true">
            {slideIndex + 1} / {slides.length}
          </NumberOfSlidesCopy>
          <ArrowButton
            type="button"
            className="btn-restart"
            whileHover={{ x: 5 }}
            whileFocus={{ x: 5 }}
            onClick={nextSlide}
          >
            <Icon iconType="arrow-long-right" aria-hidden="true" />
            <span className="sr-only">Next slide</span>
          </ArrowButton>
        </Navigation>
      </NavigationContainer>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;
`;

const SlidesContainer = styled(motion.ul)`
  position: relative;
  margin: 0;
  padding: 0;
`;

const Slide = styled(motion.li)`
  list-style: none;
`;

const NavigationContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  z-index: 100;
`;

const Navigation = styled(motion.div)`
  display: flex;
  align-items: center;
  padding: 0 10px;
  font-size: ${({ theme }) => theme.typography.titleBody.fontSize};
  font-weight: 700;
  line-height: ${({ theme }) => theme.typography.titleBody.lineHeight};
  color: ${({ theme }) => theme.colors.primary.comicReliefRed};
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.primary.white};
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.2);

  ${maxMediaQuery.sm} {
    font-size: ${({ theme }) => theme.typography.body.fontSize};
    line-height: ${({ theme }) => theme.typography.body.lineHeight};
  }
`;

const NumberOfSlidesCopy = styled.span`
  display: flex;
  justify-content: center;
  width: 40px;
  white-space: nowrap;
`;

const ArrowButton = styled(motion.button)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 68px;
  padding: 0 20px;

  svg {
    fill: ${({ theme }) => theme.colors.primary.comicReliefRed};
  }

  ${maxMediaQuery.sm} {
    height: 50px;
  }
`;

export default Slider;
