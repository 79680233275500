import React, { FC, ReactNode } from 'react';
import { ThemeProvider } from '@emotion/react';
import { CookiesProvider } from 'react-cookie';
import './query';
import theme from '../../theme';
import GlobalStyles from '../layout/GlobalStyles';
import Layout from '../layout/Layout';
import MarketingServices from '../marketing-services/MarketingServices';
import { isBrowser } from '../../utils/system';

const App: FC<{ element: ReactNode }> = ({ element }) => {
  return (
    <>
      <CookiesProvider>
        <ThemeProvider theme={theme}>
          {isBrowser() && <MarketingServices />}
          <GlobalStyles />
          <Layout>{element}</Layout>
        </ThemeProvider>
      </CookiesProvider>
      <div id="portals" />
    </>
  );
};

export default App;
