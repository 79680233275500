import React, { FC, ReactNode, useCallback } from 'react';
import styled from '@emotion/styled';
import { GatsbyImage } from 'gatsby-plugin-image';
import { motion } from 'framer-motion';
import { MediaBlockProps } from './props';
import { ConditionalWrapper } from '../wrapper/ConditionalWrapper';
import { GridContainer, maxMediaQuery } from '../grid';
import { useViewportSlideIn } from '../../hooks/viewport-slide-in';
import MediaBlockVideo from './MediaBlockVideo';
import { OverlayVariant } from '../overlay/props';

const MediaBlockContainerWidth: FC<MediaBlockProps> = ({
  id,
  fluidImage,
  constrainedImage,
  overlayVariant,
  heading,
  excerpt,
  imageAlt,
  children,
  withContainer,
  withSpaceTop = true,
  withSpaceBottom = true,
  withTransition,
  inSlider,
  videoOpen = false,
  videoUrl,
}) => {
  const [containerRef, containerControls] = useViewportSlideIn(withTransition);
  const gridContainerWrapper = useCallback((wrapperChildren: ReactNode): JSX.Element => {
    return <GridContainer>{wrapperChildren}</GridContainer>;
  }, []);

  return (
    <SpacingContainer id={id} withSpaceTop={withSpaceTop} withSpaceBottom={withSpaceBottom}>
      <ConditionalWrapper condition={withContainer === true} wrapperPositive={gridContainerWrapper}>
        <Container ref={containerRef} inSlider={inSlider === true} animate={containerControls}>
          {inSlider === true && fluidImage != null && (
            <SliderBackgroundImage
              image={fluidImage}
              alt={imageAlt != null && imageAlt !== '' ? imageAlt : 'Media block background'}
            />
          )}
          {(inSlider === false || inSlider == null) && constrainedImage != null && (
            <BackgroundImage
              image={constrainedImage}
              alt={imageAlt != null && imageAlt !== '' ? imageAlt : 'Media block background'}
            />
          )}
          {children != null && (
            <ContentContainer
              noContent={(heading == null && excerpt == null) || overlayVariant === OverlayVariant.NONE}
            >
              {children}
            </ContentContainer>
          )}
          {videoOpen === true && videoUrl != null && (
            <VideoContainer>
              <MediaBlockVideo videoUrl={videoUrl} videoOpen={videoOpen} />
            </VideoContainer>
          )}
        </Container>
      </ConditionalWrapper>
    </SpacingContainer>
  );
};

const SpacingContainer = styled.div<{ withSpaceTop: boolean; withSpaceBottom: boolean }>`
  margin-top: ${({ theme, withSpaceTop }) => (withSpaceTop === true ? `${theme.spacing.unit * 5}px` : 0)};
  margin-bottom: ${({ theme, withSpaceBottom }) => (withSpaceBottom === true ? `${theme.spacing.unit * 5}px` : 0)};
`;

const Container = styled(motion.div)<{ inSlider: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: ${({ inSlider }) => (inSlider === true ? '500px' : 'auto')};
  width: 100%;
  border-radius: 24px;
  overflow: hidden;
  mask-image: -webkit-radial-gradient(white, black);

  > div {
    height: 100%;
  }
`;

const ContentContainer = styled.div<{ noContent: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10;

  > div {
    height: 100%;
  }

  ${maxMediaQuery.sm} {
    position: ${({ noContent }) => (noContent === true ? 'absolute' : 'relative')};
  }
`;

const BackgroundImage = styled(GatsbyImage)`
  flex-grow: 1;
  display: block;
  z-index: 5;
`;

const SliderBackgroundImage = styled(GatsbyImage)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 5;

  ${maxMediaQuery.sm} {
    position: relative;
    flex-grow: 1;
    width: 100%;
  }
`;

const VideoContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
`;

export default MediaBlockContainerWidth;
