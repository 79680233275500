import { useLocation } from '@reach/router';
import React from 'react';
import Helmet from 'react-helmet';
import { removeFollowingSlash } from '../../utils/url';
import { SeoProps } from './props';

const Seo: React.FC<SeoProps> = ({
  children,
  description,
  title,
  ogType,
  ogDescription,
  ogTitle,
  featuredImageUrl,
  canonicalUrl,
  noIndex = false,
  noFollow = false,
}) => {
  const location = useLocation();

  return (
    <Helmet
      htmlAttributes={{
        lang: 'en-GB',
      }}
      title={title}
      titleTemplate="%s | Fighting Malaria | Comic Relief and GSK"
    >
      {description != null && <meta name="description" content={description} />}
      {canonicalUrl != null ||
        (location?.pathname != null && (
          <link
            rel="canonical"
            href={canonicalUrl ?? `${removeFollowingSlash(process.env.GATSBY_SITE_URL ?? '')}${location.pathname}`}
          />
        ))}
      <meta property="og:url" content={process.env.GATSBY_SITE_URL} />
      {ogType !== 'None' && <meta property="og:type" content={ogType ?? 'website'} />}
      <meta property="og:site_name" content="Fighting Malaria | Comic Relief and GSK" />
      <meta property="og:title" content={ogTitle ?? title} />
      <meta property="og:description" content={ogDescription ?? description} />
      <meta property="og:locale" content="en-GB" />
      {featuredImageUrl != null && <meta property="og:image" content={`https:${featuredImageUrl}`} />}
      {noIndex && <meta name="robots" content="noindex" />}
      {noFollow && !noIndex && <meta name="robots" content="noFollow" />}

      {children}
    </Helmet>
  );
};

export default Seo;
