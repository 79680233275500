import styled from '@emotion/styled';
import { transparentize } from 'polished';
import { maxMediaQuery } from '../grid';
import OverlayBase from './OverlayBase';
import OverlayBox from './OverlayBox';
import { OverlayProps } from './props';

const OverlayGradient = styled(OverlayBase)<OverlayProps>`
  ${OverlayBox} {
    color: ${({ theme }) => theme.colors.primary.white};
    background: ${({ theme }) =>
      `linear-gradient(90deg, ${transparentize(
        0.2,
        theme.colors.primary.comicReliefDeepViolet
      )} -3.5%, transparent 94%)`};

    ${maxMediaQuery.sm} {
      background: ${({ theme }) => theme.colors.primary.comicReliefDeepViolet};
    }
  }
`;

export default OverlayGradient;
