import styled from '@emotion/styled';
import { AnimationProps, motion, useAnimation, useTransform, useViewportScroll } from 'framer-motion';
import React, { FC, useCallback, useRef, useState } from 'react';
import { MenuLink } from '../../models/content/menu-link';
import Link from '../link/Link';

type Props = MenuLink;

const subMenuAnimationVariants: AnimationProps['variants'] = {
  hidden: {
    x: '-50%',
    y: -100,
    opacity: 0,
    zIndex: -1,
    transitionEnd: {
      visibility: 'hidden',
    },
  },
  active: {
    x: '-50%',
    y: 0,
    opacity: 1,
    zIndex: -1,
    visibility: 'visible',
  },
};

const subMenuAnimationTransition: AnimationProps['transition'] = {
  y: { type: 'spring', stiffness: 300, damping: 30, duration: 0.5 },
  opacity: { duration: 0.4 },
};

const NavigationListItem: FC<Props> = ({ label, url, nestedElements }) => {
  const hoverActiveRef = useRef(false);
  const subMenuRef = useRef<HTMLUListElement | null>(null);
  const [subMenuActive, setSubMenuActive] = useState(false);
  const { scrollY } = useViewportScroll();
  const arrowBottomPosition = useTransform(scrollY, [0, 38], [23, 10]);
  const subMenuControls = useAnimation();

  const navLinkMouseEnterHandler = useCallback((): void => {
    hoverActiveRef.current = true;

    setTimeout(() => {
      if (hoverActiveRef.current === true && nestedElements.length > 0) {
        subMenuControls.start('active');
        setSubMenuActive(true);
      }
    }, 150);
  }, [nestedElements, setSubMenuActive]);

  const navLinkMouseLeaveHandler = useCallback(
    (event: React.MouseEvent | React.FocusEvent): void => {
      hoverActiveRef.current = false;

      if (
        subMenuRef == null ||
        (event.relatedTarget instanceof Element && subMenuRef.current?.contains(event.relatedTarget as Node) === false)
      ) {
        subMenuControls.start('hidden');
        setSubMenuActive(false);
      }
    },
    [nestedElements, setSubMenuActive]
  );

  return (
    <>
      <LinkContainer
        href={url}
        className={subMenuActive === true ? 'active' : undefined}
        activeClassName="active-route"
        onMouseEnter={navLinkMouseEnterHandler}
        onMouseLeave={navLinkMouseLeaveHandler}
        onFocus={navLinkMouseEnterHandler}
        onBlur={navLinkMouseLeaveHandler}
      >
        <LabelContainer>{label}</LabelContainer>
        {nestedElements.length > 0 && (
          <ArrowContainer style={{ bottom: arrowBottomPosition }}>
            <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 20">
              <circle className="nav-arrow-circle" cx="10.883" cy="10" r="9.5" />
              <path
                className="nav-arrow-path"
                d="M10.875 13.117a.713.713 0 01-.456-.164L6.14 9.388a.714.714 0 11.913-1.098l3.822 3.194 3.822-3.08a.712.712 0 011.005.107.713.713 0 01-.1 1.041l-4.278 3.444a.714.714 0 01-.449.121z"
              />
            </svg>
          </ArrowContainer>
        )}
      </LinkContainer>
      {nestedElements.length > 0 && (
        <SubMenuContainer
          ref={subMenuRef}
          animate={subMenuControls}
          variants={subMenuAnimationVariants}
          transition={subMenuAnimationTransition}
          initial="hidden"
          onMouseEnter={navLinkMouseEnterHandler}
          onMouseLeave={navLinkMouseLeaveHandler}
          onFocus={navLinkMouseEnterHandler}
          onBlur={navLinkMouseLeaveHandler}
        >
          {nestedElements.map((nestedElement) => (
            <li key={nestedElement.key}>
              <SubMenuItemLink href={nestedElement.url} activeClassName="active">
                {nestedElement.label}
              </SubMenuItemLink>
            </li>
          ))}
        </SubMenuContainer>
      )}
    </>
  );
};

const LabelContainer = styled.span`
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0px;
    width: 100%;
    height: 2px;
    background-color: ${({ theme }) => theme.colors.primary.copy};
    transform: scaleX(0);
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
`;

const ArrowContainer = styled(motion.span)`
  position: absolute;
  bottom: 23px;
  left: 50%;
  transform: translateX(-50%) rotate(0);
  transition: transform 0.25s ease-out;

  svg {
    display: block;
    width: 20px;
    height: 20px;

    .nav-arrow-circle {
      fill: transparent;
      stroke: ${({ theme }) => theme.colors.primary.comicReliefRed};
      transition: all 0.25s ease-out;
    }

    .nav-arrow-path {
      fill: ${({ theme }) => theme.colors.primary.comicReliefRed};
      transition: all 0.25s ease-out;
    }
  }
`;

const LinkContainer = styled(Link)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 20px;
  font-family: ${({ theme }) => theme.typography.body.fontFamily};
  font-size: ${({ theme }) => theme.typography.body.fontSize};
  font-weight: 700;
  line-height: ${({ theme }) => theme.typography.body.lineHeight};
  background-color: ${({ theme }) => theme.colors.primary.white};
  z-index: 5;

  &:hover,
  &.active,
  &.active-route {
    ${LabelContainer} {
      &::after {
        transform: scaleX(1);
        transform-origin: bottom left;
      }
    }
  }

  &:hover,
  &.active {
    ${ArrowContainer} {
      transform: translateX(-50%) rotate(-180deg);

      svg {
        .nav-arrow-circle {
          fill: ${({ theme }) => theme.colors.primary.comicReliefDeepViolet};
          stroke: ${({ theme }) => theme.colors.primary.comicReliefDeepViolet};
        }

        .nav-arrow-path {
          fill: ${({ theme }) => theme.colors.primary.white};
        }
      }
    }
  }
`;

const SubMenuContainer = styled(motion.ul)`
  position: absolute;
  left: 50%;
  top: 100%;
  width: 195px;
  list-style: none;
  margin: 0;
  padding: 14px;
  background-color: ${({ theme }) => theme.colors.primary.comicReliefDeepViolet};
  z-index: -1;
  transform: translateX(-50%);

  li + li {
    margin-top: 10px;
  }
`;

const SubMenuItemLink = styled(Link)`
  display: block;
  padding: 10px;
  font-family: ${({ theme }) => theme.typography.body.fontFamily};
  font-size: ${({ theme }) => theme.typography.body.fontSize};
  font-weight: 700;
  line-height: ${({ theme }) => theme.typography.body.lineHeight};
  color: ${({ theme }) => theme.colors.primary.white};
  border-radius: 6px;
  background-color: transparent;
  word-break: break-word;
  transition: background-color 0.25s ease-out;

  &:hover,
  &:focus,
  &.active {
    background-color: ${({ theme }) => theme.colors.secondary.grey.dark};
  }
`;

export default NavigationListItem;
