import React, { FC, useCallback } from 'react';
import styled from '@emotion/styled';
import {
  ContentfulButton,
  ContentfulMediaBlock,
  ContentfulQuote,
  ContentfulStatBlock,
  ContentfulTextBlock,
} from 'graphql-types';
import { Col, GridContainer, maxMediaQuery, Row } from '../grid';
import { ConditionalWrapper } from '../wrapper/ConditionalWrapper';
import { TwoColumnsColumnSize, TwoColumnsVerticalAlignment } from './props';

export type TwoColumnsVariant = '50% x 50%' | '70% x 30%' | '30% x 70%';

export type AvailablePageContentComponents =
  | ContentfulButton
  | ContentfulMediaBlock
  | ContentfulQuote
  | ContentfulStatBlock
  | ContentfulTextBlock
  | ContentfulStatBlock;

interface Props {
  id?: string;
  firstColumn: React.ReactNode[];
  secondColumn: React.ReactNode[];
  firstColumnWidth: TwoColumnsColumnSize;
  secondColumnWidth: TwoColumnsColumnSize;
  verticalAlignment?: TwoColumnsVerticalAlignment;
  withContainer?: boolean;
  withSpaceTop?: boolean;
  withSpaceBottom?: boolean;
}

const TwoColumns: FC<Props> = ({
  id,
  firstColumn,
  secondColumn,
  firstColumnWidth,
  secondColumnWidth,
  verticalAlignment = TwoColumnsVerticalAlignment.TOP,
  withContainer = true,
  withSpaceTop = true,
  withSpaceBottom = true,
}) => {
  const handleWithContainer = useCallback(
    (children: React.ReactNode) => <GridContainer id={id}>{children}</GridContainer>,
    [withContainer, id]
  );

  return (
    <ConditionalWrapper condition={withContainer === true} wrapperPositive={handleWithContainer}>
      <StyledRow
        id={withContainer === false ? id : undefined}
        verticalAlignment={verticalAlignment}
        withSpaceTop={withSpaceTop}
        withSpaceBottom={withSpaceBottom}
      >
        <FirstStyledCol md={firstColumnWidth === '70%' ? 8 : firstColumnWidth === '30%' ? 4 : 6}>
          {firstColumn}
        </FirstStyledCol>
        <SecondStyledCol md={secondColumnWidth === '70%' ? 8 : secondColumnWidth === '30%' ? 4 : 6}>
          {secondColumn}
        </SecondStyledCol>
      </StyledRow>
    </ConditionalWrapper>
  );
};

const FirstStyledCol = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;

  ${maxMediaQuery.md} {
    align-items: center;
  }
`;

const SecondStyledCol = styled(Col)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  ${maxMediaQuery.md} {
    align-items: center;
    margin-top: ${({ theme }) => theme.spacing.unit * 3}px;
  }
`;

const StyledRow = styled(Row)<{
  verticalAlignment: TwoColumnsVerticalAlignment;
  withSpaceTop: boolean;
  withSpaceBottom: boolean;
}>`
  display: flex;
  align-items: ${({ verticalAlignment }) =>
    verticalAlignment === TwoColumnsVerticalAlignment.CENTER
      ? 'center'
      : verticalAlignment === TwoColumnsVerticalAlignment.BOTTOM
      ? 'flex-end'
      : 'flex-start'};
  margin-top: ${({ theme, withSpaceTop }) => (withSpaceTop === true ? `${theme.spacing.unit * 10}px` : 0)};
  margin-bottom: ${({ theme, withSpaceBottom }) => (withSpaceBottom === true ? `${theme.spacing.unit * 10}px` : 0)};

  ${maxMediaQuery.sm} {
    margin-top: ${({ theme, withSpaceTop }) => (withSpaceTop === true ? `${theme.spacing.unit * 3}px` : 0)};
    margin-bottom: ${({ theme, withSpaceBottom }) => (withSpaceBottom === true ? `${theme.spacing.unit * 3}px` : 0)};
  }
`;

export default TwoColumns;
