import styled from '@emotion/styled';
import ButtonBase from './ButtonBase';

const ButtonSolidWhite = styled(ButtonBase)`
  color: ${(props) => props.theme.colors.primary.comicReliefRed};
  background-color: ${(props) => props.theme.colors.primary.white};
  border: 2px solid ${(props) => props.theme.colors.primary.white};

  &:hover {
    border-color: ${(props) => props.theme.colors.primary.white};
    color: ${(props) => props.theme.colors.primary.white};
    background-color: ${(props) => props.theme.colors.primary.comicReliefRed};

    svg {
      fill: ${(props) => props.theme.colors.primary.white};
    }
  }

  svg {
    fill: ${(props) => props.theme.colors.primary.comicReliefRed};
  }
`;

export default ButtonSolidWhite;
