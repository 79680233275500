import styled from '@emotion/styled';
import ButtonBase from './ButtonBase';

const ButtonOutlinedViolet = styled(ButtonBase)`
  color: ${(props) => props.theme.colors.primary.comicReliefDeepViolet};
  background-color: ${(props) => props.theme.colors.primary.transparent};
  border: 2px solid ${(props) => props.theme.colors.primary.comicReliefDeepViolet};

  &:hover {
    background-color: ${(props) => props.theme.colors.primary.comicReliefDeepViolet};
    color: ${(props) => props.theme.colors.primary.white};

    svg {
      fill: ${(props) => props.theme.colors.primary.white};
    }
  }

  svg {
    fill: ${(props) => props.theme.colors.primary.comicReliefDeepViolet};
  }
`;

export default ButtonOutlinedViolet;
