import React, { FC } from 'react';
import styled from '@emotion/styled';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { ContentfulRichTextGatsbyReference, RenderRichTextData } from 'gatsby-source-contentful/rich-text';
import { useTheme } from '@emotion/react';
import { Col, GridContainer, maxMediaQuery, Row } from '../grid';
import PageHeaderMainPartExtended from './PageHeaderMainPartExtended';

interface Props {
  title: string;
  location?: string;
  excerpt?: RenderRichTextData<ContentfulRichTextGatsbyReference>;
  projectTheme?: string;
  image?: IGatsbyImageData;
  imageAlt?: string;
  endDate?: string;
}

const ProjectHeader: FC<Props> = ({ title, location, excerpt, projectTheme, endDate, image, imageAlt }) => {
  const theme = useTheme();
  return (
    <Container>
      <GridContainer>
        <Row>
          <TextContainer md={12} lg={6}>
            <div>
              <PageHeaderMainPartExtended
                title={title}
                topText={location}
                bottomText={excerpt}
                tags={[projectTheme || '']}
                topTextColor={theme.colors.primary.copy}
                smallGap
              />
              {endDate != null && <h5>End date: {endDate}</h5>}
            </div>
          </TextContainer>
          <ImageContainer md={12} lg={6}>
            <div>{image && <ProjectImage loading="eager" image={image} alt={imageAlt ?? ''} />}</div>
          </ImageContainer>
        </Row>
      </GridContainer>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;
  padding: 40px 0;
  background-color: ${({ theme }) => theme.colors.secondary.teal.normal};

  ${maxMediaQuery.lg} {
    h1 {
      font-size: ${({ theme }) => theme.typography.h2.fontSize};
      line-height: ${({ theme }) => theme.typography.h2.lineHeight};
    }
  }

  ${Row} {
    display: flex;
    align-items: center;
  }

  ${maxMediaQuery.sm} {
    padding: 20px 0;
  }
`;

const TextContainer = styled(Col)`
  > div {
    padding-left: 40px;
  }

  ${maxMediaQuery.lg} {
    > div {
      padding-left: 0;
    }
  }
`;

const ImageContainer = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  > div {
    padding-right: 40px;
  }

  ${maxMediaQuery.lg} {
    > div {
      padding-right: 0;
    }
  }
`;

const ProjectImage = styled(GatsbyImage)`
  width: 520px;
  height: 495px;
  border-radius: 24px;
  mask-image: -webkit-radial-gradient(white, black);

  ${maxMediaQuery.lg} {
    height: 400px;
    width: 100%;
  }

  /* ${maxMediaQuery.md} {
    height: 00px;
  } */

  ${maxMediaQuery.sm} {
    height: 300px;
  }
`;

export default ProjectHeader;
