import styled from '@emotion/styled';
import React, { FC, useCallback } from 'react';
import { GridContainer, maxMediaQuery } from '../grid';
import { ConditionalWrapper } from '../wrapper/ConditionalWrapper';
import Accordion from './Accordion';
import { AccordionItem } from './AccordionItem';

type Props = {
  items: AccordionItem[];
  id?: string;
  withContainer?: boolean;
  withSpaceTop?: boolean;
  withSpaceBottom?: boolean;
};

const Accordions: FC<Props> = ({
  items,
  id,
  withContainer = true,
  withSpaceTop = true,
  withSpaceBottom = true,
  ...props
}) => {
  const handleWithContainer = useCallback((children: React.ReactNode) => <GridContainer>{children}</GridContainer>, [
    withContainer,
    id,
  ]);

  return (
    <Container {...props} id={id} withSpaceTop={withSpaceTop} withSpaceBottom={withSpaceBottom}>
      <ConditionalWrapper condition={withContainer === true} wrapperPositive={handleWithContainer}>
        <div>
          {items.map((item, index) => (
            <AccordionCotnainer key={index}>
              <Accordion item={item} id={id ? `${id}-${index}` : undefined} />
            </AccordionCotnainer>
          ))}
        </div>
      </ConditionalWrapper>
    </Container>
  );
};

const Container = styled.div<{ withSpaceTop: boolean; withSpaceBottom: boolean }>`
  position: relative;
  margin-top: ${({ theme, withSpaceTop }) => (withSpaceTop === true ? `${theme.spacing.unit * 5}px` : 0)};
  margin-bottom: ${({ theme, withSpaceBottom }) => (withSpaceBottom === true ? `${theme.spacing.unit * 5}px` : 0)};

  ${maxMediaQuery.sm} {
    margin-top: ${({ theme, withSpaceTop }) => (withSpaceTop === true ? `${theme.spacing.unit * 3}px` : 0)};
    margin-bottom: ${({ theme, withSpaceBottom }) => (withSpaceBottom === true ? `${theme.spacing.unit * 3}px` : 0)};
  }
`;

const AccordionCotnainer = styled.div`
  & + div {
    margin-top: ${({ theme }) => theme.spacing.unit * 5}px;

    ${maxMediaQuery.sm} {
      margin-top: ${({ theme }) => theme.spacing.unit * 3}px;
    }
  }
`;

export default Accordions;
