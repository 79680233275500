import { ComponentSpacerFragment } from 'graphql-types';
import { SpacerProps } from '../../components/spacer/props';

export function mapContentSpacerToProps(content: ComponentSpacerFragment): SpacerProps {
  return {
    spaceDesktop: content?.spaceDesktop ?? 0,
    spaceTablet: content?.spaceTablet ?? content?.spaceDesktop ?? 0,
    spaceMobile: content?.spaceMobile ?? content?.spaceDesktop ?? 0,
  };
}
