import React, { FC } from 'react';
import PageVariantDefault from './PageVariantDefault';
import PageVariantLanding from './PageVariantLanding';

export type PageVariants = 'default' | 'landing';

const variants: Record<PageVariants, React.FC> = {
  default: PageVariantDefault,
  landing: PageVariantLanding,
};

type Props = {
  variant?: PageVariants;
};

const PageVariant: FC<Props> = ({ variant = 'default', children }) => {
  const Component = variants[variant];

  return <Component>{children}</Component>;
};

export default PageVariant;
