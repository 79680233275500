import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import React, { FC } from 'react';
import { useViewportSlideIn } from '../../hooks/viewport-slide-in';
import CategoriesFilter from '../categories-filter/CategoriesFilter';
import Dropdown from '../dropdown/Dropdown';
import { Col, GridContainer, maxMediaQuery, Row } from '../grid';
import { PageListFilterItem, PageListFilterValue } from './props';
import './query';

type Props = {
  filters: PageListFilterItem[];
  values: PageListFilterValue[];
  multipleFiltering: boolean;
  onChange(filterValue: PageListFilterValue): void;
};

const PagesListFilter: FC<Props> = ({ filters, values, multipleFiltering, onChange }) => {
  const [containerRef, containerControls] = useViewportSlideIn();

  return (
    <Container ref={containerRef} animate={containerControls}>
      <GridContainer>
        <Row>
          <Col>
            <FiltersContainer multipleFiltering={multipleFiltering}>
              {filters.map((filter, index) => {
                const filterValueElement = values.find((value) => value.key === filter.key);
                const multipleFilterValueElement = values
                  .filter(({ value, key }) => key === filter.key && value != null)
                  .map(({ value }) => value) as string[];

                return filter.options.length > 0 ? (
                  <FilterItem key={filter.key} style={{ zIndex: 10 - index }}>
                    {multipleFiltering === true ? (
                      <CategoriesFilter
                        items={filter.options}
                        label={filter.label}
                        value={multipleFilterValueElement.length > 0 ? multipleFilterValueElement : undefined}
                        onItemSelect={(value: string) => onChange({ key: filter.key, value })}
                      />
                    ) : (
                      <Dropdown
                        items={filter.options}
                        label={filter.label}
                        placeholder="Select item"
                        value={filterValueElement?.value}
                        withClear
                        onItemSelect={(value: string) => onChange({ key: filter.key, value })}
                      />
                    )}
                  </FilterItem>
                ) : null;
              })}
            </FiltersContainer>
          </Col>
        </Row>
      </GridContainer>
    </Container>
  );
};

const Container = styled(motion.section)`
  position: relative;
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing.unit * 5}px;
  z-index: 10;
`;

const FiltersContainer = styled.div<{ multipleFiltering: boolean }>`
  display: flex;
  align-items: center;
  justify-content: ${({ multipleFiltering }) => (multipleFiltering === true ? 'flex-start' : 'center')};
  width: 100%;

  ${maxMediaQuery.sm} {
    flex-direction: column;
    align-items: ${({ multipleFiltering }) => (multipleFiltering === true ? 'stretch' : 'center')};
  }
`;

const FilterItem = styled.div`
  + div {
    margin-left: ${({ theme }) => theme.grid.gutter}px;
  }

  ${maxMediaQuery.sm} {
    + div {
      margin-left: 0;
      margin-top: ${({ theme }) => theme.grid.gutter}px;
    }
  }
`;

export default PagesListFilter;
