import styled from '@emotion/styled';
import { transparentize } from 'polished';
import { maxMediaQuery } from '../grid';
import OverlayBase from './OverlayBase';
import OverlayBox from './OverlayBox';
import { OverlayAlignmentVariant, OverlayProps } from './props';

const OverlayDeepViolet = styled(OverlayBase)<OverlayProps>`
  ${OverlayBox} {
    color: ${({ theme }) => theme.colors.primary.white};
    background-color: ${({ theme, alignment }) =>
      transparentize(
        alignment === OverlayAlignmentVariant.CENTER ? 0.4 : 0.2,
        theme.colors.primary.comicReliefDeepViolet
      )};

    ${maxMediaQuery.sm} {
      background: ${({ theme }) => theme.colors.primary.comicReliefDeepViolet};
    }
  }
`;

export default OverlayDeepViolet;
