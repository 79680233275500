export enum CookieCategories {
  functional = 'Functional',
  marketing = 'Marketing',
  essential = 'Essential',
  other = 'Other data processing',
}

export enum CookieRelatedServices {
  GTM = 'Google Tag Manager',
  GA = 'Google Analytics',
  CCM = 'Cookie Consent Manager',
  Other = 'Other service',
}

export enum ModalPages {
  main = 'main',
  settings = 'settings',
}
