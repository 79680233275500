import React, { FC } from 'react';

import { HeroProps, HeroVariants } from './props';
import HeroFullscreen from './HeroFullscreen';
import HeroClipToContent from './HeroClipToContent';
import { componentQuery } from './query';
import { OverlayAlignmentVariant, OverlayVariant } from '../overlay/props';
import Overlay from '../overlay/Overlay';

type Props = {
  variant?: HeroVariants;
} & HeroProps;

const variants: Record<HeroVariants, React.FC<HeroProps>> = {
  [HeroVariants.FULL]: HeroFullscreen,
  [HeroVariants.FIT_TO_CONTENT]: HeroClipToContent,
};

const Hero: FC<Props> = ({ variant = HeroVariants.FULL, ...componentProps }) => {
  const Component = variants[variant];
  const {
    alignment = OverlayAlignmentVariant.LEFT,
    overlayVariant = OverlayVariant.RED,
    headingPrefix,
    heading,
    excerpt,
    buttonText,
    button,
    background,
    fadeInEnabled = true,
  } = componentProps;

  return (
    <Component {...componentProps} fadeInEnabled={fadeInEnabled}>
      <Overlay
        variant={overlayVariant}
        headingPrefix={headingPrefix}
        heading={heading}
        excerpt={excerpt}
        alignment={alignment}
        button={button}
        buttonText={buttonText}
        background={background}
        context="hero"
      />
    </Component>
  );
};

export const query = componentQuery;

export default Hero;
