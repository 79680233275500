import styled from '@emotion/styled';
import React, { FC, useCallback } from 'react';
import Button from '../button/Button';
import { Col, GridContainer, maxMediaQuery, Row } from '../grid';
import PagesListBody, { LIST_LIMIT } from '../pages-list/PagesListBody';
import { ConditionalWrapper } from '../wrapper/ConditionalWrapper';
import { PagesListChosenProps } from './props';
import './query';

const PagesListChosen: FC<PagesListChosenProps> = ({
  id,
  title,
  pages,
  cta,
  ctaLabel,
  withContainer = true,
  showFiltering = false,
  typeFilters = [],
}) => {
  const handleWithContainer = useCallback((children: React.ReactNode) => <GridContainer>{children}</GridContainer>, [
    withContainer,
    id,
  ]);

  return (
    <Container id={id}>
      <ConditionalWrapper condition={withContainer === true} wrapperPositive={handleWithContainer}>
        {((title != null && title.trim() !== '') || (cta != null && ctaLabel != null)) && (
          <Row>
            <Col>
              <TopContainer>
                <HeadingContainer>{title != null && <Heading>{title}</Heading>}</HeadingContainer>
                <ActionContainer>
                  {cta != null && ctaLabel != null && <Button {...cta}>{ctaLabel}</Button>}
                </ActionContainer>
              </TopContainer>
            </Col>
          </Row>
        )}
        <Row>
          <ListColumn>
            <PagesListBody
              items={pages}
              filters={typeFilters}
              showFiltering={showFiltering}
              showPagination={pages.length > LIST_LIMIT}
              filterByType
            />
          </ListColumn>
        </Row>
      </ConditionalWrapper>
    </Container>
  );
};

const Container = styled.section`
  position: relative;
  margin-top: ${({ theme }) => theme.spacing.unit * 5}px;
  margin-bottom: ${({ theme }) => theme.spacing.unit * 10}px;

  ${maxMediaQuery.md} {
    margin-bottom: ${({ theme }) => theme.spacing.unit * 5}px;
  }

  ${maxMediaQuery.sm} {
    margin-top: ${({ theme }) => theme.spacing.unit * 3}px;
    margin-bottom: ${({ theme }) => theme.spacing.unit * 3}px;
  }
`;

const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing.unit * 5}px;

  ${maxMediaQuery.xs} {
    flex-direction: column;
  }
`;

const HeadingContainer = styled.div`
  display: flex;
  flex-grow: 1;
`;

const Heading = styled.h3`
  margin: 0;
`;

const ActionContainer = styled.div`
  display: flex;
  flex-shrink: 0;

  ${maxMediaQuery.xs} {
    width: 100%;
    margin-top: ${({ theme }) => theme.spacing.unit * 3}px;

    button,
    a {
      width: 100%;
    }
  }
`;

const ListColumn = styled(Col)`
  margin-top: ${({ theme }) => theme.spacing.unit * 5}px;
`;

export default PagesListChosen;
