import React from 'react';
import { BLOCKS } from '@contentful/rich-text-types';
import {
  renderRichText,
  ContentfulRichTextGatsbyReference,
  RenderRichTextData,
} from 'gatsby-source-contentful/rich-text';
import { Col, Row } from '../../components/grid';
import { reusableRichTextOptions, RichTextOptions } from './reusable';

const textOptions = reusableRichTextOptions(true);

const options: RichTextOptions = {
  ...textOptions,
  renderNode: {
    ...textOptions?.renderNode,
    [BLOCKS.PARAGRAPH]: (_node: React.ReactNode, children: React.ReactNode) => (
      <Row>
        <Col lg={{ span: 10, offset: 1 }}>
          <p>{children}</p>
        </Col>
      </Row>
    ),
    [BLOCKS.HEADING_1]: (_node: React.ReactNode, children: React.ReactNode) => (
      <Row>
        <Col lg={{ span: 10, offset: 1 }}>
          <h1>{children}</h1>
        </Col>
      </Row>
    ),
    [BLOCKS.HEADING_2]: (_node: React.ReactNode, children: React.ReactNode) => (
      <Row>
        <Col lg={{ span: 10, offset: 1 }}>
          <h2>{children}</h2>
        </Col>
      </Row>
    ),
    [BLOCKS.HEADING_3]: (_node: React.ReactNode, children: React.ReactNode) => (
      <Row>
        <Col lg={{ span: 10, offset: 1 }}>
          <h3>{children}</h3>
        </Col>
      </Row>
    ),
    [BLOCKS.HEADING_4]: (_node: React.ReactNode, children: React.ReactNode) => (
      <Row>
        <Col lg={{ span: 10, offset: 1 }}>
          <h4>{children}</h4>
        </Col>
      </Row>
    ),
    [BLOCKS.HEADING_5]: (_node: React.ReactNode, children: React.ReactNode) => (
      <Row>
        <Col lg={{ span: 10, offset: 1 }}>
          <h5>{children}</h5>
        </Col>
      </Row>
    ),
    [BLOCKS.HEADING_6]: (_node: React.ReactNode, children: React.ReactNode) => (
      <Row>
        <Col lg={{ span: 10, offset: 1 }}>
          <h6>{children}</h6>
        </Col>
      </Row>
    ),
    [BLOCKS.UL_LIST]: (_node: React.ReactNode, children: React.ReactNode) => (
      <Row>
        <Col lg={{ span: 10, offset: 1 }}>
          <ul>{children}</ul>
        </Col>
      </Row>
    ),
    [BLOCKS.OL_LIST]: (_node: React.ReactNode, children: React.ReactNode) => (
      <Row>
        <Col lg={{ span: 10, offset: 1 }}>
          <ol>{children}</ol>
        </Col>
      </Row>
    ),
  },
};

export function mapArticleRichText(richText: RenderRichTextData<ContentfulRichTextGatsbyReference>): React.ReactNode {
  if (richText?.raw == null) {
    return '';
  }

  return renderRichText(richText, options);
}
