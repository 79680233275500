import { ComponentButtonFragment } from 'graphql-types';
import { ButtonProps, ButtonVariants } from '../../components/button/buttonProps';
import { IconTypes } from '../../components/icon/iconTypes';
import { AvailableEntryType, getApplicationTargetEntry } from './url';

export function mapContentButtonToProps(buttonContent: ComponentButtonFragment): ButtonProps {
  let href: string | undefined;
  let isDownload = false;

  if (buttonContent?.downloadFile?.file?.url != null) {
    href = buttonContent?.downloadFile?.file?.url;
    isDownload = true;
  } else if (buttonContent?.targetUrl != null) {
    href = buttonContent?.targetUrl;
  } else if (buttonContent?.targetEntry?.slug != null) {
    href = getApplicationTargetEntry(
      buttonContent?.targetEntry?.slug,
      buttonContent?.targetEntry?.internal?.type as AvailableEntryType
    );
  } else if (buttonContent?.targetId != null) {
    href = buttonContent?.targetId;
  }

  return {
    icon:
      !buttonContent || !buttonContent.iconRight || buttonContent.iconRight === 'None'
        ? undefined
        : (buttonContent.iconRight as IconTypes),
    id: buttonContent?.contentfulid ?? undefined,
    variant: (buttonContent?.variant as ButtonVariants) ?? undefined,
    href,
    download: isDownload,
  };
}
