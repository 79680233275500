import React, { FC, useMemo } from 'react';
import { graphql } from 'gatsby';
import { useLocation } from '@reach/router';
import { ComponentSeoDataFragment, ContentfulPublicationConnection } from 'graphql-types';
import styled from '@emotion/styled';
import { ContentfulRichTextGatsbyReference, RenderRichTextData } from 'gatsby-source-contentful/rich-text';
import Seo from '../components/seo/Seo';
import { Col, GridContainer, maxMediaQuery, Row } from '../components/grid';
import Share from '../components/share/Share';
import TextHeader from '../components/page-header/TextHeader';
import { mapArticleRichText } from '../utils/rich-text/article';
import { AvailableContentCardComponents } from '../utils/content/card';
import { mapContentPagesListChosenToPagesProps } from '../utils/content/pages-list-chosen';
import { SeoProps } from '../components/seo/props';
import { mapContentSeoDataToProps } from '../utils/content/seo';
import { RelatedCardsTypes } from '../components/related-cards/props';
import RelatedCards from '../components/related-cards/RelatedCards';

const PublicationTemplate: FC<{ data: { publications: ContentfulPublicationConnection } }> = ({ data }) => {
  const publicationData = data.publications.edges?.[0]?.node;

  const location = useLocation();

  const content = useMemo(() => {
    return (
      publicationData?.content != null &&
      mapArticleRichText((publicationData.content as unknown) as RenderRichTextData<ContentfulRichTextGatsbyReference>)
    );
  }, [publicationData]);
  const relatedContent = useMemo<RelatedCardsTypes>(() => {
    const relatedProjects = (publicationData?.relatedProjects as AvailableContentCardComponents[]) ?? undefined;
    const relatedStories = (publicationData?.relatedStories as AvailableContentCardComponents[]) ?? undefined;
    const relatedPublications = (publicationData?.relatedPublications as AvailableContentCardComponents[]) ?? undefined;
    const relatedArticles = (publicationData?.relatedArticles as AvailableContentCardComponents[]) ?? undefined;
    const relatedPress = (publicationData?.relatedPressReleases as AvailableContentCardComponents[]) ?? undefined;

    return {
      projects: mapContentPagesListChosenToPagesProps(relatedProjects),
      stories: mapContentPagesListChosenToPagesProps(relatedStories),
      publications: mapContentPagesListChosenToPagesProps(relatedPublications),
      articles: mapContentPagesListChosenToPagesProps(relatedArticles),
      press: mapContentPagesListChosenToPagesProps(relatedPress),
    };
  }, [publicationData]);
  const seoData = useMemo<SeoProps>(() => {
    return mapContentSeoDataToProps(
      publicationData?.seoData ?? ({} as ComponentSeoDataFragment),
      publicationData?.title ?? ''
    );
  }, [publicationData]);

  return (
    <>
      <Seo {...seoData} />
      <TextHeader
        title={publicationData?.title || ''}
        topText={publicationData?.type?.name ?? undefined}
        bottomText={
          ((publicationData?.excerpt as unknown) as RenderRichTextData<ContentfulRichTextGatsbyReference>) ?? undefined
        }
        tags={[publicationData?.type?.name ?? '']}
        image={publicationData?.featuredImage?.gatsbyImageData}
        imageAlt={publicationData?.featuredImage?.description ?? undefined}
      />

      <GridContainer>
        <Row>
          <Col>
            <Content>{content}</Content>
          </Col>
        </Row>
        <Row>
          <StyledCol>
            <Share
              title={publicationData?.title ?? ''}
              url={location.href}
              image={publicationData?.featuredImage?.file?.url ?? undefined}
            />
          </StyledCol>
        </Row>
      </GridContainer>
      <RelatedCards {...relatedContent} context="publications" />
    </>
  );
};

export const query = graphql`
  query Publications($id: String!) {
    publications: allContentfulPublication(filter: { contentful_id: { eq: $id } }) {
      edges {
        node {
          createdAt
          title
          id
          seoData {
            ...ComponentSeoData
          }
          date
          displayDate
          content {
            raw
            references {
              ... on Node {
                ...ComponentAccordions
                ...ComponentButton
                ...ComponentCardFeatured
                ...ComponentMediaBlock
                ...ComponentMediaBlockSlider
                ...ComponentPagesListChosen
                ...ComponentPromoContent
                ...ComponentQuote
                ...ComponentStatBlock
                ...ComponentSpacer
                ...ComponentTextBlock
                ...ComponentTextAndGraphic
                ...ComponentTwoColumns
                ...EmbeddedAsset
                ...EmbeddedEntryLink
              }
            }
          }
          excerpt {
            raw
          }
          type {
            name
          }
          featuredImage {
            gatsbyImageData
            description
            file {
              url
            }
          }
          contentful_id
          relatedProjects {
            ... on Node {
              ...ComponentCardProject
            }
          }
          relatedPublications {
            ... on Node {
              ...ComponentCardPublication
            }
          }
          relatedStories {
            ... on Node {
              ...ComponentCardStory
            }
          }
          relatedArticles {
            ... on Node {
              ...ComponentCardArticle
            }
          }
          relatedPressReleases {
            ... on Node {
              ...ComponentCardPress
            }
          }
        }
      }
    }
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledCol = styled(Col)`
  width: 100%;
  padding: ${({ theme }) => theme.spacing.unit * 5}px 0px;
  display: flex;
  justify-content: center;

  ${maxMediaQuery.md} {
    padding: ${({ theme }) => theme.spacing.unit * 3}px 0px;
  }

  ${maxMediaQuery.sm} {
    padding: ${({ theme }) => theme.spacing.unit * 3}px 0px;
  }
`;

export default PublicationTemplate;
