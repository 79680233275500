import { parse, isValid } from 'date-fns';

export function parseStringToDate(dateString: string, format = "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"): Date | undefined {
  const parsedDate = parse(dateString, format, new Date());

  return isValid(parsedDate) ? parsedDate : undefined;
}

export function parseApiTimezoneDateStringToDate(dateString: string): Date | undefined {
  return parseStringToDate(dateString, "yyyy-MM-dd'T'HH:mmxxx");
}
