import styled from '@emotion/styled';
import React, { FC } from 'react';
import { maxMediaQuery } from '../grid';
import { SpacerProps } from './props';

const Spacer: FC<SpacerProps> = (props) => <Body {...props} />;

const Body = styled.div<{ spaceDesktop: number; spaceTablet: number; spaceMobile: number }>`
  display: block;
  width: 100%;
  height: 1px;
  margin-bottom: ${({ spaceDesktop }) => spaceDesktop}px;

  ${maxMediaQuery.md} {
    margin-bottom: ${({ spaceTablet }) => spaceTablet}px;
  }

  ${maxMediaQuery.sm} {
    margin-bottom: ${({ spaceMobile }) => spaceMobile}px;
  }
`;

export default Spacer;
