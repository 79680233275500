import styled from '@emotion/styled';
import { AnimationProps, motion } from 'framer-motion';
import React, { FC } from 'react';
import Icon from '../icon/Icon';

type Props = {
  open: boolean;
  onToggle(): void;
};

const arrowAnimationVariants: AnimationProps['variants'] = {
  closed: {
    rotate: 0,
  },
  opened: {
    rotate: -180,
  },
};

const arrowAnimationTransition: AnimationProps['transition'] = {
  rotate: { type: 'spring', stiffness: 300, damping: 30, duration: 0.25 },
};

const NavigationMobileCountryToggle: FC<Props> = ({ open, onToggle }) => {
  return (
    <Toggle
      className="btn-restart"
      whileTap={{ scale: 1.05 }}
      title={open === true ? 'Close country navigation' : 'Open country navigation'}
      onClick={onToggle}
    >
      <Icon iconType="globe" />
      <ArrowContainer
        animate={open === true ? 'opened' : 'closed'}
        variants={arrowAnimationVariants}
        transition={arrowAnimationTransition}
        initial="hidden"
      >
        <Icon iconType="chevron" />
      </ArrowContainer>
    </Toggle>
  );
};

const Toggle = styled(motion.button)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;

  > svg {
    display: block;
  }
`;

const ArrowContainer = styled(motion.span)`
  display: flex;
  align-items: center;
  margin-left: 10px;

  svg {
    display: block;
  }
`;

export default NavigationMobileCountryToggle;
