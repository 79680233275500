import React from 'react';
import { ReactComponent as Arrow } from '../../assets/icons/arrow.svg';
import { ReactComponent as ArrowLongLeft } from '../../assets/icons/arrow-long-left.svg';
import { ReactComponent as ArrowLongRight } from '../../assets/icons/arrow-long-right.svg';
import { ReactComponent as ArrowLongBottom } from '../../assets/icons/arrow-long-bottom.svg';
import { ReactComponent as AccordionExpander } from '../../assets/icons/accordion-expander.svg';
import { ReactComponent as Plus } from '../../assets/social-icons/plus.svg';
import { ReactComponent as Chevron } from '../../assets/icons/chevron.svg';
import { ReactComponent as Globe } from '../../assets/icons/globe.svg';
import { ReactComponent as ZoomIn } from '../../assets/icons/map-zoom-in.svg';
import { ReactComponent as ZoomOut } from '../../assets/icons/map-zoom-out.svg';
import { ReactComponent as VideoPlay } from '../../assets/icons/video-play.svg';
import { ReactComponent as TickMark } from '../../assets/icons/tick.svg';
import { ReactComponent as XMark } from '../../assets/icons/x-mark.svg';

export type IconTypes =
  | 'arrow'
  | 'arrow-long-left'
  | 'arrow-long-right'
  | 'arrow-long-bottom'
  | 'accordion-expander'
  | 'plus'
  | 'zoom-in'
  | 'zoom-out'
  | 'chevron'
  | 'globe'
  | 'video-play'
  | 'tick-mark'
  | 'x-mark';

interface IconObject {
  icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  defaultWidth?: string;
  defaultHeight?: string;
  defaultFill?: string;
}

const iconTypes: Record<IconTypes, IconObject> = {
  arrow: {
    icon: Arrow,
    defaultWidth: '13px',
    defaultHeight: '8px',
  },
  'arrow-long-left': {
    icon: ArrowLongLeft,
    defaultWidth: '25px',
    defaultHeight: '16px',
  },
  'arrow-long-right': {
    icon: ArrowLongRight,
    defaultWidth: '25px',
    defaultHeight: '16px',
  },
  'arrow-long-bottom': {
    icon: ArrowLongBottom,
    defaultWidth: '13px',
    defaultHeight: '20px',
  },
  'accordion-expander': {
    icon: AccordionExpander,
    defaultWidth: '32px',
    defaultHeight: '32px',
  },
  plus: {
    icon: Plus,
    defaultWidth: '32px',
    defaultHeight: '32px',
  },
  'zoom-in': {
    icon: ZoomIn,
    defaultWidth: '32px',
    defaultHeight: '32px',
  },
  'zoom-out': {
    icon: ZoomOut,
    defaultWidth: '32px',
    defaultHeight: '32px',
  },
  chevron: {
    icon: Chevron,
    defaultWidth: '12px',
    defaultHeight: '6px',
  },
  globe: {
    icon: Globe,
    defaultWidth: '24px',
    defaultHeight: '24px',
  },
  'video-play': {
    icon: VideoPlay,
    defaultWidth: '80px',
    defaultHeight: '80px',
  },
  'tick-mark': {
    icon: TickMark,
    defaultWidth: '12px',
    defaultHeight: '10px',
  },
  'x-mark': {
    icon: XMark,
    defaultWidth: '10px',
    defaultHeight: '10px',
  },
};

export default iconTypes;
