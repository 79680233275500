import { StyledComponent } from '@emotion/styled';
import { Theme } from '../../models/theme';
import { IconTypes } from '../icon/iconTypes';

export enum ButtonVariants {
  Solid = 'Solid',
  SolidWhite = 'solidWhite',
  OutlinedViolet = 'outlinedViolet',
  OutlinedVioletAlt = 'outlinedVioletAlt',
  Outlined = 'Transparent',
  OutlinedWhite = 'Transparent White',
}

export type ButtonProps = {
  variant?: ButtonVariants;
  icon?: IconTypes;
  id?: string;
  href?: string;
  download?: boolean;
  active?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export type ButtonComponent = StyledComponent<
  {
    theme?: Theme | undefined;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    as?: React.ElementType<any> | undefined;
  },
  React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
  Record<string, unknown>
>;
