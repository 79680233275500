export enum OverlayVariant {
  NONE = 'None',
  RED = 'Comic Relief Red',
  DEEP_VIOLET = 'Comic Relief Deep Violet',
  WHITE = 'White',
  BLACK_GRADIENT = 'Black gradient',
  GRADIENT = 'Gradient',
}

export enum OverlayAlignmentVariant {
  LEFT = 'Left',
  CENTER = 'Center',
  RIGHT = 'Right',
}

export enum OverlayBackgroundVariant {
  GREY = 'Comic Relief Grey',
  CORAL = 'Comic Relief Coral',
  ORANGE = 'Comic Relief Orange',
  YELLOW = 'Comic Relief Yellow',
  GREEN = 'Comic Relief Green',
  BLUE = 'Comic Relief Blue',
  MAGENTA = 'Comic Relief Magenta',
  PURPLE = 'Comic Relief Purple',
  TEAL = 'Comic Relief Teal',
}

export type OverlayProps = {
  alignment?: OverlayAlignmentVariant;
  background?: OverlayBackgroundVariant;
};
