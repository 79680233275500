import React, { FC } from 'react';
import styled from '@emotion/styled';
import { GatsbyImage } from 'gatsby-plugin-image';
import { motion } from 'framer-motion';
import { HeroProps } from './props';
import { OverlayBackgroundVariant, OverlayVariant } from '../overlay/props';
import { useViewportFadeIn } from '../../hooks/viewport-fade-in';
import { maxMediaQuery } from '../grid';
import { useBreakpoint } from '../../hooks/breakpoint';
import { DESKTOP_NAVIGATION_HEIGHT, MOBILE_NAVIGATION_HEIGHT } from '../../constants/navigation';
import { useStateContext } from '../../hooks/state-context';

const HeroFullscreen: FC<HeroProps> = ({
  id,
  image,
  imageAlt,
  background,
  children,
  fadeInEnabled,
  overlayVariant,
}) => {
  const { windowLoaded } = useStateContext();
  const [containerRef, containerControls] = useViewportFadeIn(fadeInEnabled, false, windowLoaded !== true);
  const { md: breakpointMd } = useBreakpoint();

  return (
    <Container
      id={id}
      ref={containerRef}
      animate={containerControls}
      background={breakpointMd === true || overlayVariant === OverlayVariant.NONE ? background : undefined}
    >
      {image != null && (
        <BackgroundImage
          loading="eager"
          image={image}
          alt={imageAlt != null && imageAlt !== '' ? imageAlt : 'Hero background'}
        />
      )}
      {children}
    </Container>
  );
};

const Container = styled(motion.div)<{ background?: OverlayBackgroundVariant }>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: calc(90vh - ${DESKTOP_NAVIGATION_HEIGHT}px);
  background-color: ${({ theme, background }) =>
    background != null ? theme.overlayBackground[background] : 'transparent'};

  > div {
    height: 100%;
  }

  ${maxMediaQuery.sm} {
    min-height: 0;
  }
`;

const BackgroundImage = styled(GatsbyImage)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 5;

  ${maxMediaQuery.sm} {
    position: relative;
    min-height: 300px;
    height: calc(50vh - ${MOBILE_NAVIGATION_HEIGHT}px);
    width: 100%;
  }
`;

export default HeroFullscreen;
