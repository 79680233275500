import { IGatsbyImageData } from 'gatsby-plugin-image';
import { ContentfulRichTextGatsbyReference, RenderRichTextData } from 'gatsby-source-contentful/rich-text';
import { ButtonProps } from '../button/buttonProps';
import { OverlayAlignmentVariant, OverlayVariant } from '../overlay/props';

export enum MediaBlockVariant {
  FULL_WIDTH = 'Full width',
  CONTAINER_WIDTH = 'Container width',
}

export type MediaBlockProps = {
  id?: string;
  overlayVariant?: OverlayVariant;
  heading?: RenderRichTextData<ContentfulRichTextGatsbyReference>;
  fluidImage?: IGatsbyImageData;
  constrainedImage?: IGatsbyImageData;
  imageAlt?: string;
  excerpt?: RenderRichTextData<ContentfulRichTextGatsbyReference> | null | undefined;
  alignment?: OverlayAlignmentVariant;
  button?: ButtonProps;
  buttonText?: string;
  withContainer?: boolean;
  withSpaceTop?: boolean;
  withSpaceBottom?: boolean;
  withTransition?: boolean;
  inSlider?: boolean;
  videoUrl?: string;
  videoOpen?: boolean;
};
