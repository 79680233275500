import { IGatsbyImageData } from 'gatsby-plugin-image';
import { ContentfulRichTextGatsbyReference, RenderRichTextData } from 'gatsby-source-contentful/rich-text';
import {
  ComponentCardArticleFragment,
  ComponentCardPressFragment,
  ComponentCardStoryFragment,
  ComponentCardProjectFragment,
  ComponentCardPublicationFragment,
} from 'graphql-types';
import { CardProps, CardVariant } from '../../components/card/props';
import { getApplicationTargetEntry } from './url';

export type AvailableContentCardComponents =
  | ComponentCardPressFragment
  | ComponentCardArticleFragment
  | ComponentCardStoryFragment
  | ComponentCardProjectFragment
  | ComponentCardPublicationFragment;

export function mapContentCardArticleToProps(content: ComponentCardArticleFragment): CardProps {
  const excerpt = content.excerpt as RenderRichTextData<ContentfulRichTextGatsbyReference>;

  return {
    key: content?.id,
    elementDate: content?.displayDate === true && content?.date != null ? content.date : undefined,
    bottomTypesIds: content?.tag?.id != null ? [content?.tag?.id] : undefined,
    bottomTypes: content?.tag?.name != null ? [content?.tag?.name] : undefined,
    title: content?.title ?? '',
    image: (content?.featuredImage?.gatsbyImageData as IGatsbyImageData) ?? undefined,
    imageAlt: content?.featuredImage?.description ?? undefined,
    excerpt,
    path: content?.slug != null ? getApplicationTargetEntry(content.slug, 'ContentfulArticle') : undefined,
  };
}

export function mapContentCardPressToProps(content: ComponentCardPressFragment): CardProps {
  const excerpt = content.excerpt as RenderRichTextData<ContentfulRichTextGatsbyReference>;

  return {
    key: content?.id,
    elementDate: content?.updatedAt ?? undefined,
    bottomTypesIds: content?.tag?.id != null ? [content?.tag?.id] : undefined,
    bottomTypes: content?.tag?.name != null ? [content?.tag?.name] : undefined,
    title: content?.title ?? '',
    image: (content?.featuredImage?.gatsbyImageData as IGatsbyImageData) ?? undefined,
    imageAlt: content?.featuredImage?.description ?? undefined,
    excerpt,
    path: content?.slug != null ? getApplicationTargetEntry(content.slug, 'ContentfulPressRelease') : undefined,
  };
}

export function mapContentCardStoryToProps(content: ComponentCardStoryFragment): CardProps {
  return {
    key: content?.id,
    bottomTypesIds: content?.tag?.id != null ? [content?.tag?.id] : undefined,
    bottomTypes: content?.tag?.name != null ? [content?.tag?.name] : undefined,
    locationsIds: content?.location?.id != null ? [content?.location?.id] : undefined,
    locations: content?.location?.name != null ? [content?.location?.name] : undefined,
    title: content?.title ?? '',
    image: (content?.featuredImage?.gatsbyImageData as IGatsbyImageData) ?? undefined,
    imageAlt: content?.featuredImage?.description ?? undefined,
    path: content?.slug != null ? getApplicationTargetEntry(content.slug, 'ContentfulStory') : undefined,
  };
}

export function mapContentCardProjectToProps(content: ComponentCardProjectFragment): CardProps {
  const excerpt = content.excerpt as RenderRichTextData<ContentfulRichTextGatsbyReference>;
  const types = content?.types as { id: string; name: string }[];
  const filteredTypes = types != null ? types.filter((type) => type != null) : [];
  const bottomTypesIds = filteredTypes.map((type) => type.id);
  const bottomTypes = filteredTypes.map((type) => type.name);

  return {
    key: content?.id,
    bottomTypesIds,
    bottomTypes,
    locationsIds: content?.locationTag?.id != null ? [content?.locationTag?.id] : undefined,
    locations: content?.locationTag?.name != null ? [content?.locationTag?.name] : undefined,
    title: content?.title ?? '',
    image: (content?.featuredImage?.gatsbyImageData as IGatsbyImageData) ?? undefined,
    imageAlt: content?.featuredImage?.description ?? undefined,
    excerpt,
    path: content?.slug != null ? getApplicationTargetEntry(content.slug, 'ContentfulProject') : undefined,
  };
}

export function mapContentCardPublicationToProps(content: ComponentCardPublicationFragment): CardProps {
  const excerpt = content.excerpt as RenderRichTextData<ContentfulRichTextGatsbyReference>;

  return {
    key: content?.id,
    elementDate: content?.displayDate === true && content?.date != null ? content.date : undefined,
    topTypesIds: content?.type?.id != null ? [content?.type?.id] : undefined,
    topTypes: content?.type?.name != null ? [content?.type?.name] : undefined,
    title: content?.title ?? '',
    image: (content?.featuredImage?.gatsbyImageData as IGatsbyImageData) ?? undefined,
    imageAlt: content?.featuredImage?.description ?? undefined,
    excerpt,
    path: content?.slug != null ? getApplicationTargetEntry(content.slug, 'ContentfulPublication') : undefined,
  };
}

export function mapCardsContent(content: AvailableContentCardComponents): CardProps | undefined {
  if (content?.internal?.type == null) {
    return undefined;
  }

  switch (content.internal.type) {
    case 'ContentfulArticle': {
      return mapContentCardArticleToProps(content as ComponentCardArticleFragment);
    }

    case 'ContentfulPressRelease': {
      return mapContentCardPressToProps(content as ComponentCardPressFragment);
    }

    case 'ContentfulStory': {
      return mapContentCardStoryToProps(content as ComponentCardStoryFragment);
    }

    case 'ContentfulProject': {
      return mapContentCardProjectToProps(content as ComponentCardProjectFragment);
    }

    case 'ContentfulPublication': {
      return mapContentCardPublicationToProps(content as ComponentCardPublicationFragment);
    }

    default:
      return undefined;
  }
}

export function getCardVariantByInternalType(type: string): CardVariant {
  switch (type) {
    case 'ContentfulArticle':
      return CardVariant.ARTICLE;

    case 'ContentfulPressRelease':
      return CardVariant.PRESS;

    case 'ContentfulStory':
      return CardVariant.STORY;

    case 'ContentfulProject':
      return CardVariant.PROJECT;

    case 'ContentfulPublication':
      return CardVariant.PUBLICATION;

    default:
      return CardVariant.ARTICLE;
  }
}
