import { CardProps, CardVariant } from '../card/props';
import { DropdownItem } from '../dropdown/props';

export enum PagesListVariant {
  ARTICLE = 'Article',
  PRESS = 'Press release',
  PROJECT = 'Project',
  PUBLICATION = 'Publication',
  STORY = 'Story',
}

export type PagesListSharedProps = {
  showFiltering?: boolean;
};

export type PagesListProps = {
  id?: string;
  title?: string;
  variant: PagesListVariant;
  withContainer?: boolean;
} & PagesListSharedProps;

export type PagesListItem = {
  variant: CardVariant;
} & CardProps;

export type PageListFilterItem = {
  key: string;
  label: string;
  options: DropdownItem[];
};

export type PageListFilterValue = {
  key: string;
  value?: string;
};
