import React, { FC, useMemo } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { format } from 'date-fns';
import { CardProps } from './props';
import { parseApiTimezoneDateStringToDate } from '../../utils/date';
import { mapExcerptRichText } from '../../utils/rich-text/excerpt';

type Props = Pick<CardProps, 'elementDate' | 'locations' | 'topTypes' | 'bottomTypes' | 'title' | 'excerpt'> & {
  withBackdrop?: boolean;
  withShadow?: boolean;
  largeShadow?: boolean;
};

const CardBody: FC<Props> = ({
  elementDate,
  locations,
  topTypes,
  bottomTypes,
  title,
  excerpt,
  withBackdrop = true,
  withShadow = true,
  largeShadow = true,
}) => {
  const cardDate = useMemo<string | undefined>(() => {
    if (elementDate == null) {
      return undefined;
    }

    const parsedElementDate = parseApiTimezoneDateStringToDate(elementDate);

    return parsedElementDate != null ? format(parsedElementDate, 'dd MMMM yyyy') : undefined;
  }, [elementDate]);
  const parsedExcerpt = useMemo<React.ReactNode | undefined>(() => {
    if (excerpt == null) {
      return undefined;
    }

    return mapExcerptRichText(excerpt);
  }, [excerpt]);

  return (
    <Container withBackdrop={withBackdrop} withShadow={withShadow} largeShadow={largeShadow}>
      {cardDate != null && <TopHeading>{cardDate}</TopHeading>}
      {locations != null && locations?.length > 0 && (
        <TopHeading>
          {locations.map((location) => (
            <span key={location}>{location}</span>
          ))}
        </TopHeading>
      )}
      {topTypes != null && topTypes?.length > 0 && (
        <Type>
          {topTypes.map((topType) => (
            <span key={topType}>{topType}</span>
          ))}
        </Type>
      )}
      <Title>{title}</Title>
      {parsedExcerpt != null && <Description>{parsedExcerpt}</Description>}
      {bottomTypes != null && bottomTypes?.length > 0 && (
        <Type>
          {bottomTypes.map((bottomType) => (
            <span key={bottomType}>{bottomType}</span>
          ))}
        </Type>
      )}
    </Container>
  );
};

const listSeparator = css`
  span + span {
    &::before {
      content: ', ';
    }
  }
`;

const Container = styled.div<{ withBackdrop: boolean; withShadow: boolean; largeShadow: boolean }>`
  padding: 14px 30px 30px;
  background-color: ${({ theme, withBackdrop }) =>
    withBackdrop === true ? theme.colors.primary.white : 'transparent'};
  border-radius: ${({ withBackdrop }) => (withBackdrop === true ? '24px' : '0')};
  box-shadow: ${({ withBackdrop, withShadow, largeShadow }) =>
    withBackdrop === true || withShadow === true
      ? largeShadow === true
        ? '0px 4px 12px rgba(0, 0, 0, 0.2)'
        : '0px 0 12px rgba(0, 0, 0, 0.2)'
      : 'none'};
`;

const TopHeading = styled.h5`
  margin: 0;
  margin-top: ${({ theme }) => theme.spacing.unit * 2}px;
  color: ${({ theme }) => theme.colors.primary.comicReliefRed};

  ${listSeparator};
`;

const Type = styled.h5`
  margin: 0;
  margin-top: ${({ theme }) => theme.spacing.unit * 2}px;
  color: ${(props) => props.theme.colors.secondary.grey.dark};

  ${listSeparator};
`;

const Title = styled.h3`
  margin: 0;
  margin-top: ${({ theme }) => theme.spacing.unit * 2}px;
  color: ${({ theme }) => theme.colors.primary.copy};
  word-break: break-word;
`;

const Description = styled.div`
  margin-top: ${({ theme }) => theme.spacing.unit * 2}px;
  font-size: ${({ theme }) => theme.typography.body.fontSize};
  font-weight: 400;
  line-height: ${({ theme }) => theme.typography.body.lineHeight};
  word-break: break-word;
`;

export default CardBody;
