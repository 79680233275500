import { ContentfulTilesRow } from 'graphql-types';
import { TextAndGraphicProps } from '../../components/text/props';
import { TextAndGraphicItemProps } from '../../components/text/TextAndGraphicItem';

export function mapContentToTilesRowProps(content: ContentfulTilesRow): TextAndGraphicProps {
  const tile1 = content.tile1Image
    ? ({
        image: content.tile1Image.gatsbyImageData,
        imageDescription: content.tile1Image.description,
        heading: content.tile1Title,
        copy: content.tile1Copy,
      } as TextAndGraphicItemProps)
    : undefined;

  const tile2 = content.tile2Image
    ? ({
        image: content.tile2Image.gatsbyImageData,
        imageDescription: content.tile2Image.description,
        heading: content.tile2Title,
        copy: content.tile2Copy,
      } as TextAndGraphicItemProps)
    : undefined;

  const tile3 = content.tile3Image
    ? ({
        image: content.tile3Image.gatsbyImageData,
        imageDescription: content.tile3Image.description,
        heading: content.tile3Title,
        copy: content.tile3Copy,
      } as TextAndGraphicItemProps)
    : undefined;

  const items: TextAndGraphicItemProps[] = [];

  if (tile1 != null) {
    items.push(tile1);
  }

  if (tile2 != null) {
    items.push(tile2);
  }

  if (tile3 != null) {
    items.push(tile3);
  }

  return { id: content?.contentfulid ?? undefined, items };
}
