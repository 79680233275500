import React, { FC } from 'react';
import styled from '@emotion/styled';
import { ContentfulRichTextGatsbyReference, RenderRichTextData } from 'gatsby-source-contentful/rich-text';
import PageHeaderMainPart from './PageHeaderMainPart';
import { maxMediaQuery } from '../grid';
import { mapTextBlockRichText } from '../../utils/rich-text/text-block';

interface Props {
  title: string;
  topText?: string;
  topTextColor?: string;
  bottomText?: RenderRichTextData<ContentfulRichTextGatsbyReference>;
  tags?: string[];
  smallGap?: boolean;
}

const PageHeaderMainPartExtended: FC<Props> = ({
  title,
  topText,
  topTextColor,
  bottomText,
  tags,
  smallGap = false,
}) => {
  return (
    <Container smallGap={smallGap}>
      <PageHeaderMainPart title={title} topText={topText} topTextColor={topTextColor} />
      {bottomText && <BottomText>{mapTextBlockRichText(bottomText)}</BottomText>}
      <TagsContainer>
        {tags?.map((tag, index) => (
          <h5 key={index}>{tag}</h5>
        ))}
      </TagsContainer>
    </Container>
  );
};

const Container = styled.div<{ smallGap: boolean }>`
  max-width: ${({ smallGap }) => (smallGap === true ? 'calc(100% - 40px' : 'calc(100% - 220px')};

  ${maxMediaQuery.lg} {
    max-width: 100%;
  }
`;

const BottomText = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.unit * 3}px;
  white-space: pre-wrap;
  word-wrap: break-word;
`;

const TagsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  > h5 {
    & + h5 {
      margin: 0px ${(props) => props.theme.spacing.unit * 5}px;
    }

    &:last-of-type {
      margin: 0;
    }
  }

  ${maxMediaQuery.md} {
    flex-direction: column;
    > h5 + h5 {
      margin: 0px;
    }
  }
`;

export default PageHeaderMainPartExtended;
