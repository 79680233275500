import { graphql, useStaticQuery } from 'gatsby';
import React, { FC, useMemo } from 'react';
import { PagesListPublicationQuery } from 'graphql-types';
import { PageListFilterItem, PagesListItem, PagesListSharedProps } from './props';
import {
  mapContentPageListPublicationToListFilters,
  mapContentPageListPublicationToListItems,
} from '../../utils/content/pages-list';
import PagesListBody from './PagesListBody';

const PagesListPublication: FC<PagesListSharedProps> = ({ showFiltering }) => {
  const data = useStaticQuery<PagesListPublicationQuery>(graphql`
    query PagesListPublication {
      allContentfulPublication(
        sort: { order: DESC, fields: date }
        filter: { slug: { regex: "/^(?!dont\\-remove).+/" } }
      ) {
        nodes {
          ... on Node {
            ...ComponentCardPublication
          }
        }
      }
    }
  `);
  const listItems = useMemo<PagesListItem[]>(() => {
    const items = data?.allContentfulPublication?.nodes;

    return mapContentPageListPublicationToListItems(items);
  }, [data]);
  const listFilters = useMemo<PageListFilterItem[]>(() => {
    const items = data?.allContentfulPublication?.nodes;

    return mapContentPageListPublicationToListFilters(items);
  }, [data]);

  return <PagesListBody items={listItems} filters={listFilters} showFiltering={showFiltering ?? false} />;
};

export default PagesListPublication;
