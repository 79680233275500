import { OverlayBackgroundVariant } from './components/overlay/props';
import { Theme, ThemeColors } from './models/theme';

const colors: ThemeColors = {
  primary: {
    comicReliefRed: '#E52630',
    comicReliefDeepViolet: '#2C0230',
    copy: '#000',
    link: '#000',
    white: '#FFF',
    black: '#000',
    transparent: 'rgba(0,0,0,0)',
  },
  secondary: {
    grey: {
      normal: '#918791',
      light: '#F4F2F4',
      dark: '#4B324E',
    },
    coral: {
      normal: '#F9686D',
      light: '#FFCECE',
      dark: '#961D35',
    },
    orange: {
      normal: '#FF9144',
      light: '#FEE3CC',
      dark: '#984316',
    },
    yellow: {
      normal: '#FFE400',
      light: '#FEFD5A',
      dark: '#665700',
    },
    green: {
      normal: '#4ECD57',
      light: '#62EF74',
      dark: '#1B651D',
    },
    blue: {
      normal: '#6489FC',
      light: '#8BACFE',
      dark: '#274084',
    },
    magenta: {
      normal: '#FC59CE',
      light: '#FEB4DC',
      dark: '#821C5D',
    },
    purple: {
      normal: '#C180F9',
      light: '#DFC6F6',
      dark: '#48276E',
    },
    teal: {
      normal: '#22CFD8',
      light: '#86E4E9',
      dark: '#13767C',
    },
  },
};

const theme: Theme = {
  spacing: {
    unit: 8,
  },
  grid: {
    containerWidth: '1304px',
    gutter: 24,
    columns: 12,
    columnWidthPercent: 100 / 12,
  },
  colors,
  fonts: {
    main: 'Montserrat',
    headers: 'Anton',
  },
  typography: {
    h1: {
      fontFamily: 'Anton, sans-serif',
      fontSize: '80px',
      fontWeight: '400',
      lineHeight: '92px',
    },
    h2: {
      fontFamily: 'Anton, sans-serif',
      fontSize: '48px',
      fontWeight: '400',
      lineHeight: '58px',
    },
    h3: {
      fontFamily: 'Anton, sans-serif',
      fontSize: '32px',
      fontWeight: '400',
      lineHeight: '36px',
    },
    h4: {
      fontFamily: 'Anton, sans-serif',
      fontSize: '24px',
      fontWeight: '400',
      lineHeight: '30px',
    },
    h5: {
      fontFamily: 'Montserrat, sans-serif',
      fontSize: '16px',
      fontWeight: '700',
      lineHeight: '20px',
    },
    h6: {
      fontFamily: 'Montserrat, sans-serif',
      fontSize: '16px',
      fontWeight: '700',
      lineHeight: '20px',
    },
    titleBody: {
      fontFamily: 'Montserrat, sans-serif',
      fontSize: '20px',
      fontWeight: '400',
      lineHeight: '28px',
    },
    body: {
      fontFamily: 'Montserrat, sans-serif',
      fontSize: '16px',
      fontWeight: '400',
      lineHeight: '22px',
    },
    smallBody: {
      fontFamily: 'Montserrat, sans-serif',
      fontSize: '12px',
      fontWeight: '400',
      lineHeight: '18px',
    },
  },
  tabletTypography: {
    h1: {
      fontFamily: 'Anton, sans-serif',
      fontSize: '60px',
      fontWeight: '400',
      lineHeight: '70px',
    },
    h2: {
      fontFamily: 'Anton, sans-serif',
      fontSize: '40px',
      fontWeight: '400',
      lineHeight: '48px',
    },
    h3: {
      fontFamily: 'Anton, sans-serif',
      fontSize: '30px',
      fontWeight: '400',
      lineHeight: '36px',
    },
    h4: {
      fontFamily: 'Anton, sans-serif',
      fontSize: '24px',
      fontWeight: '400',
      lineHeight: '30px',
    },
    h5: {
      fontFamily: 'Montserrat, sans-serif',
      fontSize: '16px',
      fontWeight: '700',
      lineHeight: '20px',
    },
    h6: {
      fontFamily: 'Montserrat, sans-serif',
      fontSize: '16px',
      fontWeight: '700',
      lineHeight: '20px',
    },
  },
  mobileTypography: {
    h1: {
      fontFamily: 'Anton, sans-serif',
      fontSize: '42px',
      fontWeight: '400',
      lineHeight: '50px',
    },
    h2: {
      fontFamily: 'Anton, sans-serif',
      fontSize: '36px',
      fontWeight: '400',
      lineHeight: '42px',
    },
    h3: {
      fontFamily: 'Anton, sans-serif',
      fontSize: '30px',
      fontWeight: '400',
      lineHeight: '36px',
    },
    h4: {
      fontFamily: 'Anton, sans-serif',
      fontSize: '24px',
      fontWeight: '400',
      lineHeight: '30px',
    },
    h5: {
      fontFamily: 'Montserrat, sans-serif',
      fontSize: '16px',
      fontWeight: '700',
      lineHeight: '20px',
    },
    h6: {
      fontFamily: 'Montserrat, sans-serif',
      fontSize: '16px',
      fontWeight: '700',
      lineHeight: '20px',
    },
  },
  overlayBackground: {
    [OverlayBackgroundVariant.GREY]: colors.secondary.grey.normal,
    [OverlayBackgroundVariant.CORAL]: colors.secondary.coral.normal,
    [OverlayBackgroundVariant.ORANGE]: colors.secondary.orange.normal,
    [OverlayBackgroundVariant.YELLOW]: colors.secondary.yellow.normal,
    [OverlayBackgroundVariant.GREEN]: colors.secondary.green.normal,
    [OverlayBackgroundVariant.BLUE]: colors.secondary.blue.normal,
    [OverlayBackgroundVariant.MAGENTA]: colors.secondary.magenta.normal,
    [OverlayBackgroundVariant.PURPLE]: colors.secondary.purple.normal,
    [OverlayBackgroundVariant.TEAL]: colors.secondary.teal.normal,
  },
};

export default theme;
