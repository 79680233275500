import { ContentfulRichTextGatsbyReference, RenderRichTextData } from 'gatsby-source-contentful/rich-text';
import { ComponentPromoContentFragment } from 'graphql-types';
import { PromoContentProps } from '../../components/promo-content-block/PromoContentBlock';
import { mapTextBlockRichText } from '../rich-text/text-block';
import { mapContentButtonToProps } from './button';

export function mapContentPromoToProps(content: ComponentPromoContentFragment): PromoContentProps {
  return {
    heading: content.promoHeading ?? '',
    body:
      content?.body != null
        ? mapTextBlockRichText((content.body as unknown) as RenderRichTextData<ContentfulRichTextGatsbyReference>)
        : null,
    image: content.image?.gatsbyImageData,
    imageAlt: content.image?.description ?? undefined,
    imageAligment: (content.imageAlignment?.toLowerCase() as PromoContentProps['imageAligment']) ?? 'left',
    button: content?.button != null ? mapContentButtonToProps(content.button) : undefined,
    buttonLabel: content?.button?.label ?? '',
  };
}
