import React, { FC, useCallback, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { useCounter, useDebounce, useEffectOnce, useEvent } from 'react-use';
import { GridContainer, maxMediaQuery } from '../grid';
import { ConditionalWrapper } from '../wrapper/ConditionalWrapper';
import { isBrowser } from '../../utils/system';

interface Props {
  id?: string;
  statNumber?: string;
  description: string;
  withContainer?: boolean;
  withSpaceTop?: boolean;
  withSpaceBottom?: boolean;
}

const StatBlock: FC<Props> = ({
  id,
  statNumber,
  description,
  withContainer = false,
  withSpaceTop = true,
  withSpaceBottom = true,
}) => {
  const numberContainerRef = useRef<HTMLDivElement>(null);
  const [resizeCount, { inc: increaseResizeCount }] = useCounter(0);
  const [descriptionWidth, setDescriptionWidth] = useState(0);

  const handleWithContainer = useCallback(
    (children: React.ReactNode) => <StyledGridContainer id={id}>{children}</StyledGridContainer>,
    [withContainer, id]
  );

  const handleResize = useCallback(() => {
    increaseResizeCount();
  }, [increaseResizeCount]);

  useDebounce(
    () => {
      const { current: containerElement } = numberContainerRef;

      if (isBrowser() === false || containerElement == null) {
        return;
      }

      const itemRects = containerElement.getBoundingClientRect();

      setDescriptionWidth(itemRects.width);
    },
    50,
    [resizeCount, setDescriptionWidth]
  );

  useEvent('resize', handleResize);
  useEffectOnce(handleResize);

  return (
    <ConditionalWrapper condition={withContainer === true} wrapperPositive={handleWithContainer}>
      <Container
        id={withContainer === false ? id : undefined}
        withSpaceTop={withSpaceTop}
        withSpaceBottom={withSpaceBottom}
      >
        <NumberContainer ref={numberContainerRef}>{statNumber}</NumberContainer>
        <DescriptionContainer style={{ width: descriptionWidth }}>{description}</DescriptionContainer>
      </Container>
    </ConditionalWrapper>
  );
};

const StyledGridContainer = styled(GridContainer)`
  ${maxMediaQuery.sm} {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const Container = styled.div<{ withSpaceTop: boolean; withSpaceBottom: boolean }>`
  min-height: 335px;
  background-color: ${({ theme }) => theme.colors.primary.white};
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2);
  border-radius: 24px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: ${({ theme, withSpaceTop }) => (withSpaceTop === true ? `${theme.spacing.unit * 5}px` : 0)};
  margin-bottom: ${({ theme, withSpaceBottom }) => (withSpaceBottom === true ? `${theme.spacing.unit * 5}px` : 0)};
  z-index: 10;

  ${maxMediaQuery.md} {
    max-width: 320px;
    min-height: 200px;
    margin-top: ${({ theme, withSpaceTop }) => (withSpaceTop === true ? `${theme.spacing.unit * 3}px` : 0)};
    margin-bottom: ${({ theme, withSpaceBottom }) => (withSpaceBottom === true ? `${theme.spacing.unit * 3}px` : 0)};
    padding: 20px;
  }
`;

const NumberContainer = styled.div`
  font-family: ${({ theme }) => theme.fonts.headers};
  font-style: normal;
  font-weight: 400;
  font-size: 200px;
  line-height: 200px;
  margin: ${({ theme }) => theme.spacing.unit}px;
  color: ${({ theme }) => theme.colors.primary.comicReliefRed};

  ${maxMediaQuery.lg} {
    font-size: 150px;
    line-height: 150px;
  }

  ${maxMediaQuery.md} {
    font-size: 100px;
    line-height: 100px;
  }
`;

const DescriptionContainer = styled.h4`
  min-width: 220px;
  margin: 0px;
  text-align: center;
`;

export default StatBlock;
