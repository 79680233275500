import styled from '@emotion/styled';
import React, { useEffect, useRef } from 'react';
import Link from '../link/Link';
import CardBody from './CardBody';
import { CardProps } from './props';

type CardProjectMapProps = CardProps & {
  top: number;
  left: number;
  displayMode: string;
};

export const CURRENT_PROJECT_CARD_ID = 'current-project-map-card';

const CardProjectMap = React.forwardRef<HTMLDivElement, CardProjectMapProps>(
  ({ id, top, left, path, displayMode, ...cardBodyProps }, ref) => {
    const linkRef = useRef<HTMLAnchorElement>(null);
    const pathRef = useRef<string>('');

    useEffect(() => {
      if (displayMode !== 'none') {
        const currentPath = path;

        pathRef.current = path ?? '';

        setTimeout(() => {
          if (currentPath != null && currentPath === pathRef.current) {
            linkRef.current?.focus();
          }
        }, 50);
      }
    }, [displayMode, path]);

    return (
      <Link ref={linkRef} id={id} href={path} title={`Go to ${cardBodyProps.title}`}>
        <ContentContainer ref={ref} top={top} left={left} displayMode={displayMode}>
          <CardBodyContainer>
            <CardBody {...cardBodyProps} />
            <MapSelector />
          </CardBodyContainer>
        </ContentContainer>
      </Link>
    );
  }
);

const CardBodyContainer = styled.div`
  width: 100%;
`;

const ContentContainer = styled.div<{ top: number; left: number; displayMode: string }>`
  position: absolute;
  top: ${({ top }) => top}px;
  left: ${({ left }) => left}px;
  display: ${({ displayMode }) => displayMode};
  flex-direction: column;
  max-width: 370px;
  transition: all 0.8s ease-in-out;
  z-index: 100;
`;

const MapSelector = styled.div`
  width: 40px;
  height: 40px;
  background-color: ${({ theme }) => theme.colors.primary.white};
  transform: rotate(45deg);
  margin-top: -20px;
  margin-left: calc(50% - 20px);
  box-shadow: 12px 12px 8px -10px rgba(0, 0, 0, 0.2);
`;

export default CardProjectMap;
