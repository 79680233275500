import { FC, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { isBrowser } from '../../utils/system';

const PortalContainer: FC = ({ children }) => {
  const container = isBrowser() === true ? document.createElement('div') : undefined;

  useEffect(() => {
    const browser = isBrowser();
    const portalsElement = browser === true ? document.getElementById('portals') : undefined;

    if (browser === true && container != null) {
      portalsElement?.appendChild(container);
    }

    return () => {
      if (browser === true && container != null) {
        portalsElement?.removeChild(container);
      }
    };
  }, [container]);

  return isBrowser() === true && container != null ? ReactDOM.createPortal(children, container) : null;
};

export default PortalContainer;
