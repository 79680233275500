import React, { FC } from 'react';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { ContentfulRichTextGatsbyReference, RenderRichTextData } from 'gatsby-source-contentful/rich-text';
import PaheHeaderMainPartExtended from './PageHeaderMainPartExtended';
import { GridContainer, maxMediaQuery } from '../grid';

interface Props {
  title: string;
  created?: string;
  excerpt?: RenderRichTextData<ContentfulRichTextGatsbyReference>;
  articleType?: string;
  image?: IGatsbyImageData;
  imageAlt?: string;
}

const ArticleHeader: FC<Props> = ({ title, created, excerpt, articleType, image, imageAlt }) => {
  const theme = useTheme();
  return (
    <Container>
      <GridContainer>
        <PaheHeaderMainPartExtended
          title={title}
          topText={created}
          bottomText={excerpt}
          tags={[articleType || '']}
          topTextColor={theme.colors.primary.comicReliefRed}
        />
        {image && <ArticleImage loading="eager" image={image} alt={imageAlt ?? ''} />}
      </GridContainer>
    </Container>
  );
};

const ArticleImage = styled(GatsbyImage)`
  width: 100%;
  height: 500px;
  background: #8bacfe;
  border-radius: 24px;
  margin-top: ${({ theme }) => theme.spacing.unit * 6}px;
  mask-image: -webkit-radial-gradient(white, black);

  ${maxMediaQuery.md} {
    height: 350px;
  }

  ${maxMediaQuery.sm} {
    height: 300px;
  }
`;

const Container = styled.div`
  width: 100%;
  padding-top: 80px;

  ${maxMediaQuery.md} {
    padding-top: 60px;
  }
`;

export default ArticleHeader;
