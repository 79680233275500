import React, { FC, useMemo } from 'react';
import { componentQuery } from './query';
import Slider from '../slider/Slider';
import MediaBlock from '../media-block/MediaBlock';
import { MediaBlockProps, MediaBlockVariant } from '../media-block/props';

type Props = {
  id?: string;
  variant?: MediaBlockVariant;
  withContainer?: boolean;
  withSpaceTop?: boolean;
  withSpaceBottom?: boolean;
  slides: MediaBlockProps[];
};

const MediaBlockSlider: FC<Props> = ({
  id,
  variant = MediaBlockVariant.FULL_WIDTH,
  withContainer,
  withSpaceTop = true,
  withSpaceBottom = true,
  slides,
}) => {
  const childrenSlides = useMemo(() => {
    return slides.map((slide, index) => (
      <MediaBlock
        key={index}
        variant={variant}
        withContainer={withContainer}
        withSpaceTop={withSpaceTop}
        withSpaceBottom={withSpaceBottom}
        {...slide}
        withTransition={false}
        inSlider
      />
    ));
  }, [slides, variant, withContainer]);

  return <Slider id={id} slides={childrenSlides} />;
};

export const query = componentQuery;

export default MediaBlockSlider;
