import { ComponentMenuLinkFragment } from 'graphql-types';
import { MenuLink } from '../../models/content/menu-link';
import { AvailableEntryType, getApplicationTargetEntry } from './url';

export function getFinalUri(menuLink: ComponentMenuLinkFragment): string | undefined {
  return menuLink.linkUrl != null
    ? menuLink.linkUrl
    : menuLink.targetEntry?.slug != null
    ? getApplicationTargetEntry(
        menuLink.targetEntry?.slug ?? '',
        (menuLink.targetEntry?.internal?.type as AvailableEntryType) ?? undefined
      )
    : undefined;
}

export function mapContentMenuLinksToProps(menuLinks: ComponentMenuLinkFragment[]): MenuLink[] {
  return menuLinks.map((menuLink) => ({
    key: menuLink.id,
    label: menuLink.label ?? '',
    url: getFinalUri(menuLink),
    nestedElements:
      menuLink.nestedElements != null
        ? mapContentMenuLinksToProps(menuLink.nestedElements as ComponentMenuLinkFragment[])
        : [],
  }));
}
