import { IGatsbyImageData } from 'gatsby-plugin-image';
import { ContentfulRichTextGatsbyReference, RenderRichTextData } from 'gatsby-source-contentful/rich-text';

export enum CardVariant {
  PROJECT = 'project',
  STORY = 'story',
  FEATURED = 'featured',
  PUBLICATION = 'publication',
  ARTICLE = 'article',
  PRESS = 'press',
}

export type CardProps = {
  key: string;
  id?: string;
  elementDate?: string;
  locationsIds?: string[];
  locations?: string[];
  topTypesIds?: string[];
  topTypes?: string[];
  bottomTypesIds?: string[];
  bottomTypes?: string[];
  title: string;
  image?: IGatsbyImageData;
  imageAlt?: string;
  excerpt?: RenderRichTextData<ContentfulRichTextGatsbyReference> | null | undefined;
  imagePosition?: 'left' | 'right';
  path?: string;
};
