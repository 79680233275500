import { useEffect, useState } from 'react';
import checkIfDoNotTrackIsUsed from '../utils/browser/is-do-not-track-used';

export function useIsDoNotTrackUsed(): boolean {
  const [isDoNotTrackUsed, setIsDoNotTrackUsed] = useState(false);

  useEffect(() => {
    setIsDoNotTrackUsed(checkIfDoNotTrackIsUsed());
  });

  return isDoNotTrackUsed;
}
