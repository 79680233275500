import React, { FC } from 'react';
import styled from '@emotion/styled';
import { maxMediaQuery } from '../grid';

const SkipToMainContent: FC = () => {
  return <StyledLink href="#main">Skip to main content</StyledLink>;
};

const StyledLink = styled.a`
  position: absolute;
  top: 0px;
  left: -1px;
  z-index: -1;
  padding: 0px;
  width: 1px;
  height: 1px;
  overflow: hidden;

  &:active,
  &:focus {
    width: 200px;
    height: auto;
    left: calc(50% - 100px);
    padding: 8px;
    z-index: 101;
    overflow: auto;
  }

  ${maxMediaQuery.md} {
    display: none;
  }
`;

export default SkipToMainContent;
