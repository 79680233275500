export type BreakpointsTypes = 'xs' | 'sm' | 'md' | 'lg' | 'xl';
type MinBreakpointsTypes = Exclude<BreakpointsTypes, 'xs'>;
export type MaxBreakpointsTypes = Exclude<BreakpointsTypes, 'xl'>;
type MinMediaQueryType = Record<MinBreakpointsTypes, string>;
type MaxMediaQueryType = Record<MaxBreakpointsTypes, string>;

export const minAvailableBreakpoints: Record<MinBreakpointsTypes, number> = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
};

export const maxAvailableBreakpoints: Record<MaxBreakpointsTypes, number> = {
  xs: 575.98,
  sm: 767.98,
  md: 991.98,
  lg: 1199.98,
};

const minMediaQuery = {} as MinMediaQueryType;
const maxMediaQuery = {} as MaxMediaQueryType;

(Object.keys(minAvailableBreakpoints) as Array<keyof MinMediaQueryType>).forEach((breakpointKey) => {
  minMediaQuery[breakpointKey] = `@media (min-width: ${minAvailableBreakpoints[breakpointKey]}px)`;
});

(Object.keys(maxAvailableBreakpoints) as Array<keyof MaxMediaQueryType>).forEach((breakpointKey) => {
  maxMediaQuery[breakpointKey] = `@media (max-width: ${maxAvailableBreakpoints[breakpointKey]}px)`;
});

export const maxMediaQueryKeys = Object.keys(maxMediaQuery) as MaxBreakpointsTypes[];

export { minMediaQuery, maxMediaQuery };
