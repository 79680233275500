import { ComponentSeoDataFragment } from 'graphql-types';
import { SeoProps } from '../../components/seo/props';

export function mapContentSeoDataToProps(content: ComponentSeoDataFragment, fallbackTitle: string): SeoProps {
  return {
    title: content?.title ?? fallbackTitle,
    description: content?.description ?? undefined,
    featuredImageUrl: content?.featuredImage?.file?.url ?? undefined,
    canonicalUrl: content?.canonicalUrl ?? undefined,
    noIndex: content?.noIndex ?? false,
    noFollow: content?.noFollow ?? false,
    ogType: content?.ogType ?? undefined,
    ogDescription: content?.ogDescription ?? undefined,
    ogTitle: content?.ogTitle ?? undefined,
  };
}
