import styled from '@emotion/styled';
import { AnimationProps, motion } from 'framer-motion';
import React, { FC, useCallback } from 'react';
import { MenuLink } from '../../models/content/menu-link';
import { minMediaQuery } from '../grid';
import Link from '../link/Link';

type Props = {
  items: MenuLink[];
  open?: boolean;
  onToggle(): void;
};

const listAnimationVariants: AnimationProps['variants'] = {
  hidden: {
    y: -100,
    opacity: 0,
    zIndex: -1,
    transitionEnd: {
      visibility: 'hidden',
    },
  },
  active: {
    y: 0,
    opacity: 1,
    zIndex: -1,
    visibility: 'visible',
  },
};

const listAnimationTransition: AnimationProps['transition'] = {
  y: { type: 'spring', stiffness: 300, damping: 30, duration: 0.5 },
  opacity: { duration: 0.4 },
};

const NavigationMobileCountryList: FC<Props> = ({ items, open, onToggle }) => {
  const handleItemClick = useCallback(() => {
    if (open === true) {
      onToggle();
    }
  }, [open, onToggle]);

  return (
    <List
      animate={open === true ? 'active' : 'hidden'}
      variants={listAnimationVariants}
      transition={listAnimationTransition}
      initial="hidden"
    >
      {items.map((item) => (
        <li key={item.key}>
          <ListItem href={item.url} title={`Go to ${item.label}`} onClick={handleItemClick}>
            {item.label}
          </ListItem>
        </li>
      ))}
    </List>
  );
};

const List = styled(motion.ul)`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: 0;
  padding: 0;
  background-color: ${({ theme }) => theme.colors.primary.white};

  > li {
    position: relative;
    display: block;
    height: 100%;
  }

  ${minMediaQuery.md} {
    display: none;
  }
`;

const ListItem = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 20px;
  font-family: ${({ theme }) => theme.typography.body.fontFamily};
  font-size: ${({ theme }) => theme.typography.body.fontSize};
  font-weight: 400;
  line-height: ${({ theme }) => theme.typography.body.lineHeight};
`;

export default NavigationMobileCountryList;
