import styled from '@emotion/styled';
import { OverlayProps } from './props';

const OverlayBase = styled.div<OverlayProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  z-index: 10;
`;

export default OverlayBase;
