import isBrowser from '../system/isBrowser';

export default function checkIfDoNotTrackIsUsed(): boolean {
  if (isBrowser()) {
    const doNotTrackOption = window.doNotTrack || window.navigator.doNotTrack;

    if (!doNotTrackOption) {
      return false;
    }

    if (doNotTrackOption.charAt(0) === '1' || doNotTrackOption === 'yes') {
      return true;
    }

    return false;
  }

  return false;
}
