import React, { FC, useMemo } from 'react';
import { graphql } from 'gatsby';
import { useLocation } from '@reach/router';
import { ComponentSeoDataFragment, ContentfulArticleConnection } from 'graphql-types';
import styled from '@emotion/styled';
import { format } from 'date-fns';
import { ContentfulRichTextGatsbyReference, RenderRichTextData } from 'gatsby-source-contentful/rich-text';
import Seo from '../components/seo/Seo';
import ArticleHeader from '../components/page-header/ArticleHeader';
import { Col, GridContainer, maxMediaQuery, Row } from '../components/grid';
import Share from '../components/share/Share';
import { mapArticleRichText } from '../utils/rich-text/article';
import { parseApiTimezoneDateStringToDate } from '../utils/date';
import { AvailableContentCardComponents } from '../utils/content/card';
import { mapContentPagesListChosenToPagesProps } from '../utils/content/pages-list-chosen';
import { SeoProps } from '../components/seo/props';
import { mapContentSeoDataToProps } from '../utils/content/seo';
import { RelatedCardsTypes } from '../components/related-cards/props';
import RelatedCards from '../components/related-cards/RelatedCards';

const ArticleTemplate: FC<{ data: { article: ContentfulArticleConnection } }> = ({ data }) => {
  const articleData = data.article.edges?.[0]?.node;

  const location = useLocation();

  const content = useMemo(() => {
    return (
      articleData?.content != null &&
      mapArticleRichText((articleData.content as unknown) as RenderRichTextData<ContentfulRichTextGatsbyReference>)
    );
  }, [articleData]);
  const articleDate = useMemo<string | undefined>(() => {
    if (articleData?.date == null) {
      return undefined;
    }

    const parsedArticlaDate = parseApiTimezoneDateStringToDate(articleData.date);

    return parsedArticlaDate != null ? format(parsedArticlaDate, 'dd MMMM yyyy') : undefined;
  }, [articleData]);
  const relatedContent = useMemo<RelatedCardsTypes>(() => {
    const relatedProjects = (articleData?.relatedProjects as AvailableContentCardComponents[]) ?? undefined;
    const relatedStories = (articleData?.relatedStories as AvailableContentCardComponents[]) ?? undefined;
    const relatedPublications = (articleData?.relatedPublications as AvailableContentCardComponents[]) ?? undefined;
    const relatedArticles = (articleData?.relatedArticles as AvailableContentCardComponents[]) ?? undefined;
    const relatedPress = (articleData?.relatedPressReleases as AvailableContentCardComponents[]) ?? undefined;

    return {
      projects: mapContentPagesListChosenToPagesProps(relatedProjects),
      stories: mapContentPagesListChosenToPagesProps(relatedStories),
      publications: mapContentPagesListChosenToPagesProps(relatedPublications),
      articles: mapContentPagesListChosenToPagesProps(relatedArticles),
      press: mapContentPagesListChosenToPagesProps(relatedPress),
    };
  }, [articleData]);
  const seoData = useMemo<SeoProps>(() => {
    return mapContentSeoDataToProps(articleData?.seoData ?? ({} as ComponentSeoDataFragment), articleData?.title ?? '');
  }, [articleData]);

  return (
    <>
      <Seo {...seoData} />
      <ArticleHeader
        title={articleData?.title || ''}
        created={articleData?.displayDate === true ? articleDate : undefined}
        excerpt={
          ((articleData?.excerpt as unknown) as RenderRichTextData<ContentfulRichTextGatsbyReference>) ?? undefined
        }
        articleType={articleData?.tag?.name ?? undefined}
        image={articleData?.featuredImage?.gatsbyImageData}
        imageAlt={articleData?.featuredImage?.description ?? undefined}
      />
      <GridContainer>
        <Row>
          <Col>
            <Content>{content}</Content>
          </Col>
        </Row>
        <Row>
          <StyledCol>
            <Share
              title={articleData?.title ?? ''}
              url={location.href}
              image={articleData?.featuredImage?.file?.url ?? undefined}
            />
          </StyledCol>
        </Row>
      </GridContainer>
      <RelatedCards {...relatedContent} context="articles" />
    </>
  );
};

export const query = graphql`
  query Articles($id: String!) {
    article: allContentfulArticle(filter: { contentful_id: { eq: $id } }) {
      edges {
        node {
          createdAt
          title
          id
          seoData {
            ...ComponentSeoData
          }
          date
          displayDate
          content {
            raw
            references {
              ... on Node {
                ...ComponentAccordions
                ...ComponentButton
                ...ComponentCardFeatured
                ...ComponentMediaBlock
                ...ComponentMediaBlockSlider
                ...ComponentPagesListChosen
                ...ComponentPromoContent
                ...ComponentQuote
                ...ComponentStatBlock
                ...ComponentSpacer
                ...ComponentTextBlock
                ...ComponentTextAndGraphic
                ...ComponentTwoColumns
                ...EmbeddedAsset
                ...EmbeddedEntryLink
              }
            }
          }
          excerpt {
            raw
          }
          featuredImage {
            gatsbyImageData
            description
            file {
              url
            }
          }
          contentful_id
          tag {
            name
          }
          shortDescription {
            raw
          }
          relatedProjects {
            ... on Node {
              ...ComponentCardProject
            }
          }
          relatedPublications {
            ... on Node {
              ...ComponentCardPublication
            }
          }
          relatedStories {
            ... on Node {
              ...ComponentCardStory
            }
          }
          relatedArticles {
            ... on Node {
              ...ComponentCardArticle
            }
          }
          relatedPressReleases {
            ... on Node {
              ...ComponentCardPress
            }
          }
        }
      }
    }
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledCol = styled(Col)`
  width: 100%;
  padding: ${({ theme }) => theme.spacing.unit * 5}px 0px;
  display: flex;
  justify-content: center;

  ${maxMediaQuery.md} {
    padding: ${({ theme }) => theme.spacing.unit * 3}px 0px;
  }

  ${maxMediaQuery.sm} {
    padding: ${({ theme }) => theme.spacing.unit * 3}px 0px;
  }
`;

export default ArticleTemplate;
