import styled from '@emotion/styled';
import { transparentize } from 'polished';
import ButtonBase from './ButtonBase';

const ButtonOutlinedVioletAlt = styled(ButtonBase)`
  color: ${(props) => props.theme.colors.primary.comicReliefDeepViolet};
  background-color: ${(props) => props.theme.colors.primary.transparent};
  border: 2px solid ${(props) => props.theme.colors.primary.comicReliefDeepViolet};

  svg {
    fill: ${(props) => props.theme.colors.primary.comicReliefDeepViolet};
  }

  &:hover {
    background-color: ${(props) => transparentize(0.75, props.theme.colors.primary.comicReliefDeepViolet)};
    color: ${(props) => props.theme.colors.primary.comicReliefDeepViolet};

    svg {
      fill: ${(props) => props.theme.colors.primary.comicReliefDeepViolet};
    }
  }

  &.active {
    background-color: ${(props) => props.theme.colors.primary.comicReliefDeepViolet};
    color: ${(props) => props.theme.colors.primary.white};

    svg {
      fill: ${(props) => props.theme.colors.primary.white};
    }
  }
`;

export default ButtonOutlinedVioletAlt;
