import React from 'react';
import { BLOCKS, INLINES, MARKS, Text, Block, Inline, Hyperlink } from '@contentful/rich-text-types';
import { GatsbyImage } from 'gatsby-plugin-image';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { ContentfulAsset } from 'graphql-types';
import styled from '@emotion/styled';
import { mapPageContent, AvailablePageContentComponents } from '../page';
import Link from '../../components/link/Link';
import { AvailableEntrySlugs } from '../../models/content/entry-slug';
import { AvailableEntryType, getApplicationTargetEntry } from '../content/url';

export interface NodeData<T> {
  nodeType: BLOCKS & INLINES;
  data?: {
    target?: T;
  };
}

export type RichTextParameters = Parameters<typeof renderRichText>;
export type RichTextOptions = RichTextParameters[1];

export function reusableRichTextOptions(embeddedEntryWithSpacing = false): RichTextOptions {
  return {
    renderMark: {
      [MARKS.BOLD]: (text: React.ReactNode) => <strong>{text}</strong>,
      [MARKS.ITALIC]: (text: React.ReactNode) => <i>{text}</i>,
      [MARKS.UNDERLINE]: (text: React.ReactNode) => <span className="underlined">{text}</span>,
      [MARKS.CODE]: (text: React.ReactNode) => text,
    },
    renderNode: {
      [BLOCKS.LIST_ITEM]: (_node: React.ReactNode, children: React.ReactNode) => <ListItem>{children}</ListItem>,
      [BLOCKS.EMBEDDED_ENTRY]: (node: Block | Inline): React.ReactNode => {
        const content = (node as unknown) as NodeData<AvailablePageContentComponents>;

        if (content?.data?.target == null) {
          return null;
        }

        return mapPageContent(content.data.target, false, embeddedEntryWithSpacing, embeddedEntryWithSpacing);
      },
      [BLOCKS.EMBEDDED_ASSET]: (node: Block | Inline): React.ReactNode => {
        const content = (node as unknown) as NodeData<ContentfulAsset>;

        if (content?.data?.target == null || content?.data?.target?.gatsbyImageData == null) {
          return null;
        }

        return (
          <GatsbyImage image={content.data.target?.gatsbyImageData} alt={content.data.target?.description ?? ''} />
        );
      },
      [INLINES.HYPERLINK]: (node: Block | Inline, children: React.ReactNode): React.ReactNode => {
        const link = (node as unknown) as Hyperlink;

        if (link?.data?.uri == null) {
          return null;
        }

        return (
          <Link
            href={link.data.uri}
            title={Array.isArray(children) && typeof children[0] === 'string' ? `Open ${children[0] ?? ''}` : undefined}
          >
            {children}
          </Link>
        );
      },
      [INLINES.ENTRY_HYPERLINK]: (node: Block | Inline): React.ReactNode => {
        const content = (node as unknown) as NodeData<AvailableEntrySlugs>;
        const valueContent = node.content?.[0] as Text;

        if (content.data?.target?.slug == null) {
          return null;
        }

        return (
          <Link
            href={getApplicationTargetEntry(
              content.data.target.slug,
              content.data.target.internal.type as AvailableEntryType
            )}
            title={`Open ${(content.data.target?.title as string) ?? ''}`}
          >
            {valueContent?.value ?? ''}
          </Link>
        );
      },
      [INLINES.ASSET_HYPERLINK]: (node: Block | Inline): React.ReactNode => {
        const content = (node as unknown) as NodeData<ContentfulAsset>;
        const valueContent = node.content?.[0] as Text;

        if (content.data?.target?.file?.url == null) {
          return null;
        }

        return (
          <Link href={content.data?.target?.file?.url} title={`Open ${content.data.target.description ?? ''}`}>
            {valueContent?.value ?? ''}
          </Link>
        );
      },
    },
  };
}

const ListItem = styled.li`
  > div,
  > div > div {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin: 0;
    text-align: left;
  }
`;
