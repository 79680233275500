import React, { FC, forwardRef, useMemo } from 'react';
import styled from '@emotion/styled';
import { ButtonVariants, ButtonProps, ButtonComponent } from './buttonProps';
import ButtonSolid from './ButtonSolid';
import ButtonOutlined from './ButtonOutlined';
import ButtonSolidWhite from './ButtonSolidWhite';
import ButtonOutlinedViolet from './ButtonOutlinedViolet';
import ButtonOutlinedVioletAlt from './ButtonOutlinedVioletAlt';
import ButtonOutlinedWhite from './ButtonOutlinedWhite';
import Icon from '../icon/Icon';
import { componentQuery } from './query';
import Link from '../link/Link';

type Props = {
  variant?: ButtonVariants;
} & ButtonProps;

const variants: Record<ButtonVariants, ButtonComponent> = {
  [ButtonVariants.Solid]: ButtonSolid,
  [ButtonVariants.SolidWhite]: ButtonSolidWhite,
  [ButtonVariants.Outlined]: ButtonOutlined,
  [ButtonVariants.OutlinedViolet]: ButtonOutlinedViolet,
  [ButtonVariants.OutlinedVioletAlt]: ButtonOutlinedVioletAlt,
  [ButtonVariants.OutlinedWhite]: ButtonOutlinedWhite,
};

const Button: FC<Props> = forwardRef<HTMLButtonElement, Props>(
  (
    { variant = ButtonVariants.Solid, type = 'button', icon, color, href, children, className, active, ...props },
    ref
  ) => {
    const Component = variants[variant];
    const componentAs = useMemo(() => {
      if (href != null) {
        return Link;
      }

      return 'button';
    }, [href]);

    return (
      <Component
        ref={ref}
        as={componentAs}
        type={componentAs === 'button' ? type : undefined}
        href={componentAs !== 'button' ? href : undefined}
        className={`btn ${active === true ? 'active' : ''} ${className ?? ''}`}
        {...props}
      >
        {children}
        {icon != null && (
          <IconContainer>
            <Icon iconType={icon} />
          </IconContainer>
        )}
      </Component>
    );
  }
);

export const query = componentQuery;

const IconContainer = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  margin-left: ${(props) => props.theme.spacing.unit * 2}px;

  svg {
    display: block;
    width: 20px;
    height: 20px;
  }
`;

export default Button;
