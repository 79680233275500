import { graphql, useStaticQuery } from 'gatsby';
import React, { FC, useMemo } from 'react';
import { PagesListProjectQuery } from 'graphql-types';
import { PageListFilterItem, PagesListItem, PagesListSharedProps } from './props';
import {
  mapContentPageListProjectToListFilters,
  mapContentPageListProjectToListItems,
} from '../../utils/content/pages-list';
import PagesListBody from './PagesListBody';

const PagesListProject: FC<PagesListSharedProps> = ({ showFiltering }) => {
  const data = useStaticQuery<PagesListProjectQuery>(graphql`
    query PagesListProject {
      allContentfulProject(
        sort: { order: DESC, fields: endDate }
        filter: { slug: { regex: "/^(?!dont\\-remove).+/" } }
      ) {
        nodes {
          ...ComponentCardProject
        }
      }
    }
  `);
  const listItems = useMemo<PagesListItem[]>(() => {
    const items = data?.allContentfulProject?.nodes;

    return mapContentPageListProjectToListItems(items);
  }, [data]);
  const listFilters = useMemo<PageListFilterItem[]>(() => {
    const items = data?.allContentfulProject?.nodes;

    return mapContentPageListProjectToListFilters(items);
  }, [data]);

  return <PagesListBody items={listItems} filters={listFilters} showFiltering={showFiltering ?? false} />;
};

export default PagesListProject;
