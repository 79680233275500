import styled from '@emotion/styled';
import ButtonBase from './ButtonBase';

const ButtonOutlined = styled(ButtonBase)`
  color: ${(props) => props.theme.colors.primary.comicReliefRed};
  background-color: ${(props) => props.theme.colors.primary.transparent};
  border: 2px solid ${(props) => props.theme.colors.primary.comicReliefRed};

  &:hover {
    border-color: ${(props) => props.theme.colors.secondary.coral.dark};
    color: ${(props) => props.theme.colors.secondary.coral.dark};

    svg {
      fill: ${(props) => props.theme.colors.secondary.coral.dark};
    }
  }

  svg {
    fill: ${(props) => props.theme.colors.primary.comicReliefRed};
  }
`;

export default ButtonOutlined;
