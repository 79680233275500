import React, { FC, useMemo } from 'react';
import styled from '@emotion/styled';
import { AnimationProps, motion } from 'framer-motion';
import { CardProps } from '../card/props';
import { mapExcerptRichText } from '../../utils/rich-text/excerpt';
import Link from '../link/Link';

type FullpageInfoProps = CardProps & {
  top: number;
  left: number;
  displayMode: string;
  closeMethod: () => void;
};

const cardVariants: AnimationProps['variants'] = {
  closed: {
    opacity: 0,
    y: 50,
    transitionEnd: {
      visibility: 'hidden',
    },
  },
  opened: {
    opacity: 1,
    y: 0,
    visibility: 'visible',
  },
};

const cardTransition: AnimationProps['transition'] = {
  duration: 0.3,
};

const FullpageInfo: FC<FullpageInfoProps> = ({ id, title, location, excerpt, path, bottomType, displayMode }) => {
  const visible = useMemo(() => displayMode !== 'none', [displayMode]);

  const parsedExcerpt = useMemo<React.ReactNode | undefined>(() => {
    if (excerpt == null) {
      return undefined;
    }

    return mapExcerptRichText(excerpt);
  }, [excerpt]);

  return (
    <AnimationContainer
      id={id}
      variants={cardVariants}
      initial="closed"
      animate={visible === true ? 'opened' : 'closed'}
      transition={cardTransition}
    >
      <Container href={path}>
        <Location>{location}</Location>
        <Title>{title}</Title>
        {parsedExcerpt && <Excerpt>{parsedExcerpt}</Excerpt>}
        {bottomType && <Type>{bottomType}</Type>}
      </Container>
    </AnimationContainer>
  );
};

const AnimationContainer = styled(motion.div)`
  position: fixed;
  bottom: 20px;
  left: 20px;
  right: 20px;
  z-index: 99;
`;

const Container = styled(Link)`
  display: flex;
  flex-direction: column;
  margin: 0px;
  padding: 30px;
  background-color: ${({ theme }) => theme.colors.primary.white};
  border-radius: 24px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
`;

const Location = styled.h5`
  margin: 0;
  color: ${({ theme }) => theme.colors.primary.comicReliefRed};
`;

const Title = styled.h3`
  margin: 0;
  margin-top: ${({ theme }) => theme.spacing.unit * 2}px;
`;

const Excerpt = styled.div`
  margin-top: ${({ theme }) => theme.spacing.unit * 2}px;
`;

const Type = styled.h5`
  margin: 0;
  margin-top: ${({ theme }) => theme.spacing.unit * 2}px;
  color: ${(props) => props.theme.colors.secondary.grey.dark};
`;

export default FullpageInfo;
