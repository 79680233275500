import { ComponentPagesListChosenFragment } from 'graphql-types';
import { DropdownItem } from '../../components/dropdown/props';
import { PageListFilterItem } from '../../components/pages-list/props';
import { PagesListChosenProps } from '../../components/pages-list-chosen/props';
import { getRandomId } from '../id';
import { AvailableContentCardComponents, getCardVariantByInternalType, mapCardsContent } from './card';

export function mapContentPagesListChosenToPagesProps(
  contentPages: AvailableContentCardComponents[]
): PagesListChosenProps['pages'] {
  if (contentPages == null) {
    return [];
  }

  const pages: PagesListChosenProps['pages'] = [];

  contentPages.forEach((page) => {
    const cardProps = mapCardsContent(page);

    pages.push({
      ...cardProps,
      key: page?.id ?? getRandomId('key'),
      title: cardProps?.title ?? '',
      variant: getCardVariantByInternalType(page?.internal?.type),
    });
  });

  return pages;
}

export function mapContentPageListAllToListFilters(content: AvailableContentCardComponents[]): PageListFilterItem[] {
  if (content == null) {
    return [];
  }

  const filterItems: DropdownItem[] = [];

  content.forEach((item) => {
    if (item?.internal?.type != null) {
      const existingItem = filterItems.find((filterItem) => filterItem.key === item.internal.type);

      if (existingItem == null) {
        let itemLabel: string;

        switch (item.internal.type) {
          case 'ContentfulArticle':
            itemLabel = 'Article';
            break;

          case 'ContentfulPressRelease':
            itemLabel = 'Press release';
            break;

          case 'ContentfulStory':
            itemLabel = 'Story';
            break;

          case 'ContentfulProject':
            itemLabel = 'Project';
            break;

          case 'ContentfulPublication':
            itemLabel = 'Publication';
            break;

          default:
            itemLabel = 'Unknown';
        }

        filterItems.push({ key: item.internal.type, value: item.internal.type, label: itemLabel ?? '' });
      }
    }
  });

  return [
    {
      key: 'type',
      label: 'Filter by type',
      options: filterItems,
    },
  ];
}

export function mapContentPagesListChosenToProps(content: ComponentPagesListChosenFragment): PagesListChosenProps {
  const contentPages = (content?.pages as AvailableContentCardComponents[]) ?? undefined;
  const pages = mapContentPagesListChosenToPagesProps(contentPages);
  const filters = mapContentPageListAllToListFilters(contentPages);

  return {
    id: content?.contentfulid ?? undefined,
    title: content?.title ?? '',
    pages,
    showFiltering: content?.showFiltering ?? false,
    typeFilters: filters,
  };
}
