import styled from '@emotion/styled';
import { minMediaQuery } from './mediaquery';

const GridContainer = styled.div<{ fluid?: boolean }>`
  position: relative;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;

  ${minMediaQuery.sm} {
    width: ${({ fluid }) => (fluid === true ? '100%' : '540px')};
  }

  ${minMediaQuery.md} {
    width: ${({ fluid }) => (fluid === true ? '100%' : '720px')};
    padding-left: ${(props) => props.theme.grid.gutter / 2}px;
    padding-right: ${(props) => props.theme.grid.gutter / 2}px;
  }

  ${minMediaQuery.lg} {
    width: ${({ fluid }) => (fluid === true ? '100%' : '960px')};
  }

  ${minMediaQuery.xl} {
    width: ${({ theme, fluid }) => (fluid === true ? '100%' : theme.grid.containerWidth)};
  }
`;

export default GridContainer;
