import styled from '@emotion/styled';

const ButtonBase = styled.button`
  appearance: none;
  position: relative;
  max-width: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 700;
  border-radius: 30px;
  padding: 10px 20px;
  cursor: pointer;
  transition: all 0.3s ease-in-out, transform 0.1s ease;

  &:disabled {
    cursor: not-allowed;
  }

  svg {
    transition: all 0.3s ease-in-out;
  }
`;

export default ButtonBase;
