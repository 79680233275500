import styled from '@emotion/styled';
import { AnimationProps, motion, useAnimation } from 'framer-motion';
import React, { FC, useEffect, useRef } from 'react';
import { useHoverDirty } from 'react-use';
import { useViewportSlideIn } from '../../hooks/viewport-slide-in';
import Link from '../link/Link';
import CardBody from './CardBody';
import CardImage from './CardImage';
import { CardProps } from './props';

const cardAnimationVariants: AnimationProps['variants'] = {
  hoverOut: {
    y: 0,
  },
  hoverIn: {
    y: -5,
  },
};

const animationTransition: AnimationProps['transition'] = {
  x: { duration: 0.4 },
};

const CardStory: FC<CardProps> = ({ id, image, imageAlt, imagePosition, path, ...cardBodyProps }) => {
  const actionContainerRef = useRef<HTMLAnchorElement | null>(null);
  const [cardContainerRef, cardContainerControls] = useViewportSlideIn();
  const actionHovering = useHoverDirty(actionContainerRef);
  const cardHoverControls = useAnimation();

  useEffect(() => {
    if (actionHovering === true) {
      cardHoverControls.start('hoverIn');
    } else {
      cardHoverControls.start('hoverOut');
    }
  }, [actionHovering, cardHoverControls]);

  return (
    <ActionContainer ref={actionContainerRef} id={id} href={path} title={`Go to ${cardBodyProps.title}`}>
      <FadeInContainer ref={cardContainerRef} animate={cardContainerControls}>
        <ContentContainer
          animate={cardHoverControls}
          variants={cardAnimationVariants}
          initial="hoverOut"
          transition={animationTransition}
        >
          {image != null && (
            <ImageContainer>
              <CardImage image={image} imageAlt={imageAlt} isHovering={actionHovering} />
            </ImageContainer>
          )}
          <CardBodyContainer withImage={image != null}>
            <CardBody {...cardBodyProps} largeShadow={false} />
          </CardBodyContainer>
        </ContentContainer>
      </FadeInContainer>
    </ActionContainer>
  );
};

const ActionContainer = styled(Link)`
  display: block;
  width: 100%;
`;

const FadeInContainer = styled(motion.div)`
  width: 100%;
`;

const ContentContainer = styled(motion.div)`
  position: relative;
  width: 100%;
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 600px;
  border-radius: 24px;
  overflow: hidden;
  mask-image: -webkit-radial-gradient(white, black);
`;

const CardBodyContainer = styled.div<{ withImage: boolean }>`
  position: ${({ withImage }) => (withImage === true ? 'absolute' : 'relative')};
  left: ${({ withImage }) => (withImage === true ? '20px' : 'auto')};
  right: ${({ withImage }) => (withImage === true ? '20px' : 'auto')};
  bottom: ${({ withImage }) => (withImage === true ? '20px' : 'auto')};
  width: ${({ withImage }) => (withImage === true ? 'calc(100% - 40px)' : '100%')};
`;

export default CardStory;
