import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { CookieRelatedServices } from '../components/cookie-consent-manager/enums';
import { ServiceConsent } from '../components/cookie-consent-manager/types';
import { CONSENT_COOKIE_NAME } from '../components/cookie-consent-manager/constants';

export function useServiceConsent(serviceName: CookieRelatedServices): boolean {
  const [cookies] = useCookies([CONSENT_COOKIE_NAME]);
  const [serviceApproved, setServiceApproved] = useState(false);

  useEffect(() => {
    if (cookies != null && cookies[CONSENT_COOKIE_NAME] != null) {
      const consentCookie = cookies[CONSENT_COOKIE_NAME] as ServiceConsent[];

      setServiceApproved(consentCookie?.find?.((service) => service.name === serviceName)?.consent ?? false);
    }
  }, [cookies]);

  return serviceApproved;
}
