import styled from '@emotion/styled';
import React, { FC, useCallback } from 'react';
import { Col, GridContainer, maxMediaQuery, Row } from '../grid';
import { ConditionalWrapper } from '../wrapper/ConditionalWrapper';
import PagesListArticle from './PagesListArticle';
import PagesListPress from './PagesListPress';
import PagesListProject from './PagesListProject';
import PagesListPublication from './PagesListPublication';
import PagesListStory from './PagesListStory';
import { PagesListProps, PagesListSharedProps, PagesListVariant } from './props';
import './query';

const variants: Record<PagesListVariant, React.FC<PagesListSharedProps>> = {
  [PagesListVariant.ARTICLE]: PagesListArticle,
  [PagesListVariant.PRESS]: PagesListPress,
  [PagesListVariant.PROJECT]: PagesListProject,
  [PagesListVariant.PUBLICATION]: PagesListPublication,
  [PagesListVariant.STORY]: PagesListStory,
};

const PagesList: FC<PagesListProps> = ({ id, title, variant, withContainer = true, ...sharedProps }) => {
  const Component = variants[variant];
  const handleWithContainer = useCallback((children: React.ReactNode) => <GridContainer>{children}</GridContainer>, [
    withContainer,
    id,
  ]);

  return (
    <Container id={id}>
      <ConditionalWrapper condition={withContainer === true} wrapperPositive={handleWithContainer}>
        {title != null && title.trim() !== '' && (
          <Row>
            <Col>
              <Heading>{title}</Heading>
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            <Component {...sharedProps} />
          </Col>
        </Row>
      </ConditionalWrapper>
    </Container>
  );
};

const Container = styled.section`
  position: relative;
  margin-top: ${({ theme }) => theme.spacing.unit * 5}px;
  margin-bottom: ${({ theme }) => theme.spacing.unit * 10}px;

  ${maxMediaQuery.md} {
    margin-bottom: ${({ theme }) => theme.spacing.unit * 5}px;
  }

  ${maxMediaQuery.sm} {
    margin-top: ${({ theme }) => theme.spacing.unit * 3}px;
    margin-bottom: ${({ theme }) => theme.spacing.unit * 3}px;
  }
`;

const Heading = styled.h3`
  margin: 0;
  margin-top: ${({ theme }) => theme.spacing.unit * 5}px;
  margin-bottom: ${({ theme }) => theme.spacing.unit * 5}px;

  ${maxMediaQuery.sm} {
    margin-top: ${({ theme }) => theme.spacing.unit * 3}px;
    margin-bottom: ${({ theme }) => theme.spacing.unit * 3}px;
  }
`;

export default PagesList;
