import React from 'react';
import {
  ContentfulAccordions,
  ContentfulButton,
  ContentfulHeroBanner,
  ContentfulHeroSlider,
  ContentfulQuote,
  ContentfulTwoColumns,
  ContentfulMediaBlock,
  ContentfulMediaSlider,
  ContentfulTextBlock,
  ContentfulProjectsMap,
  ComponentCardFeaturedFragment,
  ComponentPagesListChosenFragment,
  ComponentPromoContentFragment,
  ContentfulStatBlock,
  ComponentPagesListFragment,
  ContentfulTilesRow,
  ComponentSpacerFragment,
} from 'graphql-types';
import { ContentfulRichTextGatsbyReference, RenderRichTextData } from 'gatsby-source-contentful/rich-text';
import Hero from '../components/hero/Hero';
import { HeroProps, HeroVariants } from '../components/hero/props';
import HeroSlider from '../components/hero-slider/HeroSlider';
import { mapContentHeroToProps } from './content/hero';
import { mapContentMediaBlockToProps } from './content/media-block';
import MediaBlock from '../components/media-block/MediaBlock';
import { MediaBlockProps, MediaBlockVariant } from '../components/media-block/props';
import MediaBlockSlider from '../components/media-block-slider/MediaBlockSlider';
import TwoColumns from '../components/two-columns/TwoColumns';
import Quote from '../components/quote/Quote';
import Button from '../components/button/Button';
import Accordions from '../components/accordion/Accordions';
import { AccordionItem } from '../components/accordion/AccordionItem';
import TextBlock from '../components/text/TextBlock';
import ProjectsMap from '../components/map/ProjectsMap';
import { mapContentFeaturedCardToProps } from './content/featured-card';
import Card from '../components/card/Card';
import { CardVariant } from '../components/card/props';
import { mapContentPagesListChosenToProps } from './content/pages-list-chosen';
import PagesListChosen from '../components/pages-list-chosen/PagesListChosen';
import PromoContentBlock from '../components/promo-content-block/PromoContentBlock';
import StatBlock from '../components/stat/StatBlock';
import PagesList from '../components/pages-list/PagesList';
import { mapContentPagesListToProps } from './content/pages-list';
import TextAndGraphicRow from '../components/text/TextAndGraphicRow';
import { Alignment } from '../templates/PageTemplate';
import { mapTextBlockRichText } from './rich-text/text-block';
import { TwoColumnsColumnSize, TwoColumnsVerticalAlignment } from '../components/two-columns/props';
import { mapContentButtonToProps } from './content/button';
import { mapContentToTilesRowProps } from './content/two-columns';
import { mapContentSpacerToProps } from './content/spacer';
import Spacer from '../components/spacer/Spacer';
import { TextBlockBackgroundVariant } from '../components/text/props';
import { mapContentPromoToProps } from './content/promo';

export type AvailablePageContentComponents =
  | ContentfulHeroBanner
  | ContentfulHeroSlider
  | ContentfulTextBlock
  | ContentfulTwoColumns
  | ContentfulButton
  | ContentfulQuote
  | ContentfulAccordions
  | ContentfulProjectsMap
  | ComponentCardFeaturedFragment
  | ComponentPromoContentFragment
  | ComponentSpacerFragment;

export function mapPageContent(
  item: AvailablePageContentComponents,
  withContainer = true,
  withSpaceTop = true,
  withSpaceBottom = true
): React.ReactNode {
  if (!item || item.internal == null) {
    return null;
  }

  switch (item.internal.type) {
    case 'ContentfulHeroBanner': {
      const content = item as ContentfulHeroBanner;
      const heroProps = mapContentHeroToProps(content);

      return <Hero key={content.id} variant={(content.variant as HeroVariants) ?? undefined} {...heroProps} />;
    }

    case 'ContentfulHeroSlider': {
      const content = item as ContentfulHeroSlider;
      const contentBanners = content.banners as ContentfulHeroBanner[];

      return (
        <HeroSlider
          key={content.id}
          id={content?.contentfulid ?? undefined}
          variant={(content.variant as HeroVariants) ?? undefined}
          slides={contentBanners?.map((banner): HeroProps => mapContentHeroToProps(banner)) ?? []}
        />
      );
    }
    case 'ContentfulMediaBlock': {
      const content = item as ContentfulMediaBlock;
      const mediaBlockProps = mapContentMediaBlockToProps(content);

      return (
        <MediaBlock
          key={content.id}
          variant={(content.variant as MediaBlockVariant) ?? undefined}
          withContainer={withContainer}
          withSpaceTop={withSpaceTop}
          withSpaceBottom={withSpaceBottom}
          {...mediaBlockProps}
        />
      );
    }

    case 'ContentfulMediaSlider': {
      const content = item as ContentfulMediaSlider;
      const contentSlides = content.media as ContentfulMediaBlock[];

      return (
        <MediaBlockSlider
          key={content.id}
          id={content?.contentfulid ?? undefined}
          variant={(content.variant as MediaBlockVariant) ?? undefined}
          withContainer={withContainer}
          withSpaceTop={withSpaceTop}
          withSpaceBottom={withSpaceBottom}
          slides={contentSlides?.map((slide): MediaBlockProps => mapContentMediaBlockToProps(slide)) ?? []}
        />
      );
    }

    case 'ContentfulButton': {
      const content = item as ContentfulButton;
      const props = mapContentButtonToProps(content);

      return (
        <Button key={content?.id} {...props}>
          {content.label}
        </Button>
      );
    }

    case 'ContentfulTextBlock': {
      const content = item as ContentfulTextBlock;

      return (
        <TextBlock
          key={content?.id}
          heading={mapTextBlockRichText(
            ((content?.heading as unknown) as RenderRichTextData<ContentfulRichTextGatsbyReference>) ?? undefined
          )}
          alignment={(content.alignment as Alignment) ?? Alignment.LEFT}
          id={content?.contentfulid ?? ''}
          text={mapTextBlockRichText(
            (content?.text as unknown) as RenderRichTextData<ContentfulRichTextGatsbyReference>
          )}
          background={(content?.background as TextBlockBackgroundVariant) ?? undefined}
          withContainer={withContainer}
          withSpaceTop={withSpaceTop}
          withSpaceBottom={withSpaceBottom}
        />
      );
    }

    case 'ContentfulTwoColumns': {
      const content = item as ContentfulTwoColumns;

      const columnsSize = content?.variant ? content?.variant.split('x') : [];
      const firstColumn = content?.firstColumn ?? [];
      const secondColumn = content?.secondColumn ?? [];

      return (
        <TwoColumns
          key={content?.id}
          firstColumn={firstColumn.map((element, index) =>
            mapPageContent(
              element as AvailablePageContentComponents,
              false,
              index !== 0,
              index !== firstColumn.length - 1
            )
          )}
          secondColumn={secondColumn.map((element, index) =>
            mapPageContent(
              element as AvailablePageContentComponents,
              false,
              index !== 0,
              index !== secondColumn.length - 1
            )
          )}
          firstColumnWidth={
            columnsSize && columnsSize.length > 0 ? (columnsSize[0].trim() as TwoColumnsColumnSize) : '50%'
          }
          secondColumnWidth={
            columnsSize && columnsSize.length > 0 ? (columnsSize[1].trim() as TwoColumnsColumnSize) : '50%'
          }
          id={content?.contentfulid ?? undefined}
          verticalAlignment={(content?.verticalAlignment as TwoColumnsVerticalAlignment) ?? undefined}
          withContainer={withContainer}
          withSpaceTop={withSpaceTop}
          withSpaceBottom={withSpaceBottom}
        />
      );
    }

    case 'ContentfulQuote': {
      const content = item as ContentfulQuote;

      return (
        <Quote
          key={content?.id}
          body={content?.body?.body ?? ''}
          author={content?.author ?? ''}
          id={content?.contentfulid ?? undefined}
          withContainer={withContainer}
          withSpaceTop={withSpaceTop}
          withSpaceBottom={withSpaceBottom}
        />
      );
    }

    case 'ContentfulAccordions': {
      const content = item as ContentfulAccordions;
      const items = content?.accordion
        ? (content?.accordion.map((element) => {
            return {
              heading: element?.heading,
              content: element?.content,
            };
          }) as AccordionItem[])
        : [];

      return (
        <Accordions
          key={content.id}
          items={items}
          id={content?.contentfulid ?? undefined}
          withContainer={withContainer}
          withSpaceTop={withSpaceTop}
          withSpaceBottom={withSpaceBottom}
        />
      );
    }

    case 'ContentfulProjectsMap': {
      const content = item as ContentfulProjectsMap & { mapHeading: string };

      return <ProjectsMap id={content?.contentfulid ?? undefined} key={content?.id} heading={content.mapHeading} />;
    }

    case 'ContentfulFeatureCard': {
      const content = item as ComponentCardFeaturedFragment;
      const cardProps = mapContentFeaturedCardToProps(content);

      return <Card {...cardProps} variant={CardVariant.FEATURED} />;
    }

    case 'ContentfulPagesListingChosen': {
      const content = item as ComponentPagesListChosenFragment;
      const props = mapContentPagesListChosenToProps(content);

      return <PagesListChosen key={content?.id} {...props} />;
    }

    case 'ContentfulPagesListing': {
      const content = item as ComponentPagesListFragment;
      const props = mapContentPagesListToProps(content);

      return <PagesList key={content?.id} {...props} />;
    }

    case 'ContentfulPromoContent': {
      const content = item as ComponentPromoContentFragment;
      const props = mapContentPromoToProps(content);

      return (
        <PromoContentBlock
          key={content?.id}
          id={content?.contentfulid ?? undefined}
          withContainer={withContainer}
          withSpaceTop={withSpaceTop}
          withSpaceBottom={withSpaceBottom}
          {...props}
        />
      );
    }

    case 'ContentfulStatBlock': {
      const content = item as ContentfulStatBlock;
      return (
        <StatBlock
          id={content?.contentfulid ?? undefined}
          key={content?.id}
          statNumber={content.statNumber ?? ''}
          description={content.description ?? ''}
          withContainer={withContainer}
          withSpaceTop={withSpaceTop}
          withSpaceBottom={withSpaceBottom}
        />
      );
    }

    case 'ContentfulTilesRow': {
      const content = item as ContentfulTilesRow;
      const props = mapContentToTilesRowProps(content);

      return (
        <TextAndGraphicRow
          key={content?.id}
          {...props}
          withContainer={withContainer}
          withSpaceTop={withSpaceTop}
          withSpaceBottom={withSpaceBottom}
        />
      );
    }

    case 'ContentfulSpacer': {
      const content = item as ComponentSpacerFragment;
      const props = mapContentSpacerToProps(content);

      return <Spacer key={content?.id} {...props} />;
    }

    default:
      return null;
  }
}
