import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useEvent, useList } from 'react-use';
import { animateScroll as scroll } from 'react-scroll';
import { AnimationProps, motion } from 'framer-motion';
import { isBrowser } from '../../utils/system';
import Card from '../card/Card';
import Pagination from '../pagination/Pagination';
import { PagesListItem } from './props';
import { getElementTopOffset } from '../../utils/element';
import { maxMediaQuery } from '../grid';
import { useViewportSlideIn } from '../../hooks/viewport-slide-in';

type Props = {
  items: PagesListItem[];
  itemsLength: number;
  limit?: number;
  initialPage?: number;
  showPagination?: boolean;
  onChange(page: number, limit: number): void;
};

const ROW_HEIGHT = 10;
const SCROLL_OFFSET = 400;

const fadeInAnimationVariants: AnimationProps['variants'] = {
  visible: {
    opacity: 1,
  },
  hidden: {
    opacity: 0,
  },
};

const fadeInAnimationTransition: AnimationProps['transition'] = {
  opacity: { duration: 0.2 },
};

const PagesListGrid: FC<Props> = ({ items, itemsLength, limit = 12, initialPage, showPagination = true, onChange }) => {
  const theme = useTheme();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const gridContainerRef = useRef<HTMLDivElement | null>(null);
  const itemsRef = useRef<HTMLDivElement[]>([]);
  const gridInitializedRef = useRef(false);
  const [itemsSpan, { set: setItemsSpan }] = useList<string>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [displayedItems, setDisplayedItems] = useState<PagesListItem[]>([]);
  const [gridAnimationVariant, setGridAnimationVariant] = useState<'visible' | 'hidden'>('hidden');
  const [paginationContainerRef, paginationContainerControls] = useViewportSlideIn();
  const totalPages = Math.ceil(itemsLength / limit);

  const resizeGrid = useCallback(() => {
    if (isBrowser() === false || gridContainerRef.current == null) {
      return;
    }

    const newItemsSpan: string[] = [];

    itemsRef.current.forEach((itemElement) => {
      const itemRects = itemElement.getBoundingClientRect();
      const rowSpan = Math.ceil((itemRects.height + theme.grid.gutter) / (ROW_HEIGHT + theme.grid.gutter));

      newItemsSpan.push(`span ${rowSpan}`);
    });

    setItemsSpan(newItemsSpan);
  }, [theme, setItemsSpan]);

  const scrollToGridTop = useCallback(() => {
    const { current: containerElement } = containerRef;

    if (containerElement == null) {
      return;
    }

    const offsetTop = getElementTopOffset(containerElement);

    scroll.scrollTo(offsetTop - SCROLL_OFFSET, { duration: 300 });
  }, []);

  const pageChange = useCallback(
    (newPageNumber: number) => {
      scrollToGridTop();

      setTimeout(() => {
        setCurrentPage(newPageNumber);
      }, 500);
    },
    [setCurrentPage, scrollToGridTop]
  );

  useEffect(() => {
    if (initialPage != null) {
      setCurrentPage(initialPage);
    }
  }, [initialPage, setCurrentPage]);

  useEffect(() => {
    onChange(currentPage, limit);
  }, [currentPage, limit, onChange]);

  useEffect(() => {
    itemsRef.current = itemsRef.current.slice(0, displayedItems.length);

    resizeGrid();
  }, [displayedItems]);

  useEffect(() => {
    setGridAnimationVariant('hidden');

    if (gridInitializedRef.current === true) {
      setTimeout(() => {
        setDisplayedItems(items);
      }, 200);

      setTimeout(() => {
        setGridAnimationVariant('visible');
      }, 300);
    } else {
      setDisplayedItems(items);
      setGridAnimationVariant('visible');
      gridInitializedRef.current = true;
    }
  }, [items, setGridAnimationVariant, setDisplayedItems]);

  useEvent('resize', resizeGrid);

  return (
    <div ref={containerRef}>
      {displayedItems.length > 0 ? (
        <GridContainer
          ref={gridContainerRef}
          variants={fadeInAnimationVariants}
          transition={fadeInAnimationTransition}
          initial="hidden"
          animate={gridAnimationVariant}
        >
          {displayedItems.map((item, index) => (
            <CardContainer key={item.key} style={{ gridRowEnd: itemsSpan[index] ?? undefined }}>
              <div
                ref={(element) => {
                  itemsRef.current[index] = element as HTMLDivElement;
                }}
              >
                <Card {...item} />
              </div>
            </CardContainer>
          ))}
        </GridContainer>
      ) : (
        <NoResultsInformation
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          Sorry, that filter combination has no results.
          <br />
          Please try different criteria.
        </NoResultsInformation>
      )}

      {showPagination === true && (
        <PaginationSlideInContainer ref={paginationContainerRef} animate={paginationContainerControls}>
          <PaginationContainer
            variants={fadeInAnimationVariants}
            transition={fadeInAnimationTransition}
            initial="hidden"
            animate={displayedItems.length > 0 ? 'visible' : 'hidden'}
          >
            <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={pageChange} />
          </PaginationContainer>
        </PaginationSlideInContainer>
      )}
    </div>
  );
};

const GridContainer = styled(motion.div)`
  position: relative;
  display: grid;
  grid-gap: ${({ theme }) => theme.grid.gutter}px;
  grid-auto-rows: ${ROW_HEIGHT}px;
  grid-template-columns: repeat(3, minmax(250px, 1fr));

  ${maxMediaQuery.md} {
    grid-template-columns: repeat(2, minmax(250px, 1fr));
  }

  ${maxMediaQuery.sm} {
    grid-template-columns: 1fr;
  }
`;

const CardContainer = styled.div`
  width: 100%;
  padding: 0;
`;

const PaginationSlideInContainer = styled(motion.div)`
  margin-top: ${({ theme }) => theme.spacing.unit * 10}px;

  ${maxMediaQuery.md} {
    margin-top: ${({ theme }) => theme.spacing.unit * 5}px;
  }

  ${maxMediaQuery.sm} {
    margin-top: ${({ theme }) => theme.spacing.unit * 3}px;
  }
`;

const PaginationContainer = styled(motion.div)`
  display: flex;

  ${maxMediaQuery.sm} {
    flex-direction: column;
    align-items: center;
  }
`;

const NoResultsInformation = styled(motion.div)`
  width: 100%;
  text-align: center;
`;

export default PagesListGrid;
