export const SMALL_DESKTOP_NAVIGATION_HEIGHT = 90;
export const DESKTOP_NAVIGATION_HEIGHT = 128;
export const DESKTOP_NAVIGATION_OFFSET = DESKTOP_NAVIGATION_HEIGHT - SMALL_DESKTOP_NAVIGATION_HEIGHT;
export const DESKTOP_NAVIGATION_SAFE_OFFSET = DESKTOP_NAVIGATION_HEIGHT + SMALL_DESKTOP_NAVIGATION_HEIGHT;
export const DESKTOP_LOGO_HEIGHT = 89;
export const SMALL_DESKTOP_LOGO_HEIGHT = 70;
export const MOBILE_NAVIGATION_HEIGHT = 80;
export const MOBILE_NAVIGATION_SAFE_OFFSET = MOBILE_NAVIGATION_HEIGHT * 2;
export const MOBILE_LOGO_HEIGHT = 60;
export const LOGO_WIDTH = 181;
