import React, { FC, useMemo } from 'react';
import { graphql } from 'gatsby';
import { useLocation } from '@reach/router';
import { ComponentSeoDataFragment, ContentfulStoryConnection } from 'graphql-types';
import styled from '@emotion/styled';
import Seo from '../components/seo/Seo';
import { Col, GridContainer, maxMediaQuery, Row } from '../components/grid';
import Share from '../components/share/Share';
import { AvailablePageContentComponents, mapPageContent } from '../utils/page';
import Hero from '../components/hero/Hero';
import { HeroVariants } from '../components/hero/props';
import { OverlayVariant } from '../components/overlay/props';
import { SeoProps } from '../components/seo/props';
import { mapContentSeoDataToProps } from '../utils/content/seo';
import { mapContentPagesListChosenToPagesProps } from '../utils/content/pages-list-chosen';
import { AvailableContentCardComponents } from '../utils/content/card';
import { RelatedCardsTypes } from '../components/related-cards/props';
import RelatedCards from '../components/related-cards/RelatedCards';

const StoryTemplate: FC<{ data: { stories: ContentfulStoryConnection } }> = ({ data }) => {
  const storyData = data.stories.nodes[0];

  const location = useLocation();

  const content = useMemo<React.ReactNode>(() => {
    if (storyData?.content == null) {
      return null;
    }

    const storyContent = storyData.content.map((item) =>
      mapPageContent(item as AvailablePageContentComponents)
    ) as React.ReactNode;

    return storyContent;
  }, [storyData]);
  const relatedContent = useMemo<RelatedCardsTypes>(() => {
    const relatedProjects = (storyData?.relatedProjects as AvailableContentCardComponents[]) ?? undefined;
    const relatedStories = (storyData?.relatedStories as AvailableContentCardComponents[]) ?? undefined;
    const relatedPublications = (storyData?.relatedPublications as AvailableContentCardComponents[]) ?? undefined;
    const relatedArticles = (storyData?.relatedArticles as AvailableContentCardComponents[]) ?? undefined;
    const relatedPress = (storyData?.relatedPressReleases as AvailableContentCardComponents[]) ?? undefined;

    return {
      projects: mapContentPagesListChosenToPagesProps(relatedProjects),
      stories: mapContentPagesListChosenToPagesProps(relatedStories),
      publications: mapContentPagesListChosenToPagesProps(relatedPublications),
      articles: mapContentPagesListChosenToPagesProps(relatedArticles),
      press: mapContentPagesListChosenToPagesProps(relatedPress),
    };
  }, [storyData]);
  const seoData = useMemo<SeoProps>(() => {
    return mapContentSeoDataToProps(storyData?.seoData ?? ({} as ComponentSeoDataFragment), storyData?.title ?? '');
  }, [storyData]);

  return (
    <>
      <Seo {...seoData} />
      <Hero
        variant={HeroVariants.FULL}
        headingPrefix={storyData?.location?.name ?? undefined}
        heading={storyData?.title ?? ''}
        image={storyData?.featuredImage?.gatsbyImageData}
        imageAlt={storyData?.featuredImage?.description ?? undefined}
        overlayVariant={OverlayVariant.GRADIENT}
      />
      {content}
      <GridContainer>
        <Row>
          <StyledCol>
            <Share
              title={storyData?.title ?? ''}
              url={location.href}
              image={storyData?.featuredImage?.file?.url ?? undefined}
            />
          </StyledCol>
        </Row>
      </GridContainer>
      <RelatedCards {...relatedContent} context="stories" />
    </>
  );
};

export const query = graphql`
  query Stories($id: String!) {
    stories: allContentfulStory(filter: { contentful_id: { eq: $id } }) {
      nodes {
        id
        createdAt
        contentful_id
        excerpt {
          raw
        }
        slug
        tag {
          name
        }
        location {
          name
        }
        featuredImage {
          gatsbyImageData
          description
          file {
            url
          }
        }
        title
        content {
          ... on Node {
            ...ComponentMediaBlock
            ...ComponentMediaBlockSlider
            ...ComponentTextBlock
            ...ComponentTwoColumns
            ...ComponentAccordions
            ...ComponentProjectsMap
            ...ComponentCardFeatured
            ...ComponentPagesListChosen
            ...ComponentPagesList
            ...ComponentPromoContent
            ...ComponentStatBlock
            ...ComponentSpacer
            ...ComponentQuote
          }
        }
        relatedProjects {
          ... on Node {
            ...ComponentCardProject
          }
        }
        relatedPublications {
          ... on Node {
            ...ComponentCardPublication
          }
        }
        relatedStories {
          ... on Node {
            ...ComponentCardStory
          }
        }
        relatedArticles {
          ... on Node {
            ...ComponentCardArticle
          }
        }
        relatedPressReleases {
          ... on Node {
            ...ComponentCardPress
          }
        }
        seoData {
          ...ComponentSeoData
        }
      }
    }
  }
`;

const StyledCol = styled(Col)`
  width: 100%;
  padding: ${({ theme }) => theme.spacing.unit * 5}px 0px;
  display: flex;
  justify-content: center;

  ${maxMediaQuery.md} {
    padding: ${({ theme }) => theme.spacing.unit * 3}px 0px;
  }

  ${maxMediaQuery.sm} {
    padding: ${({ theme }) => theme.spacing.unit * 3}px 0px;
  }
`;

export default StoryTemplate;
