import React, { FC, useMemo } from 'react';
import styled from '@emotion/styled';
import { StaticImage } from 'gatsby-plugin-image';
import { graphql, useStaticQuery } from 'gatsby';
import { ComponentMenuLinkFragment, ContentfulButton, FooterDataQuery } from 'graphql-types';
import Button from '../button/Button';
import { Col, GridContainer, maxMediaQuery, Row } from '../grid';
import { ButtonVariants } from '../button/buttonProps';
import SimpleMenu from '../simple-menu/SimpleMenu';
import { mapContentMenuLinksToProps } from '../../utils/content/menu';
import Link from '../link/Link';

const Footer: FC = () => {
  const footerData = useStaticQuery<FooterDataQuery>(graphql`
    fragment ComponentFooter on ContentfulSiteFooter {
      id
      internal {
        type
      }
      menuTop {
        menuLinks {
          ... on Node {
            ...ComponentMenuLink
          }
        }
      }
      menuBottom {
        menuLinks {
          ... on Node {
            ...ComponentMenuLink
          }
        }
      }
      footerCopy {
        footerCopy
      }
      buttons {
        iconRight
        label
        targetUrl
        variant
        targetEntry {
          ... on Node {
            ...ArticleEntrySlug
            ...StoryEntrySlug
            ...ProjectEntrySlug
            ...PublicationEntrySlug
            ...PageEntrySlug
          }
        }
      }
    }

    query FooterData {
      allContentfulSiteFooter {
        nodes {
          ... on Node {
            ...ComponentFooter
          }
        }
      }
    }
  `);

  const footerNode = footerData?.allContentfulSiteFooter?.nodes?.[0];

  const footerButtons = useMemo(() => {
    const buttons = (footerData?.allContentfulSiteFooter?.nodes?.[0]?.buttons as ContentfulButton[]) ?? undefined;

    return buttons ?? [];
  }, [footerData]);

  const topMenuLinks = useMemo(() => {
    const menu =
      (footerData?.allContentfulSiteFooter?.nodes?.[0]?.menuTop?.menuLinks as ComponentMenuLinkFragment[]) ?? undefined;

    if (menu !== undefined) {
      return mapContentMenuLinksToProps(menu);
    }

    return [];
  }, [footerData]);

  const bottomMenuLinks = useMemo(() => {
    const menu =
      (footerData?.allContentfulSiteFooter?.nodes?.[0]?.menuBottom?.menuLinks as ComponentMenuLinkFragment[]) ??
      undefined;

    if (menu !== undefined) {
      return mapContentMenuLinksToProps(menu);
    }

    return [];
  }, [footerData]);

  return (
    <Container>
      <GridContainer>
        <TopRow>
          <Col sm={12} md={8}>
            <ButtonContainer>
              {footerButtons.map((item: ContentfulButton, index: number) => (
                <StyledButton
                  key={index}
                  href={item.targetUrl ?? undefined}
                  variant={
                    item.variant === ButtonVariants.Outlined
                      ? ButtonVariants.OutlinedWhite
                      : (item.variant as ButtonVariants)
                  }
                >
                  {item.label}
                </StyledButton>
              ))}
            </ButtonContainer>
          </Col>
          <Col sm={12} md={4}>
            <TopMenu aria-label="Footer menu top">
              <SimpleMenu items={topMenuLinks} />
            </TopMenu>
          </Col>
        </TopRow>
        <MiddleRow>
          <Col sm={12} md={7}>
            <IconContainer>
              <Link href="/" title="Go to the home page">
                <StaticImage
                  src="../../assets/comic-relief-gsk-logo-footer.png"
                  alt="Comic Relief and GSK"
                  layout="fixed"
                  placeholder="none"
                />
              </Link>
            </IconContainer>
          </Col>
          <Col sm={12} md={5}>
            <BottomNavigation aria-label="Footer menu bottom">
              <SimpleMenu items={bottomMenuLinks} />
            </BottomNavigation>
          </Col>
        </MiddleRow>
        <BottomRow>
          <Col sm={12} md={9}>
            <TextContainer>{footerNode?.footerCopy?.footerCopy || ''}</TextContainer>
          </Col>
        </BottomRow>
      </GridContainer>
    </Container>
  );
};

const Container = styled.footer`
  position: relative;
  min-height: 426px;
  width: 100%;
  background-color: ${(props) => props.theme.colors.primary.comicReliefDeepViolet};
  padding-bottom: ${(props) => props.theme.spacing.unit * 5}px;

  a {
    color: ${(props) => props.theme.colors.primary.white};
    display: inline-block;
    position: relative;
  }
`;

const StyledButton = styled(Button)`
  margin-bottom: ${(props) => props.theme.spacing.unit * 4}px;

  ${maxMediaQuery.sm} {
    width: 100%;
    text-align: center;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  ${maxMediaQuery.sm} {
    padding: 0px;
    flex-direction: column;
  }

  > a + a {
    margin-left: ${(props) => props.theme.spacing.unit * 5}px;
    ${maxMediaQuery.sm} {
      margin-left: 0px;
    }
  }
`;

const TopRow = styled(Row)`
  margin-bottom: ${(props) => props.theme.spacing.unit * 9}px;
  padding-top: ${(props) => props.theme.spacing.unit * 5}px;
  align-items: center;

  ${maxMediaQuery.sm} {
    margin-bottom: ${(props) => props.theme.spacing.unit * 5}px;
  }
`;

const BottomRow = styled(Row)`
  align-items: center;
`;

const MiddleRow = styled(Row)`
  align-items: center;
  ${maxMediaQuery.sm} {
    flex-direction: column-reverse;
  }
`;

const IconContainer = styled.div`
  width: 100%;
  height: 90px;
  display: flex;
  align-items: center;
  margin-bottom: ${(props) => props.theme.spacing.unit * 2}px;

  a {
    display: block;
    height: 100%;

    > div {
      height: 100% !important;
      width: auto !important;

      img {
        position: relative;
        display: block;
        height: 100%;
        width: auto;
      }
    }
  }
`;

const TextContainer = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: ${(props) => props.theme.colors.primary.white};
`;

const StyledNavigation = styled.nav`
  a {
    &:after {
      content: '';
      position: absolute;
      width: 100%;
      transform: scaleX(0);
      height: 2px;
      bottom: 0;
      left: 0px;
      background-color: ${(props) => props.theme.colors.primary.white};
      transform-origin: bottom right;
      transition: transform 0.25s ease-out;
    }

    &:hover:after {
      transform: scaleX(1);
      transform-origin: bottom left;
    }
  }
`;

const TopMenu = styled(StyledNavigation)`
  > ul {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding-inline-start: 0px;
    margin-block-start: 0px;
    margin-block-end: 0px;

    ${maxMediaQuery.sm} {
      justify-content: flex-start;
    }

    > li + li {
      border-left: 1px solid ${(props) => props.theme.colors.primary.white};
      padding-left: ${(props) => props.theme.spacing.unit * 5}px;
    }

    > li {
      font-style: normal;
      font-weight: 700;
      display: flex;
      align-items: center;
      padding-right: ${(props) => props.theme.spacing.unit * 5}px;

      &:last-child {
        padding-right: 0px;
      }
    }
  }
`;

const BottomNavigation = styled(StyledNavigation)`
  > ul {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    list-style: none;
    margin: 0;
    padding: 0;

    ${maxMediaQuery.sm} {
      justify-content: flex-start;
      flex-direction: column;
      margin-bottom: 40px;
    }

    > li + li {
      margin-left: ${(props) => props.theme.spacing.unit * 5}px;

      ${maxMediaQuery.sm} {
        margin-left: 0px;
        margin-top: ${(props) => props.theme.spacing.unit * 1}px;
      }
    }

    > li {
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 18px;
    }
  }
`;

export default Footer;
