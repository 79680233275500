import React, { FC, useCallback, useState } from 'react';
import { componentQuery } from './query';
import { MediaBlockProps, MediaBlockVariant } from './props';
import MediaBlockFullWidth from './MediaBlockFullWidth';
import MediaBlockContainerWidth from './MediaBlockContainerWidth';
import { OverlayAlignmentVariant, OverlayVariant } from '../overlay/props';
import Overlay from '../overlay/Overlay';

type Props = {
  variant?: MediaBlockVariant;
} & MediaBlockProps;

const variants: Record<MediaBlockVariant, React.FC<MediaBlockProps>> = {
  [MediaBlockVariant.FULL_WIDTH]: MediaBlockFullWidth,
  [MediaBlockVariant.CONTAINER_WIDTH]: MediaBlockContainerWidth,
};

const MediaBlock: FC<Props> = ({ variant = MediaBlockVariant.FULL_WIDTH, videoUrl, ...componentProps }) => {
  const [videoOpen, setVideoOpen] = useState(false);

  const handlePlayVideo = useCallback(() => {
    setVideoOpen(true);
  }, [setVideoOpen]);

  const Component = variants[variant];
  const {
    alignment = OverlayAlignmentVariant.LEFT,
    overlayVariant = OverlayVariant.NONE,
    heading,
    excerpt,
    buttonText,
    button,
    withTransition = true,
  } = componentProps;

  return (
    <Component {...componentProps} withTransition={withTransition} videoUrl={videoUrl} videoOpen={videoOpen}>
      <Overlay
        variant={overlayVariant}
        heading={heading}
        excerpt={excerpt}
        alignment={alignment}
        button={button}
        buttonText={buttonText}
        fluidContainer={variant === MediaBlockVariant.CONTAINER_WIDTH}
        withVideo={videoUrl != null}
        onPlayVideo={handlePlayVideo}
      />
    </Component>
  );
};

export const query = componentQuery;

export default MediaBlock;
