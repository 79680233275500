import styled from '@emotion/styled';
import { AnimationProps, motion } from 'framer-motion';
import React, { forwardRef } from 'react';
import { useBreakpoint } from '../../hooks/breakpoint';
import { MenuLink } from '../../models/content/menu-link';
import { GridContainer, maxMediaQuery } from '../grid';
import NavigationMobileItem from './NavigationMobileItem';
import NavigationToggle from './NavigationToggle';

type Props = {
  isOpen: boolean;
  menuLinks: MenuLink[];
  navigationHeight: number;
  onClose(): void;
};

const DESKTOP_LIST_PADDING = 70;
const MOBILE_LIST_PADDING = 30;

const containerAnimationVariants: AnimationProps['variants'] = {
  closed: {
    x: '100%',
    opacity: 0,
    transitionEnd: {
      visibility: 'hidden',
    },
  },
  opened: {
    x: 0,
    opacity: 1,
    visibility: 'visible',
  },
};

const containerAnimationTransition: AnimationProps['transition'] = {
  x: { type: 'spring', stiffness: 300, damping: 50, duration: 0.3 },
  opacity: { duration: 0.2 },
};

const NavigationMobile = forwardRef<HTMLDivElement, Props>(({ isOpen, menuLinks, navigationHeight, onClose }, ref) => {
  const { md: breakpointMd } = useBreakpoint();
  const variant = isOpen === true ? 'opened' : 'closed';

  return (
    <Container
      ref={ref}
      animate={variant}
      variants={containerAnimationVariants}
      initial="closed"
      transition={containerAnimationTransition}
    >
      <ScrollContainer>
        <InnerContainer
          style={{
            paddingTop: navigationHeight + (breakpointMd === false ? MOBILE_LIST_PADDING : DESKTOP_LIST_PADDING),
          }}
        >
          {menuLinks.map((menuLink) => (
            <li key={menuLink.key}>
              <NavigationMobileItem {...menuLink} />
            </li>
          ))}
        </InnerContainer>
      </ScrollContainer>
      <ToggleContainer>
        <GridContainer>
          <ToggleInnerContainer style={{ height: breakpointMd === false ? navigationHeight : 90 }}>
            <NavigationToggle isOpen={isOpen} light onToggle={onClose} />
          </ToggleInnerContainer>
        </GridContainer>
      </ToggleContainer>
    </Container>
  );
});

const Container = styled(motion.div)`
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  max-width: 475px;
  background-color: ${({ theme }) => theme.colors.primary.comicReliefDeepViolet};
`;

const ToggleContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;

  ${GridContainer} {
    position: relative;
    height: 1px;
  }
`;

const ToggleInnerContainer = styled.div`
  position: absolute;
  top: 0;
  right: -8px;
  display: flex;

  ${maxMediaQuery.sm} {
    right: 0;
  }
`;

const ScrollContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

const InnerContainer = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0;
  padding: ${DESKTOP_LIST_PADDING}px;

  > li {
    position: relative;
    display: block;

    + li {
      margin-top: ${({ theme }) => theme.spacing.unit}px;
    }
  }

  ${maxMediaQuery.sm} {
    padding: ${MOBILE_LIST_PADDING}px;
  }
`;

export default NavigationMobile;
