import React, { FC, useCallback } from 'react';
import styled from '@emotion/styled';
import Icon from '../icon/Icon';

type SwitchProps = {
  onChange(switched: boolean): void;
  value?: boolean;
};

const Switch: FC<SwitchProps> = ({ onChange, value = false, ...props }) => {
  const toggle = useCallback(() => {
    onChange(!value);
  }, [onChange, value]);

  return (
    <SwitchElement {...props} tabIndex={0} className={value ? 'btn-restart switched' : 'btn-restart'} onClick={toggle}>
      <TickMark iconType="tick-mark" />
      <XMark iconType="x-mark" />
    </SwitchElement>
  );
};

const TickMark = styled(Icon)`
  position: absolute;
  left: 7px;
  top: 11px;
  fill: ${({ theme }) => theme.colors.primary.white};
`;

const XMark = styled(Icon)`
  position: absolute;
  right: 8px;
  top: 10px;
  fill: ${({ theme }) => theme.colors.primary.white};
`;

const SwitchElement = styled.button`
  display: block;
  width: 52px;
  height: 32px;
  position: relative;
  cursor: pointer;
  user-select: none;
  background: ${({ theme }) => theme.colors.primary.comicReliefDeepViolet};
  border-radius: 36.5px;
  padding: 2px;
  transition: all 0.4s ease;
  border: 1px solid ${({ theme }) => theme.colors.primary.white};
  overflow: hidden;

  &:after {
    position: relative;
    display: block;
    content: '';
    width: 26px;
    height: 26px;
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.primary.white};
    transition: left 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275), padding 0.3s ease, margin 0.3s ease;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15), 0px 1px 1px rgba(0, 0, 0, 0.16), 0px 3px 1px rgba(0, 0, 0, 0.1);
    left: 0;
  }

  &.switched {
    background: ${({ theme }) => theme.colors.primary.comicReliefRed};

    &:active {
      box-shadow: none;
    }

    &:after {
      left: 20px;
    }
  }
`;

export default Switch;
