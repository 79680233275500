import React, { FC, useCallback } from 'react';
import { useEvent, useMap } from 'react-use';
import StateContext, { StateContextType } from './StateContext';

const StateProvider: FC = ({ children }) => {
  const [state, { set }] = useMap<StateContextType>({ windowLoaded: false });

  const handleLoad = useCallback(() => {
    set('windowLoaded', true);
  }, [set]);

  useEvent('load', handleLoad);

  return <StateContext.Provider value={state}>{children}</StateContext.Provider>;
};

export default StateProvider;
