import React, { FC, useCallback } from 'react';
import styled from '@emotion/styled';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { Col, GridContainer, maxMediaQuery, Row } from '../grid';
import Button from '../button/Button';
import { ButtonProps } from '../button/buttonProps';
import { ConditionalWrapper } from '../wrapper/ConditionalWrapper';

export interface PromoContentProps {
  heading: string;
  body: React.ReactNode;
  button?: ButtonProps;
  image?: IGatsbyImageData;
  imageAlt?: string;
  buttonLabel: string;
  imageAligment?: 'left' | 'right';
  id?: string;
  withContainer?: boolean;
  withSpaceTop?: boolean;
  withSpaceBottom?: boolean;
}

const PromoContentBlock: FC<PromoContentProps> = ({
  id,
  heading,
  image,
  imageAlt,
  body,
  button,
  buttonLabel,
  imageAligment = 'left',
  withContainer = true,
  withSpaceTop = true,
  withSpaceBottom = true,
}) => {
  const handleWithContainer = useCallback(
    (children: React.ReactNode) => <GridContainer id={id}>{children}</GridContainer>,
    [withContainer, id]
  );

  return (
    <ConditionalWrapper condition={withContainer === true} wrapperPositive={handleWithContainer}>
      <Container
        id={withContainer === false ? id : undefined}
        withSpaceTop={withSpaceTop}
        withSpaceBottom={withSpaceBottom}
      >
        <StyledRow imageAligment={imageAligment}>
          <StyledCol md={12} lg={6}>
            <Body imageAligment={imageAligment}>
              <h2>{heading}</h2>
              <Description>{body}</Description>
              {button != null && buttonLabel != null && <Button {...button}>{buttonLabel}</Button>}
            </Body>
          </StyledCol>
          <Col md={12} lg={6}>
            {image && <ProjectImage image={image} alt={imageAlt ?? ''} />}
          </Col>
        </StyledRow>
      </Container>
    </ConditionalWrapper>
  );
};

const Container = styled.div<{ withSpaceTop: boolean; withSpaceBottom: boolean }>`
  width: 100%;
  position: relative;
  padding: 0px;
  margin-top: ${({ theme, withSpaceTop }) => (withSpaceTop === true ? `${theme.spacing.unit * 10}px` : 0)};
  margin-bottom: ${({ theme, withSpaceBottom }) => (withSpaceBottom === true ? `${theme.spacing.unit * 10}px` : 0)};

  a {
    display: inline-flex;
  }

  ${maxMediaQuery.md} {
    margin-top: ${({ theme, withSpaceTop }) => (withSpaceTop === true ? `${theme.spacing.unit * 5}px` : 0)};
    margin-bottom: ${({ theme, withSpaceBottom }) => (withSpaceBottom === true ? `${theme.spacing.unit * 5}px` : 0)};
  }
`;

const Body = styled.div<{ imageAligment: PromoContentProps['imageAligment'] }>`
  padding-right: ${({ imageAligment, theme }) => (imageAligment === 'right' ? `${theme.spacing.unit * 5}px` : '0')};
  padding-left: ${({ imageAligment, theme }) => (imageAligment === 'right' ? '0' : `${theme.spacing.unit * 5}px`)};

  ${maxMediaQuery.md} {
    padding-right: 0;
    padding-left: 0;
  }
`;

const Description = styled.div`
  margin: ${({ theme }) => theme.spacing.unit * 3}px 0px;

  a:not(.btn) {
    font-weight: 700;
    border-bottom: 1px solid transparent;
    transition: all 0.2s ease;

    &:hover {
      border-bottom-color: ${({ theme }) => theme.colors.primary.copy};
    }
  }
`;

const StyledRow = styled(Row)<{ imageAligment: PromoContentProps['imageAligment'] }>`
  align-items: center;
  flex-direction: ${({ imageAligment }) => (imageAligment === 'right' ? 'row' : 'row-reverse')};

  ${maxMediaQuery.md} {
    flex-direction: column-reverse;
  }
`;

const StyledCol = styled(Col)`
  height: 100%;
`;

const ProjectImage = styled(GatsbyImage)`
  width: 100%;
  height: 600px;
  border-radius: 24px;
  mask-image: -webkit-radial-gradient(white, black);

  ${maxMediaQuery.lg} {
    height: 500px;
  }

  ${maxMediaQuery.md} {
    height: 400px;
    margin-bottom: ${({ theme }) => theme.spacing.unit * 4}px;
  }

  ${maxMediaQuery.sm} {
    height: 300px;
    margin-bottom: ${({ theme }) => theme.spacing.unit * 2.5}px;
  }
`;

export default PromoContentBlock;
