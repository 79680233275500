import React, { FC, SVGAttributes } from 'react';
import iconTypes, { IconTypes } from './iconTypes';

const DEFAULT_ICON_WIDTH = undefined;
const DEFAULT_ICON_HEIGHT = undefined;
const DEFAULT_ICON_FILL = 'currentColor';

export type IconProps = {
  iconType: IconTypes;
  withStroke?: boolean;
} & SVGAttributes<SVGElement>;

const Icon: FC<IconProps> = ({ iconType, withStroke, fill, width, height, ...props }) => {
  const Component = iconTypes[iconType]?.icon;
  const defaultWidth = iconTypes[iconType]?.defaultWidth || DEFAULT_ICON_WIDTH;
  const defaultHeight = iconTypes[iconType]?.defaultHeight || DEFAULT_ICON_HEIGHT;
  const defaultFill = iconTypes[iconType]?.defaultFill || DEFAULT_ICON_FILL;

  return (
    <Component
      fill={fill ?? defaultFill}
      stroke={withStroke ? DEFAULT_ICON_FILL : undefined}
      width={width ?? defaultWidth}
      height={height ?? defaultHeight}
      {...props}
    />
  );
};

export default Icon;
