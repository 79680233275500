import styled from '@emotion/styled';
import { AnimationProps, motion } from 'framer-motion';
import { ContentfulRichTextGatsbyReference, RenderRichTextData } from 'gatsby-source-contentful/rich-text';
import React, { FC, ReactNode, useCallback } from 'react';
import mapHeading from '../../utils/hero';
import Button from '../button/Button';
import { ButtonProps, ButtonVariants } from '../button/buttonProps';
import { Col, GridContainer, maxMediaQuery, Row } from '../grid';
import { ConditionalWrapper } from '../wrapper/ConditionalWrapper';
import OverlayGradient from './OverlayGradient';
import OverlayBox from './OverlayBox';
import OverlayDeepViolet from './OverlayDeepViolet';
import OverlayRed from './OverlayRed';
import OverlayNone from './OverlayNone';
import OverlayWhite from './OverlayWhite';
import { OverlayAlignmentVariant, OverlayVariant, OverlayProps } from './props';
import Icon from '../icon/Icon';
import { mapMediaBlockHeadingRichText } from '../../utils/rich-text/media-block-heading';
import { mapTextBlockRichText } from '../../utils/rich-text/text-block';

type Props = {
  variant?: OverlayVariant;
  headingPrefix?: string;
  heading?: RenderRichTextData<ContentfulRichTextGatsbyReference> | string;
  excerpt?: RenderRichTextData<ContentfulRichTextGatsbyReference> | null | undefined;
  button?: ButtonProps;
  buttonText?: string;
  fluidContainer?: boolean;
  context?: 'hero' | 'image';
  withVideo?: boolean;
  onPlayVideo?(): void;
} & OverlayProps;

const variants: Record<OverlayVariant, React.FC<OverlayProps>> = {
  [OverlayVariant.NONE]: OverlayNone,
  [OverlayVariant.RED]: OverlayRed,
  [OverlayVariant.DEEP_VIOLET]: OverlayDeepViolet,
  [OverlayVariant.WHITE]: OverlayWhite,
  [OverlayVariant.GRADIENT]: OverlayGradient,
  [OverlayVariant.BLACK_GRADIENT]: OverlayGradient,
};

const contentItemAnimationVariants: AnimationProps['variants'] = {
  enter: {
    y: 50,
    opacity: 0,
  },
  active: {
    y: 0,
    opacity: 1,
  },
  exit: {
    y: 50,
    opacity: 0,
  },
};

const contentItemTransition: AnimationProps['transition'] = {
  y: { type: 'spring', stiffness: 300, damping: 30, duration: 0.5, delay: 0.3 },
  opacity: { duration: 0.2, delay: 0.3 },
};

const boxAnimationVariants: AnimationProps['variants'] = {
  enter: {
    opacity: 0,
  },
  active: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
};

const boxTransition: AnimationProps['transition'] = {
  opacity: { duration: 0.2, delay: 0.3 },
};

const Overlay: FC<Props> = ({
  variant = OverlayVariant.NONE,
  headingPrefix,
  heading,
  excerpt,
  alignment = OverlayAlignmentVariant.LEFT,
  button,
  buttonText,
  fluidContainer = false,
  context = 'image',
  withVideo = false,
  onPlayVideo,
}) => {
  const Component = variants[variant];
  const Heading = context === 'image' ? HeadingMediaBlock : HeadingH1;

  const boxContainer = useCallback(
    (children: ReactNode): JSX.Element => {
      return (
        <OverlayBox
          variants={boxAnimationVariants}
          transition={boxTransition}
          initial="enter"
          animate="active"
          exit="exit"
          isSide={false}
        >
          {children}
        </OverlayBox>
      );
    },
    [alignment]
  );

  const sideWrapper = useCallback(
    (children: ReactNode): JSX.Element => {
      return (
        <Col
          md={{ span: 8, offset: alignment === OverlayAlignmentVariant.RIGHT ? 4 : 0 }}
          lg={{ span: 6, offset: alignment === OverlayAlignmentVariant.RIGHT ? 6 : 0 }}
        >
          {variant === OverlayVariant.NONE ||
          variant === OverlayVariant.GRADIENT ||
          variant === OverlayVariant.BLACK_GRADIENT ? (
            children
          ) : (
            <OverlayBox
              variants={boxAnimationVariants}
              transition={boxTransition}
              initial="enter"
              animate="active"
              exit="exit"
              isSide
            >
              {children}
            </OverlayBox>
          )}
        </Col>
      );
    },
    [alignment, variant]
  );

  const centerWrapper = useCallback(
    (children: ReactNode): JSX.Element => {
      return <Col md={{ span: 10, offset: 1 }}>{children}</Col>;
    },
    [alignment, variant]
  );

  return headingPrefix != null ||
    heading != null ||
    excerpt != null ||
    withVideo === true ||
    (button != null && buttonText != null) ? (
    <Component alignment={alignment}>
      <ConditionalWrapper
        condition={
          variant !== OverlayVariant.NONE &&
          (alignment === OverlayAlignmentVariant.CENTER ||
            variant === OverlayVariant.GRADIENT ||
            variant === OverlayVariant.BLACK_GRADIENT)
        }
        wrapperPositive={boxContainer}
      >
        <FullHeightGridContainer fluid={fluidContainer}>
          <Row>
            <ConditionalWrapper
              condition={alignment !== OverlayAlignmentVariant.CENTER}
              wrapperPositive={sideWrapper}
              wrapperNegative={centerWrapper}
            >
              <Content alignment={alignment}>
                {headingPrefix != null && (
                  <HeadingPrefix
                    variants={contentItemAnimationVariants}
                    transition={contentItemTransition}
                    initial="enter"
                    animate="active"
                    exit="exit"
                  >
                    {headingPrefix}
                  </HeadingPrefix>
                )}
                {heading != null && (
                  <Heading
                    variants={contentItemAnimationVariants}
                    transition={contentItemTransition}
                    initial="enter"
                    animate="active"
                    exit="exit"
                  >
                    {typeof heading === 'string'
                      ? heading
                      : context === 'image'
                      ? mapMediaBlockHeadingRichText(heading)
                      : mapHeading(heading)}
                  </Heading>
                )}
                {excerpt != null && (
                  <Excerpt
                    variants={contentItemAnimationVariants}
                    transition={contentItemTransition}
                    initial="enter"
                    animate="active"
                    exit="exit"
                  >
                    {mapTextBlockRichText(excerpt)}
                  </Excerpt>
                )}
                {withVideo === false && button != null && buttonText != null && (
                  <ButtonContainer
                    variants={contentItemAnimationVariants}
                    transition={contentItemTransition}
                    initial="enter"
                    animate="active"
                    exit="exit"
                  >
                    <Button
                      {...button}
                      variant={variant === OverlayVariant.RED ? ButtonVariants.SolidWhite : ButtonVariants.Solid}
                    >
                      {buttonText}
                    </Button>
                  </ButtonContainer>
                )}
                {withVideo === true && (
                  <VideoToggleContainer
                    variants={contentItemAnimationVariants}
                    transition={contentItemTransition}
                    initial="enter"
                    animate="active"
                    exit="exit"
                  >
                    <VideoToggle
                      type="button"
                      onClick={onPlayVideo}
                      className="btn-restart"
                      title="Play video"
                      variant={variant}
                    >
                      <Icon iconType="video-play" />
                    </VideoToggle>
                  </VideoToggleContainer>
                )}
              </Content>
            </ConditionalWrapper>
          </Row>
        </FullHeightGridContainer>
      </ConditionalWrapper>
    </Component>
  ) : null;
};

const Content = styled.div<{ alignment: OverlayAlignmentVariant }>`
  display: flex;
  flex-direction: column;
  align-items: ${({ alignment }) => {
    if (alignment === OverlayAlignmentVariant.LEFT || alignment === OverlayAlignmentVariant.RIGHT) {
      return 'flex-start';
    }

    return 'center';
  }};
  justify-content: center;
  height: 100%;
  text-align: ${({ alignment }) => {
    if (alignment === OverlayAlignmentVariant.LEFT || alignment === OverlayAlignmentVariant.RIGHT) {
      return 'left';
    }

    return 'center';
  }};
`;

const FullHeightGridContainer = styled(GridContainer)`
  display: flex;
  flex-grow: 1;
  align-items: center;
  height: 100%;
`;

const HeadingPrefix = styled(motion.span)`
  display: block;
  width: 100%;
  margin: 0;
  margin-bottom: ${({ theme }) => theme.spacing.unit * 3}px;
  font-size: ${({ theme }) => theme.typography.h5.fontSize};
  font-weight: ${({ theme }) => theme.typography.h5.fontWeight};
  font-family: ${({ theme }) => theme.typography.h5.fontFamily};
  line-height: ${({ theme }) => theme.typography.h5.lineHeight};
  text-transform: uppercase;
  word-break: break-word;
`;

const HeadingH1 = styled(motion.h1)`
  width: 100%;
  margin: 0;
  word-break: break-word;
`;

const HeadingMediaBlock = styled(motion.div)`
  width: 100%;

  h2,
  h3,
  h4 {
    width: 100%;
    margin: 0;
    word-break: break-word;
  }
`;

const Excerpt = styled(motion.div)`
  width: 100%;
  margin-top: ${({ theme }) => `${theme.spacing.unit * 3}px`};
  font-size: ${({ theme }) => theme.typography.titleBody.fontSize};
  font-weight: ${({ theme }) => theme.typography.titleBody.fontWeight};
  font-family: ${({ theme }) => theme.typography.titleBody.fontFamily};
  line-height: ${({ theme }) => theme.typography.titleBody.lineHeight};
  word-break: break-word;

  p {
    margin: 0;
  }
`;

const ButtonContainer = styled(motion.div)`
  margin-top: ${({ theme }) => `${theme.spacing.unit * 4}px`};

  ${maxMediaQuery.sm} {
    width: 100%;

    button,
    a {
      width: 100%;
    }
  }
`;

const VideoToggleContainer = styled(motion.div)`
  margin-top: ${({ theme }) => `${theme.spacing.unit}px`};
`;

const VideoToggle = styled.button<{ variant: OverlayVariant }>`
  width: 120px;
  height: 120px;
  padding: 20px;
  cursor: pointer;

  > svg {
    display: block;
    width: 80px;
    height: 80px;
    fill: ${({ theme, variant }) =>
      variant === OverlayVariant.RED ? theme.colors.primary.white : theme.colors.primary.comicReliefRed};
    transition: fill 0.3s ease;
  }

  &:hover {
    > svg {
      fill: ${({ theme, variant }) =>
        variant === OverlayVariant.DEEP_VIOLET
          ? theme.colors.primary.white
          : theme.colors.primary.comicReliefDeepViolet};
    }
  }
`;

export default Overlay;
