import { AnimationControls, useAnimation } from 'framer-motion';
import { useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';

type Ref = (node?: Element | null | undefined) => void;

export function useViewportSlideIn(enabled = true): [Ref, AnimationControls] {
  const animationTriggeredRef = useRef(false);
  const [ref, inView] = useInView({ triggerOnce: true });
  const controls = useAnimation();

  useEffect(() => {
    if (animationTriggeredRef.current === false && enabled === true) {
      controls.set({ opacity: 0, y: 50 });
    } else if (enabled === false) {
      controls.set({ opacity: 1, y: 0 });
    }
  }, [controls, enabled]);

  useEffect(() => {
    if (animationTriggeredRef.current === false && inView === true) {
      animationTriggeredRef.current = true;
      controls.start({ opacity: 1, y: 0, transition: { duration: 0.5, delay: 0.2 } });
    }
  }, [inView, controls]);

  return [ref, controls];
}
