import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { useCookies } from 'react-cookie';
import { ContentfulSiteFooter } from 'graphql-types';
import { graphql, useStaticQuery } from 'gatsby';
import { useLocation } from '@reach/router';
import { addYears } from 'date-fns';
import Button from '../button/Button';
import { ButtonVariants } from '../button/buttonProps';
import Modal from '../modal/Modal';
import Switch from '../switch/Switch';
import SimpleMenu from '../simple-menu/SimpleMenu';
import { getFinalUri, mapContentMenuLinksToProps } from '../../utils/content/menu';
import { CONSENT_COOKIE_NAME } from './constants';
import { availableCookies, cookieCategories, defaultServicesData } from './cookiesData';
import { ConsentData, ServiceConsent } from './types';
import { ComponentMenuLinkFragment } from '../../../graphql-types';
import { maxMediaQuery } from '../grid/mediaquery';
import { isBrowser } from '../../utils/system';
import { CookieCategories } from './enums';

enum AcceptSettings {
  acceptAll = 'accept all',
  disallowAll = 'disallow all',
}

const CookieConsentManager: FC = () => {
  const location = useLocation();
  const policyMenuData: ContentfulSiteFooter = useStaticQuery<{ contentfulSiteFooter: ContentfulSiteFooter }>(graphql`
    query policyMenuData {
      contentfulSiteFooter {
        menuBottom {
          menuLinks {
            label
            linkUrl
            targetEntry {
              ... on ContentfulPage {
                slug
              }
            }
          }
        }
      }
    }
  `).contentfulSiteFooter;

  const menuLinks = useMemo(() => {
    return mapContentMenuLinksToProps(policyMenuData?.menuBottom?.menuLinks as ComponentMenuLinkFragment[]);
  }, [policyMenuData]);

  const isPolicyDetailsPage = useMemo((): boolean => {
    const policyDetailsLinks = policyMenuData?.menuBottom?.menuLinks?.map((link) => {
      if (!link) {
        return null;
      }
      return link?.linkUrl ? link.linkUrl : getFinalUri(link);
    });
    return policyDetailsLinks?.includes(location.pathname) ?? false;
  }, [policyMenuData, location]);

  const [cookies, setCookie] = useCookies([CONSENT_COOKIE_NAME]);

  const [mainModalVisibility, setMainModalVisibility] = useState(false);
  const [settingsModalVisibility, setSettingsModalVisibility] = useState(false);

  const [servicesSettings, setServicesSettings] = useState(defaultServicesData);

  const setConsentFlag = (cookie: ConsentData, option?: AcceptSettings) => {
    if (option) {
      switch (option) {
        case AcceptSettings.acceptAll:
          return true;
        case AcceptSettings.disallowAll:
          return false;
        default:
          return cookie.accepted;
      }
    } else {
      return cookie.accepted;
    }
  };

  const prepareConsentCookieValue = useCallback(
    (option?: AcceptSettings): Array<ServiceConsent> => {
      return servicesSettings.map((cookie) => ({
        name: cookie.service,
        consent: setConsentFlag(cookie, option),
      }));
    },
    [servicesSettings, setServicesSettings]
  );

  const setServiceConsent = useCallback(
    (serviceName: string, accept: boolean) => {
      setServicesSettings((currentServices) => {
        const data = currentServices.map((service) => {
          if (service.service === serviceName) {
            return { ...service, accepted: accept };
          }
          return service;
        });
        return data;
      });
    },
    [setServicesSettings, prepareConsentCookieValue, servicesSettings]
  );

  const showSettingsModal = useCallback(() => {
    setMainModalVisibility(false);
    setSettingsModalVisibility(true);
  }, [mainModalVisibility, settingsModalVisibility]);

  const saveCookiePreferences = useCallback(
    (options?: AcceptSettings) => {
      setCookie(CONSENT_COOKIE_NAME, JSON.stringify(prepareConsentCookieValue(options)), {
        path: '/',
        expires: addYears(new Date(), 1),
      });
      setMainModalVisibility(false);
      setSettingsModalVisibility(false);
    },
    [mainModalVisibility, settingsModalVisibility, setServicesSettings, prepareConsentCookieValue]
  );

  const renderCookieSettings = useMemo(() => {
    return cookieCategories.map((category) => {
      return (
        <React.Fragment key={category}>
          <CookieCategory>{category}</CookieCategory>
          {availableCookies
            .filter((cookie) => cookie.category === category)
            .map((cookie) => {
              const isAccepted = servicesSettings.find((service) => service.name === cookie.name)?.accepted;
              return (
                <CookieSelection key={cookie.name}>
                  <div>
                    <span>{cookie.name}</span>
                    {category !== CookieCategories.essential && (
                      <Switch
                        aria-label={`toggle ${isAccepted ? 'off' : 'on'} consent for ${cookie.name}`}
                        onChange={(isSwitched) => setServiceConsent(cookie.relatedService, isSwitched)}
                        value={isAccepted}
                      />
                    )}
                  </div>
                  <p>{cookie.description}</p>
                </CookieSelection>
              );
            })}
        </React.Fragment>
      );
    });
  }, [setServiceConsent, servicesSettings]);

  useEffect(() => {
    if (isBrowser() === true) {
      setMainModalVisibility(true);
    }
  }, [setMainModalVisibility]);

  return !cookies[CONSENT_COOKIE_NAME] && !isPolicyDetailsPage ? (
    <Modal visible={mainModalVisibility || settingsModalVisibility}>
      {mainModalVisibility && (
        <div>
          <h3>HOW DO YOU LIKE YOUR COOKIES?</h3>
          <ModalCopy>
            <p>
              We use clever little cookies to get to know how you use our website. It’s just so we can give you a better
              browsing experience and show you things that are more relevant, like advertising, social media features,
              and content.
            </p>
            <p>
              We don’t serve them with milk, but our yummy little cookies could help you have a better time on our
              website. Choose below how you want your cookies.
            </p>
          </ModalCopy>
          <LinksContainer>
            <SimpleMenu items={menuLinks} />
          </LinksContainer>
          <ModalCopy>
            <ButtonsContainer>
              <Button onClick={showSettingsModal} variant={ButtonVariants.Outlined}>
                I’m watching my cookie intake
              </Button>
              <Button
                onClick={() => {
                  saveCookiePreferences(AcceptSettings.acceptAll);
                }}
                variant={ButtonVariants.Solid}
              >
                Yummy, I love cookies!
              </Button>
            </ButtonsContainer>
          </ModalCopy>
        </div>
      )}
      {settingsModalVisibility && (
        <div>
          <h3>Cookie settings</h3>
          <div>
            We strive to cut our cookies to your preference. Some of them you can turn off, and some of them you can’t
            (mostly because they are necessary for the website to function). Feel free to tell us what you think and
            then they can be your default settings when you visit our site.
          </div>
          <LinksContainer aria-label="Read more about privacy">
            <SimpleMenu items={menuLinks} />
          </LinksContainer>
          <CookieSettings>{renderCookieSettings}</CookieSettings>
          <ButtonsContainer>
            <Button
              onClick={() => {
                saveCookiePreferences();
              }}
              variant={ButtonVariants.Outlined}
            >
              Save
            </Button>
            <Button
              onClick={() => {
                saveCookiePreferences(AcceptSettings.disallowAll);
              }}
              variant={ButtonVariants.Outlined}
            >
              No cookies for me
            </Button>
            <Button
              onClick={() => {
                saveCookiePreferences();
              }}
              variant={ButtonVariants.Solid}
            >
              Yummy, I love cookies!
            </Button>
          </ButtonsContainer>
        </div>
      )}
    </Modal>
  ) : (
    <></>
  );
};

const ModalCopy = styled.div`
  p {
    margin-bottom: ${({ theme }) => theme.spacing.unit * 4}px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;

  button {
    margin-left: ${({ theme }) => theme.spacing.unit * 2}px;
    &:first-of-type {
      margin-left: 0;
    }
  }

  ${maxMediaQuery.sm} {
    flex-direction: column;

    button {
      margin-left: 0;
      margin-bottom: ${({ theme }) => theme.spacing.unit * 2}px;
    }
  }
`;

const LinksContainer = styled.nav`
  margin: ${({ theme }) => `${theme.spacing.unit * 2}px 0 ${theme.spacing.unit * 3}px 0`};

  ul {
    display: flex;
    padding: 0;
    list-style: none;

    a {
      font-size: ${({ theme }) => theme.typography.smallBody.fontSize};
      line-height: ${({ theme }) => theme.typography.smallBody.lineHeight};
      margin-right: ${({ theme }) => theme.spacing.unit * 2}px;
      color: ${({ theme }) => theme.colors.primary.black};
    }
  }
`;

const CookieCategory = styled.h5`
  margin: ${({ theme }) => `${theme.spacing.unit * 3}px 0 ${theme.spacing.unit * 2}px 0`};
  line-height: ${({ theme }) => theme.typography.body.lineHeight};
  color: ${({ theme }) => theme.colors.primary.comicReliefDeepViolet};
`;

const CookieSettings = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.primary.black};
  border-bottom: 1px solid ${({ theme }) => theme.colors.primary.black};
  margin-bottom: ${({ theme }) => theme.spacing.unit * 5}px;
  max-height: 500px;
  overflow-y: auto;
  position: relative;

  ${maxMediaQuery.xs} {
    max-height: none;
  }
`;

const CookieSelection = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.unit * 3}px;

  div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${({ theme }) => theme.spacing.unit}px;
  }

  span {
    font-weight: ${({ theme }) => theme.typography.h5.fontWeight};
    font-size: ${({ theme }) => theme.typography.titleBody.fontSize};
    line-height: ${({ theme }) => theme.typography.titleBody.lineHeight};
  }
`;

export default CookieConsentManager;
