import React, { FC } from 'react';
import ReactPlayer from 'react-player';
import styled from '@emotion/styled';
import { AnimationProps, motion } from 'framer-motion';

interface Props {
  videoUrl: string;
  showControl?: boolean;
  playLoop?: boolean;
  videoOpen?: boolean;
}

const containerAnimationVariants: AnimationProps['variants'] = {
  closed: {
    opacity: 0,
  },
  opened: {
    opacity: 1,
  },
};

const containerTransition: AnimationProps['transition'] = {
  opacity: { duration: 0.5, delay: 0.5 },
};

const MediaBlockVideo: FC<Props> = ({ videoUrl, showControl = true, playLoop = false, videoOpen = false }) => {
  return (
    <VideoIconContainer
      variants={containerAnimationVariants}
      transition={containerTransition}
      initial="closed"
      animate={videoOpen === true ? 'opened' : 'closed'}
    >
      {videoOpen === true && (
        <StyledReactPlayer
          url={videoUrl}
          width="100%"
          height="100%"
          playing
          controls={showControl}
          loop={playLoop}
          vimeoConfig={{ iframeParams: { fullscreen: 1 } }}
        />
      )}
    </VideoIconContainer>
  );
};

const VideoIconContainer = styled(motion.div)`
  width: 100%;
  height: 100%;
`;

const StyledReactPlayer = styled(ReactPlayer)`
  width: 100%;
  height: 100%;
`;

export default MediaBlockVideo;
