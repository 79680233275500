import { CookieCategories, CookieRelatedServices } from './enums';
import { ConsentData, CookieData } from './types';

export const availableCookies: Array<CookieData> = [
  {
    name: 'GTM Cookie',
    description:
      'Google Tag Manager is a tag management system that allows to quickly and easily update measurement codes and related code fragments collectively known as tags on a website or mobile app.',
    category: CookieCategories.marketing,
    relatedService: CookieRelatedServices.GTM,
  },
  {
    name: 'GA Cookie',
    description:
      'Google Analytics is a web analytics service offered by Google that tracks and reports website traffic, currently as a platform inside the Google Marketing Platform brand.',
    category: CookieCategories.marketing,
    relatedService: CookieRelatedServices.GA,
  },
  {
    name: 'Cookie Consent Manager Cookie',
    description: 'We are using cookie to keep your consent data',
    category: CookieCategories.essential,
    relatedService: CookieRelatedServices.CCM,
  },
];

export const cookieCategories = [
  ...new Set(
    availableCookies.map((cookie) => {
      return cookie.category;
    })
  ),
];

export const defaultServicesData: Array<ConsentData> = availableCookies.map((cookie) => {
  return {
    name: cookie.name,
    service: cookie.relatedService,
    category: cookie.category,
    accepted: true,
  };
});
