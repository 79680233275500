import React, { FC, useMemo } from 'react';
import { graphql } from 'gatsby';
import { ComponentSeoDataFragment, PageQuery } from 'graphql-types';
import Seo from '../components/seo/Seo';
import PageVariant from '../components/page-variant/PageVariant';
import { mapPageContent, AvailablePageContentComponents } from '../utils/page';
import { mapContentSeoDataToProps } from '../utils/content/seo';
import { SeoProps } from '../components/seo/props';

export enum Alignment {
  LEFT = 'Left',
  CENTER = 'Center',
  RIGHT = 'Right',
}

const PageTemplate: FC<{ data: PageQuery }> = ({ data }) => {
  const pageData = data.page.edges?.[0]?.node;
  const content = useMemo(() => {
    return pageData?.content?.map((item) => mapPageContent(item as AvailablePageContentComponents));
  }, [pageData]);
  const seoData = useMemo<SeoProps>(() => {
    return mapContentSeoDataToProps(pageData?.seoData ?? ({} as ComponentSeoDataFragment), pageData?.title ?? '');
  }, [pageData]);

  return (
    <>
      <Seo {...seoData} />
      <PageVariant variant="default">{content}</PageVariant>
    </>
  );
};

export const query = graphql`
  query Page($id: String!) {
    page: allContentfulPage(filter: { contentful_id: { eq: $id } }) {
      edges {
        node {
          createdAt
          title
          seoData {
            ...ComponentSeoData
          }
          content {
            ... on Node {
              ...ComponentHero
              ...ComponentHeroSlider
              ...ComponentMediaBlock
              ...ComponentMediaBlockSlider
              ...ComponentTextBlock
              ...ComponentTwoColumns
              ...ComponentAccordions
              ...ComponentProjectsMap
              ...ComponentCardFeatured
              ...ComponentPagesListChosen
              ...ComponentPagesList
              ...ComponentPromoContent
              ...ComponentStatBlock
              ...ComponentSpacer
              ...ComponentQuote
              ...ComponentTextAndGraphic
            }
          }
          id
        }
      }
    }
  }
`;

export default PageTemplate;
