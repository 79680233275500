import { IGatsbyImageData } from 'gatsby-plugin-image';
import { ContentfulRichTextGatsbyReference, RenderRichTextData } from 'gatsby-source-contentful/rich-text';
import { ButtonProps } from '../button/buttonProps';
import { OverlayAlignmentVariant, OverlayBackgroundVariant, OverlayVariant } from '../overlay/props';

export enum HeroVariants {
  FULL = 'Full',
  FIT_TO_CONTENT = 'Fit to content',
}

export type HeroProps = {
  id?: string;
  overlayVariant?: OverlayVariant;
  headingPrefix?: string;
  heading: RenderRichTextData<ContentfulRichTextGatsbyReference> | string;
  image?: IGatsbyImageData;
  imageAlt?: string;
  excerpt?: RenderRichTextData<ContentfulRichTextGatsbyReference> | null | undefined;
  alignment?: OverlayAlignmentVariant;
  button?: ButtonProps;
  buttonText?: string;
  background?: OverlayBackgroundVariant;
  fadeInEnabled?: boolean;
};
