import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { maxMediaQuery } from '../grid';

const OverlayBox = styled(motion.div)<{ isSide: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: ${({ isSide }) => (isSide === false ? '100%' : 'auto')};
  border-radius: ${({ isSide }) => (isSide === false ? '0' : '24px')};
  margin-top: ${({ theme, isSide }) => (isSide === false ? '0' : `${theme.spacing.unit * 9}px`)};
  margin-bottom: ${({ theme, isSide }) => (isSide === false ? '0' : `${theme.spacing.unit * 9}px`)};
  padding: ${({ theme, isSide }) =>
    isSide === false ? `${theme.spacing.unit * 15}px 0` : `${theme.spacing.unit * 4}px ${theme.spacing.unit * 5}px`};

  ${maxMediaQuery.sm} {
    margin-top: ${({ theme, isSide }) => (isSide === true ? `-${theme.spacing.unit * 4}px` : '0')};
    margin-bottom: ${({ theme, isSide }) => (isSide === true ? `${theme.spacing.unit * 3}px` : '0')};
    padding: ${({ theme, isSide }) =>
      isSide === false ? `${theme.spacing.unit * 4}px 0` : `${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`};
    box-shadow: ${({ isSide }) => (isSide === true ? '0 4px 12px 0 rgb(0, 0, 0, 0.2)' : 'none')};
  }
`;

export default OverlayBox;
