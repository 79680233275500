import React from 'react';
import {
  renderRichText,
  ContentfulRichTextGatsbyReference,
  RenderRichTextData,
} from 'gatsby-source-contentful/rich-text';
import { BLOCKS } from '@contentful/rich-text-types';
import { reusableRichTextOptions, RichTextOptions } from './reusable';

const textOptions = reusableRichTextOptions();

const options: RichTextOptions = {
  ...textOptions,
  renderMark: {
    ...textOptions?.renderMark,
  },
  renderNode: {
    ...textOptions?.renderNode,
    [BLOCKS.PARAGRAPH]: (_node: React.ReactNode, children: React.ReactNode) => <p>{children}</p>,
    [BLOCKS.HEADING_1]: (_node: React.ReactNode, children: React.ReactNode) => children,
    [BLOCKS.HEADING_2]: (_node: React.ReactNode, children: React.ReactNode) => <h2>{children}</h2>,
    [BLOCKS.HEADING_3]: (_node: React.ReactNode, children: React.ReactNode) => <h3>{children}</h3>,
    [BLOCKS.HEADING_4]: (_node: React.ReactNode, children: React.ReactNode) => <h4>{children}</h4>,
    [BLOCKS.HEADING_5]: (_node: React.ReactNode, children: React.ReactNode) => <h5>{children}</h5>,
    [BLOCKS.HEADING_6]: (_node: React.ReactNode, children: React.ReactNode) => <h6>{children}</h6>,
    [BLOCKS.UL_LIST]: (_node: React.ReactNode, children: React.ReactNode) => <ul>{children}</ul>,
    [BLOCKS.OL_LIST]: (_node: React.ReactNode, children: React.ReactNode) => <ol>{children}</ol>,
  },
};

export function mapTextBlockRichText(richText: RenderRichTextData<ContentfulRichTextGatsbyReference>): React.ReactNode {
  if (richText?.raw == null) {
    return '';
  }

  return renderRichText(richText, options);
}
