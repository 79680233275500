import React, { FC, useMemo } from 'react';
import { graphql } from 'gatsby';
import { useLocation } from '@reach/router';
import { ComponentSeoDataFragment, ContentfulProjectConnection } from 'graphql-types';
import { format } from 'date-fns';
import styled from '@emotion/styled';
import { ContentfulRichTextGatsbyReference, RenderRichTextData } from 'gatsby-source-contentful/rich-text';
import Seo from '../components/seo/Seo';
import { Col, GridContainer, maxMediaQuery, Row } from '../components/grid';
import Share from '../components/share/Share';
import ProjectHeader from '../components/page-header/ProjectHeader';
import { AvailablePageContentComponents, mapPageContent } from '../utils/page';
import { parseStringToDate } from '../utils/date';
import { AvailableContentCardComponents } from '../utils/content/card';
import { mapContentPagesListChosenToPagesProps } from '../utils/content/pages-list-chosen';
import { SeoProps } from '../components/seo/props';
import { mapContentSeoDataToProps } from '../utils/content/seo';
import RelatedCards from '../components/related-cards/RelatedCards';
import { RelatedCardsTypes } from '../components/related-cards/props';

const ProjectTemplate: FC<{ data: { projects: ContentfulProjectConnection } }> = ({ data }) => {
  const projectData = data.projects.edges?.[0]?.node;

  const location = useLocation();

  const content = useMemo(() => {
    return (
      projectData?.content != null &&
      projectData.content.map((item) => mapPageContent(item as AvailablePageContentComponents))
    );
  }, [projectData]);
  const projectDate = useMemo<string | undefined>(() => {
    if (projectData?.endDate == null) {
      return undefined;
    }

    const parsedProjectDate = parseStringToDate(projectData.endDate);

    return parsedProjectDate != null ? format(parsedProjectDate, 'dd MMMM yyyy') : undefined;
  }, [projectData]);
  const relatedContent = useMemo<RelatedCardsTypes>(() => {
    const relatedProjects = (projectData?.relatedProjects as AvailableContentCardComponents[]) ?? undefined;
    const relatedStories = (projectData?.relatedStories as AvailableContentCardComponents[]) ?? undefined;
    const relatedPublications = (projectData?.relatedPublications as AvailableContentCardComponents[]) ?? undefined;
    const relatedArticles = (projectData?.relatedArticles as AvailableContentCardComponents[]) ?? undefined;
    const relatedPress = (projectData?.relatedPressReleases as AvailableContentCardComponents[]) ?? undefined;

    return {
      projects: mapContentPagesListChosenToPagesProps(relatedProjects),
      stories: mapContentPagesListChosenToPagesProps(relatedStories),
      publications: mapContentPagesListChosenToPagesProps(relatedPublications),
      articles: mapContentPagesListChosenToPagesProps(relatedArticles),
      press: mapContentPagesListChosenToPagesProps(relatedPress),
    };
  }, [projectData]);
  const seoData = useMemo<SeoProps>(() => {
    return mapContentSeoDataToProps(projectData?.seoData ?? ({} as ComponentSeoDataFragment), projectData?.title ?? '');
  }, [projectData]);

  return (
    <>
      <Seo {...seoData} />
      <ProjectHeader
        title={projectData?.title || ''}
        excerpt={(projectData?.excerpt as RenderRichTextData<ContentfulRichTextGatsbyReference>) ?? undefined}
        location={projectData?.locationTag?.name ?? undefined}
        projectTheme={projectData?.tag?.name ?? undefined}
        endDate={projectDate ?? undefined}
        image={projectData?.featuredImage?.gatsbyImageData}
        imageAlt={projectData?.featuredImage?.description ?? undefined}
      />
      {content}
      <GridContainer>
        <Row>
          <StyledCol>
            <Share
              title={projectData?.title ?? ''}
              url={location.href}
              image={projectData?.featuredImage?.file?.url ?? undefined}
            />
          </StyledCol>
        </Row>
      </GridContainer>
      <RelatedCards {...relatedContent} context="projects" />
    </>
  );
};

export const query = graphql`
  query Projects($id: String!) {
    projects: allContentfulProject(filter: { contentful_id: { eq: $id } }) {
      edges {
        node {
          createdAt
          title
          id
          excerpt {
            raw
          }
          featuredImage {
            gatsbyImageData
            description
            file {
              url
            }
          }
          contentful_id
          tag {
            name
          }
          locationTag {
            name
          }
          endDate
          content {
            ... on Node {
              ...ComponentMediaBlock
              ...ComponentMediaBlockSlider
              ...ComponentTextBlock
              ...ComponentTwoColumns
              ...ComponentAccordions
              ...ComponentProjectsMap
              ...ComponentCardFeatured
              ...ComponentPagesListChosen
              ...ComponentPagesList
              ...ComponentPromoContent
              ...ComponentStatBlock
              ...ComponentSpacer
              ...ComponentQuote
            }
          }
          relatedProjects {
            ... on Node {
              ...ComponentCardProject
            }
          }
          relatedPublications {
            ... on Node {
              ...ComponentCardPublication
            }
          }
          relatedStories {
            ... on Node {
              ...ComponentCardStory
            }
          }
          relatedArticles {
            ... on Node {
              ...ComponentCardArticle
            }
          }
          relatedPressReleases {
            ... on Node {
              ...ComponentCardPress
            }
          }
          seoData {
            ...ComponentSeoData
          }
        }
      }
    }
  }
`;

const StyledCol = styled(Col)`
  width: 100%;
  padding: ${({ theme }) => theme.spacing.unit * 5}px 0px;
  display: flex;
  justify-content: center;

  ${maxMediaQuery.md} {
    padding: ${({ theme }) => theme.spacing.unit * 3}px 0px;
  }

  ${maxMediaQuery.sm} {
    padding: ${({ theme }) => theme.spacing.unit * 3}px 0px;
  }
`;

export default ProjectTemplate;
