import React, { FC } from 'react';
import styled from '@emotion/styled';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { ContentfulRichTextGatsbyReference, RenderRichTextData } from 'gatsby-source-contentful/rich-text';
import PaheHeaderMainPartExtended from './PageHeaderMainPartExtended';
import { GridContainer, maxMediaQuery } from '../grid';

interface Props {
  title: string;
  topText?: string;
  bottomText?: RenderRichTextData<ContentfulRichTextGatsbyReference>;
  tags?: string[];
  image?: IGatsbyImageData;
  imageAlt?: string;
}

const TextHeader: FC<Props> = ({ title, topText, bottomText, tags, image, imageAlt }) => {
  return (
    <Container>
      <GridContainer>
        <PaheHeaderMainPartExtended title={title} topText={topText} bottomText={bottomText} tags={tags} />
        {image && <HeaderImage loading="eager" image={image} alt={imageAlt ?? ''} />}
      </GridContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  padding-top: 80px;

  ${maxMediaQuery.md} {
    padding-top: 40px;
  }
`;

const HeaderImage = styled(GatsbyImage)`
  width: 100%;
  height: 500px;
  border-radius: 24px;
  margin-top: ${({ theme }) => theme.spacing.unit * 6}px;

  ${maxMediaQuery.md} {
    height: 350px;
    margin-top: ${({ theme }) => theme.spacing.unit * 4}px;
  }

  ${maxMediaQuery.sm} {
    height: 300px;
    margin-top: ${({ theme }) => theme.spacing.unit * 3}px;
  }
`;

export default TextHeader;
