import React, { FC, Suspense, useCallback, useEffect, useMemo, useRef } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from '@emotion/styled';
import { ContentfulProject, ContentfulProjectsMapConnection } from 'graphql-types';
import { useToggle } from 'react-use';
import { AnimatePresence, AnimationProps, motion } from 'framer-motion';
import { maxMediaQuery } from '../grid';
import { MarkerData } from './props';
import PortalContainer from '../portal-container/PortalContainer';
import { useScrollLock } from '../../hooks/useScrollLock';
import NavigationToggle from '../navigation/NavigationToggle';
import { isBrowser } from '../../utils/system';

interface Props {
  id?: string;
  heading: string;
}

const modalMapVariants: AnimationProps['variants'] = {
  closed: {
    opacity: 0,
    y: 100,
  },
  opened: {
    opacity: 1,
    y: 0,
  },
};

const modalMapTransition: AnimationProps['transition'] = {
  y: { type: 'spring', stiffness: 300, damping: 30, duration: 0.5, delay: 0.5 },
  opacity: { duration: 0.5, delay: 0.5 },
  delay: 0.5,
};

const ProjectsMapElement = React.lazy(
  () =>
    new Promise((resolve) => {
      setTimeout(() => resolve(import('./ProjectsMapElement')), 1000);
    })
);

const ProjectsMap: FC<Props> = ({ id, heading }) => {
  const projectsLocationData = useStaticQuery<{
    allContentfulProject: ContentfulProjectsMapConnection;
  }>(graphql`
    query projectLocationData {
      allContentfulProject(filter: { slug: {regex: "/^(?!dont\\-remove).+/"} }) {
        nodes {
          location {
            lat
            lon
          }
          title
          excerpt {
            raw
          }
          locationTag {
            name
          }
          tag {
            name
          }
          slug
        }
      }
    }
  `);
  const browser = isBrowser();
  const componentInitializedRef = useRef(false);
  const [showMobileMap, toggleShowMobileMap] = useToggle(false);
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { ref, enableScroll, disableScroll } = useScrollLock<HTMLDivElement>();

  const markers = useMemo<MarkerData[]>(() => {
    const markersNode = projectsLocationData?.allContentfulProject?.nodes;

    if (markersNode == null) {
      return [];
    }

    return markersNode
      .filter((item: ContentfulProject) => item?.location != null)
      .map((item: ContentfulProject) => {
        return {
          name: item?.title,
          coordinates: [item?.location?.lon, item?.location?.lat],
          location: item.locationTag ? item.locationTag.name : '',
          description: item?.excerpt ? item.excerpt : [],
          tag: item.tag ? item.tag.name : '',
          path: item.slug || '',
        } as MarkerData;
      });
  }, [projectsLocationData]);

  const handleOpenMobileMap = useCallback(() => {
    toggleShowMobileMap(true);
  }, [toggleShowMobileMap]);

  useEffect(() => {
    if (componentInitializedRef.current === false) {
      componentInitializedRef.current = true;
    } else if (showMobileMap === true) {
      disableScroll();
    } else {
      enableScroll();
    }
  }, [showMobileMap]);

  return (
    <>
      <Container id={id}>
        <HeadingContainer>{heading && <StyledH3>{heading}</StyledH3>}</HeadingContainer>
        {browser === true && (
          <Suspense fallback={null}>
            <ProjectsMapElement markers={markers} onOpenMobileMap={handleOpenMobileMap} />
          </Suspense>
        )}
      </Container>
      <PortalContainer>
        <AnimatePresence exitBeforeEnter>
          {showMobileMap === true && (
            <MobileMapContainer
              key="projects-map"
              ref={ref}
              variants={modalMapVariants}
              initial="closed"
              animate="opened"
              exit="closed"
              transition={modalMapTransition}
            >
              <div>
                {browser === true && (
                  <Suspense fallback={null}>
                    <ProjectsMapElement inModal markers={markers} />
                  </Suspense>
                )}
                <NavigationToggleContainer>
                  <NavigationToggle light isOpen={showMobileMap} onToggle={toggleShowMobileMap} />
                </NavigationToggleContainer>
              </div>
            </MobileMapContainer>
          )}
        </AnimatePresence>
      </PortalContainer>
    </>
  );
};

const Container = styled.div`
  width: 100%;
  margin-top: ${(props) => props.theme.spacing.unit * 10}px;

  ${maxMediaQuery.lg} {
    margin-top: ${(props) => props.theme.spacing.unit * 5}px;
  }

  ${maxMediaQuery.sm} {
    margin-top: ${(props) => props.theme.spacing.unit * 3}px;
  }
`;

const StyledH3 = styled.h3`
  position: relative;
  width: 100%;
  text-align: center;
  margin: 0px;
`;

const HeadingContainer = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.primary.white};
  padding: 16px;
`;

const MobileMapContainer = styled(motion.div)`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 10000;

  > div {
    position: relative;
    width: 100%;
    height: 100%;
  }
`;

const NavigationToggleContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  height: 80px;
  z-index: 10001;

  > button {
    height: 100%;
  }
`;

export default ProjectsMap;
