import React, { FC } from 'react';
import Navigation from '../navigation/Navigation';
import SkipToMainContent from '../skipToMainContent/SkipToMainContent';

const Header: FC = () => {
  return (
    <>
      <SkipToMainContent />
      <Navigation />
    </>
  );
};

export default Header;
