export type AvailableEntryType =
  | 'ContentfulArticle'
  | 'ContentfulPressRelease'
  | 'ContentfulStory'
  | 'ContentfulProject'
  | 'ContentfulPublication'
  | 'ContentfulPage'
  | 'ContentfulPressRelease';

export function getTargetEntryPrefix(type: AvailableEntryType): string {
  switch (type) {
    case 'ContentfulArticle':
      return '/articles/';

    case 'ContentfulPressRelease':
      return '/press/';

    case 'ContentfulStory':
      return '/stories/';

    case 'ContentfulProject':
      return '/projects/';

    case 'ContentfulPublication':
      return '/publications/';

    default:
      return '/';
  }
}

export function getApplicationTargetEntry(url: string, type: AvailableEntryType = 'ContentfulPage'): string {
  const prefix = getTargetEntryPrefix(type);

  return url.charAt(0) === '/' ? `${prefix}${url.slice(1)}` : `${prefix}${url}`;
}
