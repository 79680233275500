import React, { forwardRef, useMemo } from 'react';
import { Link as GatsbyLink, GatsbyLinkProps } from 'gatsby';
import { getUrlWithLeadingSlash, isApplicationTargetEntry, isApplicationTargetId } from '../../utils/url';

type LinkType = 'entry' | 'target' | 'external';
type AnchorProps = Omit<
  React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>,
  'href'
>;
type Props = AnchorProps &
  Pick<GatsbyLinkProps<unknown>, 'activeClassName' | 'activeStyle'> & {
    href?: string;
  };

const Link = forwardRef<HTMLAnchorElement, Props>(
  ({ href, children, activeClassName, activeStyle, ...linkProps }, ref) => {
    const type = useMemo<LinkType>(() => {
      if (href != null && isApplicationTargetEntry(href) === true) {
        return 'entry';
      }

      if (href != null && isApplicationTargetId(href) === true) {
        return 'target';
      }

      return 'external';
    }, [href]);

    if (href != null && type === 'entry') {
      return (
        <GatsbyLink
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ref={ref as any}
          to={getUrlWithLeadingSlash(href)}
          activeClassName={activeClassName}
          activeStyle={activeStyle}
          {...linkProps}
        >
          {children}
        </GatsbyLink>
      );
    }

    return (
      // eslint-disable-next-line react/jsx-no-target-blank
      <a
        ref={ref}
        href={href}
        {...linkProps}
        target={type === 'external' || linkProps.download != null ? '_blank' : undefined}
        rel={type === 'external' || linkProps.download != null ? 'noopener noreferrer' : undefined}
      >
        {children}
      </a>
    );
  }
);

export default Link;
