import styled from '@emotion/styled';
import { AnimationProps, motion } from 'framer-motion';
import React, { FC } from 'react';

type Props = {
  isOpen: boolean;
  light?: boolean;
  onToggle(): void;
  onOpen?(): void;
};

const topLineAnimationVariants: AnimationProps['variants'] = {
  closed: {
    rotate: 0,
    y: -4,
    width: 35,
  },
  opened: {
    rotate: 45,
    y: 4,
    width: 35,
  },
};

const middleLineAnimationVariants: AnimationProps['variants'] = {
  closed: {
    opacity: 1,
    x: 0,
    width: 17.5,
  },
  opened: {
    opacity: 0,
    x: 20,
    width: 35,
  },
};

const bottomLineAnimationVariants: AnimationProps['variants'] = {
  closed: {
    rotate: 0,
    y: 4,
    width: 26,
  },
  opened: {
    rotate: -45,
    y: -4,
    width: 35,
  },
};

const lineAnimationTransition: AnimationProps['transition'] = {
  y: { type: 'spring', stiffness: 300, damping: 30, duration: 0.3 },
  rotate: { type: 'spring', stiffness: 300, damping: 30, duration: 0.3 },
  width: { type: 'spring', stiffness: 300, damping: 30, duration: 0.3 },
  opacity: { duration: 0.2 },
};

const NavigationToggle: FC<Props> = ({ isOpen, light = false, onToggle }) => {
  const variant = isOpen === true ? 'opened' : 'closed';

  return (
    <Toggle
      className="btn-restart"
      whileTap={{ scale: 1.05 }}
      title={isOpen === true ? 'Close navigation' : 'Open navigation'}
      onClick={onToggle}
    >
      <LinesContainer light={light}>
        <Line
          animate={variant}
          variants={topLineAnimationVariants}
          initial="closed"
          transition={lineAnimationTransition}
        />
        <MiddleLine
          animate={variant}
          variants={middleLineAnimationVariants}
          initial="closed"
          transition={lineAnimationTransition}
        />
        <BottomLine
          animate={variant}
          variants={bottomLineAnimationVariants}
          initial="closed"
          transition={lineAnimationTransition}
        />
      </LinesContainer>
    </Toggle>
  );
};

const Toggle = styled(motion.button)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
`;

const Line = styled(motion.span)`
  width: 35px;
  height: 4px;
`;

const MiddleLine = styled(Line)`
  width: 17.5px;
`;

const BottomLine = styled(Line)`
  width: 26px;
`;

const LinesContainer = styled.span<{ light: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 35px;

  ${Line},
  ${MiddleLine},
  ${BottomLine} {
    background-color: ${({ theme, light }) =>
      light === true ? theme.colors.primary.white : theme.colors.primary.black};
  }
`;

export default NavigationToggle;
