import { TextAndGraphicItemProps } from './TextAndGraphicItem';

export interface TextAndGraphicProps {
  items: TextAndGraphicItemProps[];
  id?: string;
  withContainer?: boolean;
  withSpaceTop?: boolean;
  withSpaceBottom?: boolean;
}

export enum TextBlockBackgroundVariant {
  TEAL = 'Teal',
  CORAL = 'Coral',
  DEEP_VIOLET = 'Deep Violet',
  NONE = 'None',
}
