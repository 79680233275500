import React, { FC, useCallback, useMemo } from 'react';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { Col, GridContainer, maxMediaQuery, Row } from '../grid';
import { Alignment } from '../../templates/PageTemplate';
import { ConditionalWrapper } from '../wrapper/ConditionalWrapper';
import { TextBlockBackgroundVariant } from './props';

interface Props {
  heading: React.ReactNode;
  text: React.ReactNode;
  alignment: Alignment;
  id?: string;
  withContainer?: boolean;
  withSpaceTop?: boolean;
  withSpaceBottom?: boolean;
  background?: TextBlockBackgroundVariant;
}

const TextBlock: FC<Props> = ({
  heading,
  text,
  alignment,
  id,
  withContainer = true,
  withSpaceTop = true,
  withSpaceBottom = true,
  background = TextBlockBackgroundVariant.NONE,
}) => {
  const theme = useTheme();
  const textBackground = useMemo<string | undefined>(() => {
    switch (background) {
      case TextBlockBackgroundVariant.TEAL:
        return theme.colors.secondary.teal.normal;

      case TextBlockBackgroundVariant.CORAL:
        return theme.colors.secondary.coral.normal;

      case TextBlockBackgroundVariant.DEEP_VIOLET:
        return theme.colors.primary.comicReliefDeepViolet;

      default:
        return undefined;
    }
  }, [background, theme]);
  const handleWithContainer = useCallback(
    (children: React.ReactNode) => <GridContainer id={id}>{children}</GridContainer>,
    [withContainer, id]
  );

  const handleWithBackground = useCallback(
    (children: React.ReactNode) => (
      <Col>
        <BackgroundContainer backgroundColor={textBackground as string}>
          <GridContainer fluid>
            <Row>{children}</Row>
          </GridContainer>
        </BackgroundContainer>
      </Col>
    ),
    [textBackground]
  );

  return (
    <ConditionalWrapper condition={withContainer === true} wrapperPositive={handleWithContainer}>
      <StyledRow
        id={withContainer === false ? id : undefined}
        withSpaceTop={withSpaceTop}
        withSpaceBottom={withSpaceBottom}
      >
        <ConditionalWrapper condition={textBackground != null} wrapperPositive={handleWithBackground}>
          <Col lg={{ span: 10, offset: 1 }}>
            <Content aligment={alignment}>
              <Container lightText={background === TextBlockBackgroundVariant.DEEP_VIOLET}>
                {heading}
                {text}
              </Container>
            </Content>
          </Col>
        </ConditionalWrapper>
      </StyledRow>
    </ConditionalWrapper>
  );
};

const Container = styled.div<{ lightText: boolean }>`
  width: 100%;
  color: ${({ theme, lightText }) => (lightText === true ? theme.colors.primary.white : theme.colors.primary.copy)};

  hr {
    width: 100%;
  }

  a:not(.btn) {
    font-weight: 700;
    border-bottom: 1px solid transparent;
    transition: all 0.2s ease;

    &:hover {
      border-bottom-color: ${({ theme }) => theme.colors.primary.copy};
    }
  }
`;

const BackgroundContainer = styled.div<{ backgroundColor: string }>`
  padding: 80px 0;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 24px;

  ${maxMediaQuery.md} {
    padding: 40px 0;
  }

  ${maxMediaQuery.sm} {
    padding: 20px 0;
  }
`;

const Content = styled.div<{ aligment: Alignment }>`
  display: flex;
  flex-direction: column;
  align-items: end;
  text-align: ${({ aligment }) =>
    aligment === Alignment.LEFT ? 'left' : aligment === Alignment.RIGHT ? 'right' : 'center'};

  ${Container} {
    display: flex;
    flex-direction: column;
    align-items: ${({ aligment }) =>
      aligment === Alignment.LEFT ? 'flex-start' : aligment === Alignment.RIGHT ? 'flex-end' : 'center'};
  }
`;

const StyledRow = styled(Row)<{ withSpaceTop: boolean; withSpaceBottom: boolean }>`
  margin-top: ${({ theme, withSpaceTop }) => (withSpaceTop === true ? `${theme.spacing.unit * 5}px` : 0)};
  margin-bottom: ${({ theme, withSpaceBottom }) => (withSpaceBottom === true ? `${theme.spacing.unit * 5}px` : 0)};

  ${maxMediaQuery.md} {
    margin-top: ${({ theme, withSpaceTop }) => (withSpaceTop === true ? `${theme.spacing.unit * 3}px` : 0)};
    margin-bottom: ${({ theme, withSpaceBottom }) => (withSpaceBottom === true ? `${theme.spacing.unit * 3}px` : 0)};
  }
`;

export default TextBlock;
