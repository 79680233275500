import React from 'react';
import { BLOCKS } from '@contentful/rich-text-types';
import {
  renderRichText,
  ContentfulRichTextGatsbyReference,
  RenderRichTextData,
} from 'gatsby-source-contentful/rich-text';

const options = {
  renderNode: {
    [BLOCKS.HEADING_1]: (_node: React.ReactNode, children: React.ReactNode) => children,
    [BLOCKS.HEADING_2]: (_node: React.ReactNode, children: React.ReactNode) => children,
    [BLOCKS.HEADING_3]: (_node: React.ReactNode, children: React.ReactNode) => children,
    [BLOCKS.HEADING_4]: (_node: React.ReactNode, children: React.ReactNode) => children,
    [BLOCKS.HEADING_5]: (_node: React.ReactNode, children: React.ReactNode) => children,
    [BLOCKS.HEADING_6]: (_node: React.ReactNode, children: React.ReactNode) => children,
    [BLOCKS.PARAGRAPH]: (_node: React.ReactNode, children: React.ReactNode) => children,
  },
};

const mapHeroHeading = (heading: RenderRichTextData<ContentfulRichTextGatsbyReference>): React.ReactNode => {
  if (heading?.raw == null) {
    return '';
  }

  return renderRichText(heading, options);
};

export default mapHeroHeading;
