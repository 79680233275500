import styled from '@emotion/styled';
import React, { FC } from 'react';
import { MenuLink } from '../../models/content/menu-link';
import NavigationListItem from './NavigationListItem';

type Props = {
  items: MenuLink[];
  smallGap?: boolean;
};

const NavigationList: FC<Props> = ({ items, smallGap = false }) => (
  <List smallGap={smallGap}>
    {items.map((item) => (
      <li key={item.key}>
        <NavigationListItem {...item} />
      </li>
    ))}
  </List>
);

const List = styled.ul<{ smallGap: boolean }>`
  list-style: none;
  display: flex;
  justify-content: flex-end;
  margin: 0;
  padding: 0;
  padding-left: 100px;
  padding-right: ${({ smallGap }) => (smallGap === true ? '12px' : '100px')};
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.primary.white};

  > li {
    position: relative;
    display: block;
    height: 100%;
  }
`;

export default NavigationList;
