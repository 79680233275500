import React, { FC, ReactNode } from 'react';

export type Props = {
  condition: boolean;
  wrapperPositive: (children: ReactNode) => JSX.Element;
  wrapperNegative?: (children: ReactNode) => JSX.Element;
};

export const ConditionalWrapper: FC<Props> = ({ condition, wrapperPositive, wrapperNegative, children }) => {
  if (condition) {
    return wrapperPositive(children);
  }

  return wrapperNegative ? wrapperNegative(children) : <>{children}</>;
};
