import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import React, { FC } from 'react';
import { MenuLink } from '../../models/content/menu-link';
import Link from '../link/Link';

type Props = MenuLink;

const NavigationMobileItem: FC<Props> = ({ label, url, nestedElements }) => {
  return (
    <Container>
      <LinkContainer href={url} activeClassName="active">
        {label}
      </LinkContainer>
      {nestedElements.length > 0 && (
        <SubMenuContainer>
          {nestedElements.map((nestedElement) => (
            <li key={nestedElement.key}>
              <SubMenuItemLink href={nestedElement.url} activeClassName="active">
                {nestedElement.label}
              </SubMenuItemLink>
            </li>
          ))}
        </SubMenuContainer>
      )}
    </Container>
  );
};

const Container = styled(motion.div)``;

const LinkContainer = styled(Link)`
  display: block;
  padding: 10px;
  font-family: ${({ theme }) => theme.typography.h4.fontFamily};
  font-size: ${({ theme }) => theme.typography.h4.fontSize};
  font-weight: 700;
  line-height: ${({ theme }) => theme.typography.h4.lineHeight};
  color: ${({ theme }) => theme.colors.primary.white};
  text-transform: uppercase;
  border-radius: 6px;
  background-color: transparent;
  word-break: break-word;
  transition: background-color 0.25s ease-out;

  &:hover,
  &:focus,
  &.active {
    background-color: ${({ theme }) => theme.colors.secondary.grey.dark};
  }
`;

const SubMenuContainer = styled(motion.ul)`
  list-style: none;
  display: flex;
  flex-direction: column;
  margin: 0;
  margin-left: ${({ theme }) => theme.spacing.unit * 3}px;
  padding: 0;

  li {
    display: block;
    margin-top: ${({ theme }) => theme.spacing.unit}px;
  }
`;

const SubMenuItemLink = styled(Link)`
  display: block;
  padding: 10px;
  font-family: ${({ theme }) => theme.typography.h5.fontFamily};
  font-size: ${({ theme }) => theme.typography.h5.fontSize};
  font-weight: 700;
  line-height: ${({ theme }) => theme.typography.h5.lineHeight};
  color: ${({ theme }) => theme.colors.primary.white};
  text-transform: uppercase;
  border-radius: 6px;
  background-color: transparent;
  word-break: break-word;
  transition: background-color 0.25s ease-out;

  &:hover,
  &:focus,
  &.active {
    background-color: ${({ theme }) => theme.colors.secondary.grey.dark};
  }
`;

export default NavigationMobileItem;
