import React, { FC } from 'react';
import { MenuLink } from '../../models/content/menu-link';
import Link from '../link/Link';

const SimpleMenu: FC<{ items: MenuLink[] }> = ({ items }) => {
  return (
    <ul>
      {items.map((item: MenuLink, index: number) => (
        <li key={index}>
          <Link href={item.url}>{item.label}</Link>
        </li>
      ))}
    </ul>
  );
};

export default SimpleMenu;
