import { graphql, useStaticQuery } from 'gatsby';
import React, { FC, useMemo } from 'react';
import { PagesListPressQuery } from 'graphql-types';
import { PageListFilterItem, PagesListItem, PagesListSharedProps } from './props';
import {
  mapContentPageListPressToListFilters,
  mapContentPageListPressToListItems,
} from '../../utils/content/pages-list';
import PagesListBody from './PagesListBody';

const PagesListPress: FC<PagesListSharedProps> = ({ showFiltering }) => {
  const data = useStaticQuery<PagesListPressQuery>(graphql`
    query PagesListPress {
      allContentfulPressRelease(
        sort: { order: DESC, fields: date }
        filter: { slug: { regex: "/^(?!dont\\-remove).+/" } }
      ) {
        nodes {
          ... on Node {
            ...ComponentCardPress
          }
        }
      }
    }
  `);
  const listItems = useMemo<PagesListItem[]>(() => {
    const items = data?.allContentfulPressRelease?.nodes;

    return mapContentPageListPressToListItems(items);
  }, [data]);
  const listFilters = useMemo<PageListFilterItem[]>(() => {
    const items = data?.allContentfulPressRelease?.nodes;

    return mapContentPageListPressToListFilters(items);
  }, [data]);

  return <PagesListBody items={listItems} filters={listFilters} showFiltering={showFiltering ?? false} />;
};

export default PagesListPress;
