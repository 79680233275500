import styled from '@emotion/styled';
import { transparentize } from 'polished';
import { maxMediaQuery } from '../grid';
import OverlayBase from './OverlayBase';
import OverlayBox from './OverlayBox';
import { OverlayAlignmentVariant, OverlayProps } from './props';

const OverlayWhite = styled(OverlayBase)<OverlayProps>`
  ${OverlayBox} {
    color: ${({ theme }) => theme.colors.primary.copy};
    background-color: ${({ theme, alignment }) =>
      transparentize(alignment === OverlayAlignmentVariant.CENTER ? 0.4 : 0.2, theme.colors.primary.white)};

    ${maxMediaQuery.sm} {
      background: ${({ theme }) => theme.colors.primary.white};
    }
  }
`;

export default OverlayWhite;
