import { useEffect, useState } from 'react';
import { createBreakpoint } from 'react-use';
import { BreakpointsTypes, minAvailableBreakpoints } from '../components/grid';

type HookType = Record<BreakpointsTypes, boolean>;

const breakpoints: Record<BreakpointsTypes, number> = {
  xs: 1,
  sm: minAvailableBreakpoints.sm,
  md: minAvailableBreakpoints.md,
  lg: minAvailableBreakpoints.lg,
  xl: minAvailableBreakpoints.xl,
};

const useRegularBreakpoint = createBreakpoint(breakpoints) as () => BreakpointsTypes;

const initialBreakpoints: HookType = {
  xs: false,
  sm: false,
  md: false,
  lg: false,
  xl: false,
};

export function useBreakpoint(): HookType {
  const [matchingBreakpoints, setMatchingBreakpoints] = useState<HookType>(initialBreakpoints);
  const currentBreakpoint = useRegularBreakpoint();

  useEffect(() => {
    const breakpointsKeys = Object.keys(breakpoints);
    const currentBreakpointIndex = breakpointsKeys.findIndex((breakpointKey) => breakpointKey === currentBreakpoint);
    const newBreakpoints: HookType = {
      ...initialBreakpoints,
    };

    breakpointsKeys.forEach((breakpointKey, index) => {
      newBreakpoints[breakpointKey as BreakpointsTypes] = index <= currentBreakpointIndex;
    });

    setMatchingBreakpoints(newBreakpoints);
  }, [setMatchingBreakpoints, currentBreakpoint]);

  return matchingBreakpoints;
}
