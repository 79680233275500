import React from 'react';
import { BLOCKS } from '@contentful/rich-text-types';
import {
  renderRichText,
  ContentfulRichTextGatsbyReference,
  RenderRichTextData,
} from 'gatsby-source-contentful/rich-text';
import { reusableRichTextOptions, RichTextOptions } from './reusable';

export function mapExcerptRichText(richText: RenderRichTextData<ContentfulRichTextGatsbyReference>): React.ReactNode {
  if (richText?.raw == null) {
    return '';
  }

  const textOptions = reusableRichTextOptions();

  const options: RichTextOptions = {
    renderMark: {
      ...textOptions?.renderMark,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (_node: React.ReactNode, children: React.ReactNode) => children,
    },
  };

  return renderRichText(richText, options);
}
