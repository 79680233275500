import React from 'react';
import Helmet from 'react-helmet';
import { CookieRelatedServices } from '../cookie-consent-manager/enums';
import { useServiceConsent } from '../../hooks/service-consent';
import config from '../../config/index';
import { useIsDoNotTrackUsed } from '../../hooks/use-do-not-track';

const MarketingServices: React.FC = () => {
  const doNotTrackIsUsed = useIsDoNotTrackUsed();
  const gtmServiceApproved = useServiceConsent(CookieRelatedServices.GTM);
  const gaServiceApproved = useServiceConsent(CookieRelatedServices.GA);
  const enableGtm = gtmServiceApproved && config.GTM_ID !== '' && doNotTrackIsUsed !== true;
  const enableGa = gaServiceApproved && config.GA_ID !== '' && doNotTrackIsUsed !== true;

  return (
    <>
      {enableGtm && (
        <Helmet>
          <script>
            {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${config.GTM_ID}');`}
          </script>
          <noscript>
            {`<iframe
              src="https://www.googletagmanager.com/ns.html?id=${config.GTM_ID}"
              height="0"
              width="0"
              title="GTM"
            />`}
          </noscript>
        </Helmet>
      )}
      {enableGa && (
        <Helmet>
          <script async src={`https://www.googletagmanager.com/gtag/js?id=${config.GA_ID}`} />
          <script>
            {`window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${config.GA_ID}');`}
          </script>
        </Helmet>
      )}
    </>
  );
};

export default MarketingServices;
