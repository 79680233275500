import React, { FC } from 'react';
import styled from '@emotion/styled';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { maxMediaQuery } from '../grid';

export interface TextAndGraphicItemProps {
  image: IGatsbyImageData;
  imageDescription: string;
  heading: string;
  copy: string;
  id?: string;
}

const TextAndGraphicItem: FC<TextAndGraphicItemProps> = ({ image, imageDescription, heading, copy, ...props }) => {
  return (
    <Container {...props}>
      {image && <Image image={image} alt={imageDescription} />}
      <HeadingContainer>{heading}</HeadingContainer>
      <div>{copy}</div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px ${({ theme }) => theme.spacing.unit}px;
  text-align: center;

  ${maxMediaQuery.md} {
    margin: ${({ theme }) => `${theme.spacing.unit * 5}px ${theme.spacing.unit}px`};
  }
`;

const Image = styled(GatsbyImage)`
  width: 250px;
  height: 250px;
  border-radius: 24px;
`;

const HeadingContainer = styled.h4`
  margin: ${({ theme }) => theme.spacing.unit * 3}px 0px;
  min-height: 30px;

  ${maxMediaQuery.md} {
    min-height: 0px;
  }
`;

export default TextAndGraphicItem;
