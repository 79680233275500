import React, { FC, useCallback, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import {
  EmailShareButton,
  FacebookShareButton,
  FacebookMessengerShareButton,
  HatenaShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WorkplaceShareButton,
  WeiboShareButton,
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  HatenaIcon,
  InstapaperIcon,
  LineIcon,
  LinkedinIcon,
  LivejournalIcon,
  MailruIcon,
  OKIcon,
  PinterestIcon,
  PocketIcon,
  RedditIcon,
  TelegramIcon,
  TumblrIcon,
  TwitterIcon,
  ViberIcon,
  VKIcon,
  WeiboIcon,
  WorkplaceIcon,
  WhatsappIcon,
} from 'react-share';
import { motion } from 'framer-motion';
import config from '../../config';
import Modal from '../modal/Modal';
import { maxMediaQuery } from '../grid';
import Icon from '../icon/Icon';

export type SocialShareProps = {
  url: string;
  title: string;
  image?: string;
  hashtags?: Array<string>;
  source?: string;
};

const DEFAULT_HASHTAGS_VALUE: Array<string> = [];
const { FACEBOOK_APP_ID } = config;

const Share: FC<SocialShareProps> = ({ url, image, title, hashtags, source }) => {
  const [modalVisibility, setModalVisibility] = useState(false);
  const showMoreButton = useRef<HTMLButtonElement>(null);

  const openModal = useCallback(() => {
    const returnFocusToComponent = setTimeout(() => {
      if (!modalVisibility) {
        showMoreButton.current?.focus();
      }
    }, 50);
    setModalVisibility((isVisible) => !isVisible);
    return () => clearTimeout(returnFocusToComponent);
  }, [setModalVisibility]);

  const theme = useTheme();

  const hoveAnimationProps = {
    y: -10,
    transition: { duration: 0.2 },
  };

  return (
    <>
      <ShareButtonsContainer>
        <Title>Share</Title>
        <FeaturedShareButtons>
          {[
            <FacebookShareButton url={url} hashtag={hashtags ? hashtags[0] : undefined} quote={title || undefined}>
              <FacebookIcon round bgStyle={{ fill: theme.colors.primary.comicReliefRed }} />
            </FacebookShareButton>,
            <TwitterShareButton url={url} title={title} hashtags={hashtags || DEFAULT_HASHTAGS_VALUE}>
              <TwitterIcon round bgStyle={{ fill: theme.colors.primary.comicReliefRed }} />
            </TwitterShareButton>,
            <LinkedinShareButton url={url} title={title} source={source || ''}>
              <LinkedinIcon round bgStyle={{ fill: theme.colors.primary.comicReliefRed }} />
            </LinkedinShareButton>,
            <ShowMoreButton type="button" onClick={openModal} ref={showMoreButton} aria-label="Share on social media">
              <Icon iconType="plus" />
            </ShowMoreButton>,
          ].map((item, index) => (
            <motion.div key={index} whileHover={hoveAnimationProps}>
              {item}
            </motion.div>
          ))}
        </FeaturedShareButtons>

        <Modal visible={modalVisibility} closeFunction={openModal}>
          <ModalButtons>
            {[
              <TwitterShareButton url={url} title={title} hashtags={hashtags || DEFAULT_HASHTAGS_VALUE}>
                <TwitterIcon round bgStyle={{ fill: theme.colors.primary.comicReliefRed }} />
              </TwitterShareButton>,
              <LinkedinShareButton url={url} title={title} source={source || ''}>
                <LinkedinIcon round bgStyle={{ fill: theme.colors.primary.comicReliefRed }} />
              </LinkedinShareButton>,
              <FacebookShareButton url={url} hashtag={hashtags ? hashtags[0] : undefined} quote={title || undefined}>
                <FacebookIcon round bgStyle={{ fill: theme.colors.primary.comicReliefRed }} />
              </FacebookShareButton>,
              <EmailShareButton url={url} subject={title}>
                <EmailIcon round bgStyle={{ fill: theme.colors.primary.comicReliefRed }} />
              </EmailShareButton>,

              FACEBOOK_APP_ID && (
                <FacebookMessengerShareButton appId={FACEBOOK_APP_ID} url={url}>
                  <FacebookMessengerIcon round bgStyle={{ fill: theme.colors.primary.comicReliefRed }} />
                </FacebookMessengerShareButton>
              ),

              <HatenaShareButton url={url} title={title}>
                <HatenaIcon round bgStyle={{ fill: theme.colors.primary.comicReliefRed }} />
              </HatenaShareButton>,
              <InstapaperShareButton url={url} title={title}>
                <InstapaperIcon round bgStyle={{ fill: theme.colors.primary.comicReliefRed }} />
              </InstapaperShareButton>,
              <LineShareButton url={url} title={title}>
                <LineIcon round bgStyle={{ fill: theme.colors.primary.comicReliefRed }} />
              </LineShareButton>,
              <LivejournalShareButton url={url} title={title}>
                <LivejournalIcon round bgStyle={{ fill: theme.colors.primary.comicReliefRed }} />
              </LivejournalShareButton>,
              <MailruShareButton url={url} title={title} imageUrl={image || ''}>
                <MailruIcon round bgStyle={{ fill: theme.colors.primary.comicReliefRed }} />
              </MailruShareButton>,
              <OKShareButton url={url} title={title} image={image || ''}>
                <OKIcon round bgStyle={{ fill: theme.colors.primary.comicReliefRed }} />
              </OKShareButton>,
              <PocketShareButton url={url} title={title}>
                <PocketIcon round bgStyle={{ fill: theme.colors.primary.comicReliefRed }} />
              </PocketShareButton>,

              image && (
                <PinterestShareButton media={image} url={url} description={title}>
                  <PinterestIcon round bgStyle={{ fill: theme.colors.primary.comicReliefRed }} />
                </PinterestShareButton>
              ),

              <RedditShareButton url={url} title={title}>
                <RedditIcon round bgStyle={{ fill: theme.colors.primary.comicReliefRed }} />
              </RedditShareButton>,
              <TelegramShareButton url={url} title={title}>
                <TelegramIcon round bgStyle={{ fill: theme.colors.primary.comicReliefRed }} />
              </TelegramShareButton>,
              <TumblrShareButton url={url} title={title} tags={hashtags || DEFAULT_HASHTAGS_VALUE}>
                <TumblrIcon round bgStyle={{ fill: theme.colors.primary.comicReliefRed }} />
              </TumblrShareButton>,
              <ViberShareButton url={url}>
                <ViberIcon round bgStyle={{ fill: theme.colors.primary.comicReliefRed }} />
              </ViberShareButton>,
              <VKShareButton url={url} title={title} image={image || ''}>
                <VKIcon round bgStyle={{ fill: theme.colors.primary.comicReliefRed }} />
              </VKShareButton>,
              <WhatsappShareButton url={url} title={title}>
                <WhatsappIcon round bgStyle={{ fill: theme.colors.primary.comicReliefRed }} />
              </WhatsappShareButton>,
              <WorkplaceShareButton url={url} quote={title} hashtag={hashtags ? hashtags[0] : undefined}>
                <WorkplaceIcon round bgStyle={{ fill: theme.colors.primary.comicReliefRed }} />
              </WorkplaceShareButton>,
              <WeiboShareButton url={url} title={title} image={image || ''}>
                <WeiboIcon round bgStyle={{ fill: theme.colors.primary.comicReliefRed }} />
              </WeiboShareButton>,
              <EmailShareButton url={url} subject={title}>
                <EmailIcon round bgStyle={{ fill: theme.colors.primary.comicReliefRed }} />
              </EmailShareButton>,
            ].map((item, index: number) => (
              <motion.div key={index} whileHover={hoveAnimationProps}>
                {item}
              </motion.div>
            ))}
          </ModalButtons>
        </Modal>
      </ShareButtonsContainer>
    </>
  );
};

const ShareButtonsContainer = styled.div`
  display: flex !important;
  flex-direction: row !important;
  align-items: center;
  width: 278px;
  height: 68px;
  background: ${(props) => props.theme.colors.primary.white};
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  line-height: 0px;

  div > div > button {
    border: none;
    background: none;
    outline: none;
    cursor: pointer;
    padding: 0px;

    &:focus {
      outline: -webkit-focus-ring-color auto 5px;
    }
  }

  ${maxMediaQuery.md} {
    justify-content: center;
    margin-top: 0;
  }
`;

const Title = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  color: ${(props) => props.theme.colors.primary.comicReliefRed};
  margin: 0px ${(props) => props.theme.spacing.unit * 2}px;
`;

const ModalButtons = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  button {
    margin: ${(props) => props.theme.spacing.unit * 4}px;
  }

  svg {
    width: 60px;
    height: 60px;
  }

  ${maxMediaQuery.sm} {
    button {
      margin: ${(props) => props.theme.spacing.unit * 2}px;
    }
    svg {
      width: 48px;
      height: 48px;
    }
  }

  ${maxMediaQuery.xs} {
    button {
      margin: ${(props) => props.theme.spacing.unit}px;
    }
    svg {
      width: 48px;
      height: 48px;
    }
  }
`;

const FeaturedShareButtons = styled.div`
  display: flex;
  button {
    width: 33px;
    margin-right: 10px;
    cursor: pointer;
  }

  svg {
    width: 100%;
    height: 100%;
  }
`;

const ShowMoreButton = styled.button`
  height: 32px;
`;

export default Share;
