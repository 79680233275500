import styled from '@emotion/styled';
import { AnimationProps, motion, useAnimation } from 'framer-motion';
import React, { FC, useEffect, useRef } from 'react';
import { useHoverDirty } from 'react-use';
import { useViewportSlideIn } from '../../hooks/viewport-slide-in';
import { useViewportFadeIn } from '../../hooks/viewport-fade-in';
import { Col, GridContainer, maxMediaQuery, Row } from '../grid';
import CardBody from './CardBody';
import { CardProps } from './props';
import CardImage from './CardImage';
import Link from '../link/Link';

const imageAnimationVariants: AnimationProps['variants'] = {
  hoverOut: {
    x: 0,
  },
  hoverIn: (imagePosition: CardProps['imagePosition']) => ({
    x: imagePosition === 'left' ? 5 : -5,
  }),
};

const bodyAnimationVariants: AnimationProps['variants'] = {
  hoverOut: {
    x: 0,
  },
  hoverIn: (imagePosition: CardProps['imagePosition']) => ({
    x: imagePosition === 'left' ? -5 : 5,
  }),
};

const animationTransition: AnimationProps['transition'] = {
  x: { duration: 0.4 },
};

const CardFeatured: FC<CardProps> = ({ id, image, imageAlt, imagePosition = 'left', path, ...cardBodyProps }) => {
  const actionContainerRef = useRef<HTMLAnchorElement | null>(null);
  const [imageContainerRef, imageContainerControls] = useViewportFadeIn();
  const [bodyContainerRef, bodyContainerControls] = useViewportSlideIn();
  const actionHovering = useHoverDirty(actionContainerRef);
  const imageHoverControls = useAnimation();
  const bodyHoverControls = useAnimation();

  useEffect(() => {
    if (actionHovering === true) {
      imageHoverControls.start('hoverIn');
      bodyHoverControls.start('hoverIn');
    } else {
      imageHoverControls.start('hoverOut');
      bodyHoverControls.start('hoverOut');
    }
  }, [actionHovering, imageHoverControls, bodyHoverControls]);

  return (
    <Container id={id}>
      <GridContainer>
        <Row>
          <ActionContainer ref={actionContainerRef} href={path} title={`Go to ${cardBodyProps.title}`}>
            <Col md={{ span: 7, offset: imagePosition === 'left' ? 0 : 5 }}>
              <ImageContainer
                custom={imagePosition}
                animate={imageHoverControls}
                variants={imageAnimationVariants}
                initial="hoverOut"
                transition={animationTransition}
              >
                <motion.div ref={imageContainerRef} animate={imageContainerControls}>
                  {image != null && <CardImage image={image} imageAlt={imageAlt} isHovering={actionHovering} />}
                </motion.div>
              </ImageContainer>
            </Col>
            <CardBodyContainer md={{ span: 7 }} imagePosition={imagePosition}>
              <motion.div ref={bodyContainerRef} animate={bodyContainerControls}>
                <CardBodyInnerContainer
                  custom={imagePosition}
                  animate={bodyHoverControls}
                  variants={bodyAnimationVariants}
                  initial="hoverOut"
                  transition={animationTransition}
                >
                  <CardBody {...cardBodyProps} largeShadow={false} />
                </CardBodyInnerContainer>
              </motion.div>
            </CardBodyContainer>
          </ActionContainer>
        </Row>
      </GridContainer>
    </Container>
  );
};

const Container = styled.section`
  display: block;
  position: relative;
  margin-top: ${({ theme }) => theme.spacing.unit * 10}px;
  margin-bottom: ${({ theme }) => theme.spacing.unit * 10}px;

  ${Row} {
    padding-bottom: 24px;
  }

  ${maxMediaQuery.md} {
    margin-top: ${({ theme }) => theme.spacing.unit * 5}px;
    margin-bottom: ${({ theme }) => theme.spacing.unit * 5}px;
  }

  ${maxMediaQuery.sm} {
    margin-top: ${({ theme }) => theme.spacing.unit * 3}px;
    margin-bottom: ${({ theme }) => theme.spacing.unit * 3}px;
  }
`;

const ActionContainer = styled(Link)`
  display: block;
  width: 100%;
`;

const ImageContainer = styled(motion.div)`
  border-radius: 24px;
  overflow: hidden;
  mask-image: -webkit-radial-gradient(white, black);

  > div {
    height: 500px;
  }
`;

const CardBodyContainer = styled(Col)<{ imagePosition: CardProps['imagePosition'] }>`
  position: absolute;
  right: ${({ imagePosition }) => (imagePosition === 'left' ? '0' : 'auto')};
  left: ${({ imagePosition }) => (imagePosition === 'right' ? '0' : 'auto')};
  bottom: -24px;
  padding-left: ${({ imagePosition }) => (imagePosition === 'left' ? '100px' : '0')};
  padding-right: ${({ imagePosition }) => (imagePosition === 'right' ? '100px' : '0')};
`;

const CardBodyInnerContainer = styled(motion.div)`
  width: 100%;
`;

export default CardFeatured;
