import React, { FC } from 'react';
import styled from '@emotion/styled';
import { GatsbyImage } from 'gatsby-plugin-image';
import { motion } from 'framer-motion';
import { HeroProps } from './props';
import { OverlayBackgroundVariant } from '../overlay/props';
import { useViewportFadeIn } from '../../hooks/viewport-fade-in';
import { maxMediaQuery } from '../grid';
import { MOBILE_NAVIGATION_HEIGHT } from '../../constants/navigation';
import { useStateContext } from '../../hooks/state-context';

const HeroClipToContent: FC<HeroProps> = ({ id, image, imageAlt, background, children, fadeInEnabled }) => {
  const { windowLoaded } = useStateContext();
  const [containerRef, containerControls] = useViewportFadeIn(fadeInEnabled, false, windowLoaded !== true);

  return (
    <Container id={id} ref={containerRef} animate={containerControls} background={background}>
      {image != null && (
        <BackgroundImage
          loading="eager"
          image={image}
          alt={imageAlt != null && imageAlt !== '' ? imageAlt : 'Hero background'}
        />
      )}
      {children}
    </Container>
  );
};

const Container = styled(motion.div)<{ background?: OverlayBackgroundVariant }>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${({ theme, background }) =>
    background != null ? theme.overlayBackground[background] : 'transparent'};

  > div {
    height: 100%;
  }
`;

const BackgroundImage = styled(GatsbyImage)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 5;

  ${maxMediaQuery.sm} {
    position: relative;
    min-height: 300px;
    height: calc(50vh - ${MOBILE_NAVIGATION_HEIGHT}px);
    width: 100%;
  }
`;

export default HeroClipToContent;
